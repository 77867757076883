import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
	Task,
	TaskData,
	CreateTaskResponse,
	UpdateTaskResponse,
	GetAllTasksResponse,
	GetATaskResponse,
	DeleteTaskResponse,
	createTask,
	updateTask,
	getAllTasks,
	getATask,
	deleteTask,
} from "../../services/project-management/project-management-service";

export interface ProjectManagementState {
	createTaskResponse: CreateTaskResponse | null;
	updateTaskResponse: UpdateTaskResponse | null;
	getAllTasksResponse: GetAllTasksResponse | null;
	getATaskResponse: GetATaskResponse | null;
	deleteTaskResponse: DeleteTaskResponse | null;
	tasks?: GetAllTasksResponse | null;
	taskData?: TaskData | null;
	task_id?: string;
	meta?: any;
	error?: string | null;
}

const initialState: ProjectManagementState = {
	createTaskResponse: null,
	updateTaskResponse: null,
	getAllTasksResponse: null,
	getATaskResponse: null,
	deleteTaskResponse: null,
	tasks: null,
	taskData: null,
	task_id: "",
	error: null,
	meta: null,
};

export const createTaskAsync = createAsyncThunk(
	"projectManagement/createTask",
	async (data: TaskData, { dispatch, rejectWithValue }) => {
		const response = await createTask(data);
		return response;
	},
);

export const updateTaskAsync = createAsyncThunk(
	"projectManagement/updateTask",
	async (data: Task) => {
		const response = await updateTask(data);
		return response;
	},
);

export const getAllTasksAsync = createAsyncThunk(
	"projectManagement/getAllTasks",
	async ({
		company_id,
		projectId,
		page,
	}: {
		company_id: string;
		projectId: string;
		page: number;
	}) => {
		const response = await getAllTasks(company_id, projectId, page);
		return response;
	},
);

export const getATaskAsync = createAsyncThunk(
	"projectManagement/getATask",
	async ({
		id,
		company_id,
		projectId,
	}: {
		id: string;
		company_id: string;
		projectId: string;
	}) => {
		const response = await getATask(id, company_id, projectId);
		return response.data;
	},
);

export const deleteTaskAsync = createAsyncThunk(
	"projectManagement/deleteTask",
	async ({
		id,
		projectId,
		company_id,
	}: {
		id: string;
		projectId: string;
		company_id: string;
	}) => {
		const response = await deleteTask(id, projectId, company_id);
		return response;
	},
);

export const projectManagementSlice = createSlice({
	name: "projectManagement",
	initialState,
	reducers: {
		setTaskId: (state, action) => {
			state.task_id = action.payload;
		},
		setTasks: (state, action) => {
			state.tasks = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(createTaskAsync.fulfilled, (state, action) => {
				state.createTaskResponse = action.payload;
			})
			.addCase(createTaskAsync.rejected, (state, action) => {
				state.createTaskResponse = null;
				state.error = action.error.message || "Failed to create task";
			})
			.addCase(updateTaskAsync.fulfilled, (state, action) => {
				state.updateTaskResponse = action.payload;
			})
			.addCase(updateTaskAsync.rejected, (state, action) => {
				state.updateTaskResponse = null;
				state.error = action.error.message || "Failed to update task";
			})
			.addCase(getAllTasksAsync.fulfilled, (state, action) => {
				state.tasks = action.payload;
				state.meta = action.payload.meta;
			})
			.addCase(getAllTasksAsync.rejected, (state, action) => {
				state.getAllTasksResponse = null;
				state.tasks = null;
				state.error = action.error.message || "Failed to fetch tasks";
			})
			.addCase(getATaskAsync.fulfilled, (state, action) => {
				state.taskData = action.payload;
			})
			.addCase(getATaskAsync.rejected, (state, action) => {
				state.getATaskResponse = null;
				state.taskData = null;
				state.error = action.error.message || "Failed to fetch task";
			})
			.addCase(deleteTaskAsync.fulfilled, (state, action) => {
				state.deleteTaskResponse = action.payload;
			})
			.addCase(deleteTaskAsync.rejected, (state, action) => {
				state.deleteTaskResponse = null;
				state.error = action.error.message || "Failed to delete task";
			});
	},
});

export const { setTaskId, setTasks } = projectManagementSlice.actions;

export default projectManagementSlice.reducer;
