import axios from 'axios';

export interface Budget {
    amount_spent: string;
    budget_amount: string;
    budget_type: string;
    budget_type_id: string;
    company_id: string;
    id: string;
    projectId: string;
}
export interface CreateBudgetData {
    budget_amount: string;
    budget_type_id: string;
    company_id: string;
    projectId: string;
}
export interface CreateBudgetResponse {
    data: Budget;
    message: string;
    error: boolean;
}
export interface GetAllBudgetsResponse {
    data: Budget[];
    message: string;
}

export const createBudget = async (values: CreateBudgetData): Promise<CreateBudgetResponse> => {
    const response = await axios.post<CreateBudgetResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/projects/${values.projectId}/budgets/create`, values);
    return response.data;
}
export const getAllBudgets = async (companyId: string, projectId: string): Promise<Budget[]> => {
    const response = await axios.get<GetAllBudgetsResponse>(`https://syteboxx-api.remsys.com.ng/api/v1/projects/${projectId}/budgets/?company_id=${companyId}`);
    return response.data.data;
}