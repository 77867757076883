import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetAllReportsResponse, getAllReports } from "services/reports/reportsService";


export interface ReportState {
    reports: GetAllReportsResponse | null;

}

const initialState: ReportState = {
    reports: null,

}

export const getAllReportsAsync = createAsyncThunk(
    'requests/getAllReports',
    async ({ companyId, projectId }: { companyId: string; projectId: string; }) => {
        try {
            const reports = await getAllReports(companyId, projectId);
            return reports;
        } catch (error) {
            console.error('Error fetching reports:', error);
            throw error;
        }
    }
);

const reportsSlice = createSlice({
    name: "requests",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllReportsAsync.fulfilled, (state, action) => {
            state.reports = action.payload;
        });
    }
})

export default reportsSlice.reducer;
