import React from "react";
import { Formik, Form, Field } from "formik";
import { Container, Card, Button, Image } from "react-bootstrap";
import Logo from "../../assets/images/logo.png";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { loginAsync } from "../../slices/auth/authSlice";
import { toast } from "react-toastify";

interface SignInValues {
	email: string;
	password: string;
}

const SignIn: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const logInResponse = useAppSelector(
		(state: any) => state.auth.logInResponse,
	);
	// const user = useAppSelector((state: any) => state.auth.user);

	if (!logInResponse?.error) {
		navigate("/projects");
	}

	return (
		<Container fluid>
			<div className="auth-bg d-none d-md-block"></div>
			<Card
				className="auth-card text-center p-5 fixed-bottom"
				style={{ overflowY: "auto" }}
			>
				<div className="text-center">
					<Image src={Logo} width={180} height={63} />
				</div>
				<p className="mt-2">Please sign in to continue</p>
				<div className="text-start">
					<Formik
						initialValues={{
							email: "",
							password: "",
						}}
						validationSchema={Yup.object().shape({
							email: Yup.string()
								.email("Invalid email")
								.required("Email is required"),
							password: Yup.string()
								.required("No password provided.")
								.min(6, "Password is too short - should be 6 chars minimum."),
						})}
						onSubmit={(values: SignInValues, { setSubmitting }) => {
							dispatch(loginAsync(values))
								.unwrap()
								.then((response) => {
									toast.success("Login successful");
								})
								.catch((error) => {
									const errorMessage = Object.values(error.message).flat().join(" ");
									toast.error(errorMessage);
								})
								.finally(() => {
									setSubmitting(false);
								});
						}}

					>
						{({
							errors,
							touched,
							handleSubmit,
							isSubmitting,
							values,
							handleChange,
						}) => (
							<Form onSubmit={handleSubmit}>
								<label className="mt-2">
									<p>Email address</p>
								</label>
								<Field
									type="email"
									name="email"
									placeholder="Please enter your email"
									className={`fluent-input ${touched.email && errors.email ? "is-invalid" : ""
										}`}
									required
								/>
								<label className="mt-2">
									<p>Password</p>
								</label>
								<Field
									type="password"
									name="password"
									placeholder="Please enter your password"
									className={`fluent-input ${touched.password && errors.password ? "is-invalid" : ""
										}`}
									required
								/>
								<div className="d-grid mt-2">
									<Button
										type="submit"
										className="my-4 py-3 border-0 bg-color-1"
									>
										<span className="text-center">Sign in</span>
									</Button>
								</div>
								<div className="text-center mt-1">
									<Link to="/forgot-password" className="link-styles">
										Forgot password?
									</Link>
								</div>
								<div className="text-center mt-5">
									<Link to="/sign-up" className="link-styles">
										Don’t have an account?{" "}
										<span className="text-color-1"> Sign up </span>
									</Link>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</Card>
		</Container>
	);
};

export default SignIn;
