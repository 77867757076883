import React from "react";
import {
	Modal,
	Button,
	Row,
	Col,
	Container,
	Form,
	CloseButton,
} from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useAppDispatch } from "store/hooks";
import { confirmAnInventoryAsync } from "slices/inventory/inventorySlice";

interface ConfirmOrderValues {
	confirmed_quantity: number;
	comment: string;
}
interface ConfirmOrderProps {
	closeModal: () => void;
	show: boolean;
	inventoryItemId: string | null;
	onConfirmSuccess: () => void;
	projectId: string | null | undefined;
}

const ConfirmOrder: React.FC<ConfirmOrderProps> = ({
	closeModal,
	show,
	projectId,
	inventoryItemId,
	onConfirmSuccess,
}) => {
	const companyId = localStorage.getItem("userCompanyID");
	const dispatch = useAppDispatch();

	return (
		<Container fluid>
			<Modal show={show} onHide={closeModal} centered>
				<div className="px-3 py-3">
					<Formik
						initialValues={{
							confirmed_quantity: 0,
							comment: "",
						}}
						validationSchema={Yup.object().shape({
							confirmed_quantity: Yup.number()
								.required("Required")
								.min(1, "Quantity must be greater than 0"),
							comment: Yup.string(),
						})}
						onSubmit={(values: ConfirmOrderValues, { setSubmitting }) => {
							if (companyId && projectId) {
								dispatch(
									confirmAnInventoryAsync({
										...values,
										projectId: `${projectId}`,
										company_id: `${companyId}`,
										id: `${inventoryItemId}`,
									}),
								)
									.unwrap()
									.then((response) => {
										toast.success(response.message);
										onConfirmSuccess();
										closeModal();
									})
									.catch((error) => {
										const errorMessage = Object.values(error.message)
											.flat()
											.join(" ");
										toast.error(errorMessage);
									})
									.finally(() => {
										setSubmitting(false);
									});
							} else {
								console.error("projectId or companyId is undefined");
							}
						}}
						validateOnChange
						validateOnBlur
					>
						{({ errors, touched, handleSubmit, isSubmitting }) => (
							<Form onSubmit={handleSubmit}>

								<Modal.Header className='text-start'>
									<h4 className="confirm-header"> Confirm order</h4>
									<CloseButton onClick={closeModal} />
								</Modal.Header>
								<Modal.Body>
									<Row>
										<Col className="mt-2">
											<label htmlFor="confirmed_quantity">
												Quantity received <span className="text-danger">*</span>
											</label>
											<Field
												type="number"
												name="confirmed_quantity"
												placeholder="Enter quantity received"
												className={`fluent-input ${touched.confirmed_quantity &&
													errors.confirmed_quantity
													? "is-invalid"
													: ""
													}`}
												min={1}
												required
											/>
											{touched.confirmed_quantity &&
												errors.confirmed_quantity && (
													<div className="invalid-feedback">
														{errors.confirmed_quantity}
													</div>
												)}
										</Col>
									</Row>
									<Row>
										<Col className="mt-3">
											<label htmlFor="comment">Comment</label>
											<Field
												type="text"
												name="comment"
												placeholder="Optional comment"
												className={`fluent-input ${touched.comment && errors.comment ? "is-invalid" : ""
													}`}
											/>
										</Col>
									</Row>
									<div className="d-grid mt-1">
										<Button
											className="my-4 py-3 border-0 bg-color-1"
											type="submit" // Use type="submit" instead of onClick
											disabled={isSubmitting}
										>
											{isSubmitting ? `Saving...` : `Save`}
										</Button>
									</div>
								</Modal.Body>
							</Form>
						)}
					</Formik>
				</div>
			</Modal>
		</Container>
	);
};

export default ConfirmOrder;
