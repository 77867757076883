// create-task.tsx
import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Container, Form } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { createTaskAsync } from "../../../../slices/project-management/project-management-slice";
import { getAllUsersAsync } from "../../../../slices/user/userSlice";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { toast } from "react-toastify";

interface CreateIssueValues {
	issue_name: string;
	associate_task: number;
	start_date: string;
	end_date: string;
	issue_status: string;
	image: string;
}

interface CreateIssueProps {
	closeModal: () => void;
	show: boolean;
	project_id: string | undefined | null;
	onCreateSuccess: () => void;
}

const CreateIssue: React.FC<CreateIssueProps> = ({
	closeModal,
	show,
	project_id,
	onCreateSuccess,
}) => {
	const dispatch = useAppDispatch();
	const [pageNumber, setPage] = useState(1);

	const companyId = localStorage.getItem("userCompanyID");

	const users = useAppSelector((state) => state.user.users?.data);

	useEffect(() => {
		if (companyId) {
			dispatch(getAllUsersAsync({ companyId, page: pageNumber }));
		}
	}, [companyId, pageNumber, dispatch]);

	return (
		<Container fluid className="">
			<Modal
				size="lg"
				show={show}
				onHide={closeModal}
				style={{ maxHeight: "100vh", overflowY: "auto" }}
			>
				<div className="px-5">
					<Formik
						initialValues={{
							issue_name: "",
							associate_task: 0,
							start_date: "",
							end_date: "",
							issue_status: "",
							image: "",
						}}
						validationSchema={Yup.object().shape({
							issue_name: Yup.string().required("Required"),
							associate_task: Yup.number().required("Required"),
							start_date: Yup.string().required("Required"),
							end_date: Yup.string().required("Required"),
							issue_status: Yup.string().required("Required"),
							image: Yup.string().required("Required"),
						})}
						onSubmit={(values: CreateIssueValues, { setSubmitting }) => {
							// if (companyId) {
							// 	dispatch(
							// 		createTaskAsync({
							// 			...values,
							// 			projectId: `${project_id}`,
							// 			company_id: `${companyId}`,
							// 		}),
							// 	)
							// 		.unwrap()
							// 		.then((response) => {
							// 			toast.success(response.message);
							// 			onCreateSuccess();
							// 			closeModal();
							// 		})
							// 		.catch((error) => {})
							// 		.catch((error) => {
							// 			const errorMessage = Object.values(error.message)
							// 				.flat()
							// 				.join(" ");
							// 			toast.error(errorMessage);
							// 		})
							// 		.finally(() => {
							// 			setSubmitting(false);
							// 		});
							// } else {
							// 	console.error("project_id or company_id is undefined");
							// }
							console.log("Form fields:\n", values);
						}}
						validateOnChange
						validateOnBlur
					>
						{({
							errors,
							touched,
							handleSubmit,
							isSubmitting,
							values,
							handleChange,
							setFieldValue,
						}) => (
							<Form onSubmit={handleSubmit}>
								<Modal.Header closeButton className="text-start mt-2">
									<h4>Create issue (This is not working for now...)</h4>
								</Modal.Header>
								<Modal.Body>
									<Row>
										<Col md={12} className="mt-2 w-50">
											<label className="mt-2">
												<p>
													Issue name <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="issue_name"
												className={`fluent-input  ${
													touched.issue_name && errors.issue_name
														? "is-invalid"
														: ""
												}`}
												required
											></Field>
										</Col>
										<Col md={12} className="mt-2 w-50">
											<label className="mt-2">
												<p>
													Associate task <span className="text-danger">*</span>
												</p>
											</label>
											<FormControl fullWidth>
												<InputLabel>Task</InputLabel>
												<Select
													label="Member"
													name="associate_task"
													className={`fluent-input  ${
														touched.associate_task && errors.associate_task
															? "is-invalid"
															: ""
													}`}
													onChange={handleChange}
													required
												>
													{users !== undefined && users !== null ? (
														users?.map((user) => (
															<MenuItem key={user.id} value={user.id}>
																{user.first_name} {user.last_name}
															</MenuItem>
														))
													) : (
														<MenuItem value="">John Doe</MenuItem>
													)}
												</Select>
											</FormControl>
										</Col>
									</Row>
									<Row>
										<Col md={12} className="mt-2 w-50">
											<label className="mt-2">
												<p>
													Priority <span className="text-danger">*</span>
												</p>
											</label>
											<FormControl fullWidth>
												<InputLabel>priority</InputLabel>
												<Select
													label="Member"
													name="priority"
													className={`fluent-input  ${
														touched.start_date && errors.start_date
															? "is-invalid"
															: ""
													}`}
													onChange={handleChange}
													required
												>
													{users !== undefined && users !== null ? (
														users?.map((user) => (
															<MenuItem key={user.id} value={user.id}>
																{user.first_name} {user.last_name}
															</MenuItem>
														))
													) : (
														<MenuItem value="">John Doe</MenuItem>
													)}
												</Select>
											</FormControl>
										</Col>
										<Col md={12} className="mt-2 w-50">
											<label className="mt-2">
												<p>
													Status <span className="text-danger">*</span>
												</p>
											</label>
											<FormControl fullWidth>
												<InputLabel>Status</InputLabel>
												<Select
													label="issue_status"
													name="issue_status"
													className={`fluent-input  ${
														touched.issue_status && errors.issue_status
															? "is-invalid"
															: ""
													}`}
													onChange={handleChange}
													required
												>
													<MenuItem value="To do">To do</MenuItem>
													<MenuItem value="In progress">In progress</MenuItem>
													<MenuItem value="Done">Done</MenuItem>
												</Select>
											</FormControl>
										</Col>
									</Row>
									<Row>
										<Col md={12} className="mt-2 w-50">
											<label className="mt-2">
												<p>
													Image <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="file"
												name="image"
												className={`fluent-input  ${
													touched.image && errors.image ? "is-invalid" : ""
												}`}
												required
											/>
										</Col>
									</Row>

									<Modal.Footer className="mt-5">
										<Row>
											<Col md={6}>
												<div className=" mt-1">
													<Button onClick={closeModal} className="cancel-btn">
														<span className="text-center">Cancel </span>{" "}
													</Button>
												</div>
											</Col>
											<Col md={6}>
												<div className=" mt-1">
													<Button
														type="submit"
														className="border-0 create-btn bg-color-1"
														disabled={isSubmitting}
													>
														<span className="text-center">
															{isSubmitting ? `Creating...` : `Create`}
														</span>{" "}
													</Button>
												</div>
											</Col>
										</Row>
									</Modal.Footer>
								</Modal.Body>
							</Form>
						)}
					</Formik>
				</div>
			</Modal>
		</Container>
	);
};

export default CreateIssue;
