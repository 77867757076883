import React, { useState, useEffect } from "react";
import Layout from "../../../components/layouts/layout";
import { Nav, Table, Dropdown, Button, Card } from "react-bootstrap";
import Action from "../../../assets/images/action-vector.png";
import ConfirmOrder from "./modals/confirm-order";
import { useParams } from "react-router-dom";
import searchIcon from "../../../assets/images/search-icon.svg";
import CreateStoreRequest from "./modals/create-store-request";
import ViewStoreRequest from "./modals/view-store-request";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/store";
import {
	getAllInventoryAsync,
	searchInventoryAsync,
	getAllStoreRequestsAsync,
	getAllUsagesAsync,
	getConfirmedInventoryAsync,
} from "slices/inventory/inventorySlice";
import ViewOrder from "./modals/view-order";
import { format } from "date-fns";
import { hasPermission } from "../../../util/permissionchecker";
import { extractPermissionNames } from "../../../util/transformPermissions";
import ViewUsage from "./modals/view-usage";
import ReturnUsage from "./modals/return-usage";
import Pagination from "@mui/material/Pagination";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

function Inventory() {
	const dispatch = useAppDispatch();
	// const inventory = useAppSelector((state:RootState) => state.inventory.inventories)
	const userData = useAppSelector((state: any) => state.user?.userData?.user);
	const isAdmin = userData?.system_role === "admin";
	const permissions = isAdmin
		? null
		: extractPermissionNames(userData?.permissions?.permissions || []);
	const [activeTab, setActiveTab] = useState<string>("confirm-order");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

	const inventory = useAppSelector(
		(state: RootState) => state.inventory.inventory?.data,
	);
	const inventoryMeta = useAppSelector(
		(state: RootState) => state.inventory.inventory?.meta,
	);
	const confirmedInventory = useAppSelector(
		(state: RootState) => state.inventory.confirmedInventory?.data,
	);
	const confirmedInventoryMeta = useAppSelector(
		(state: RootState) => state.inventory.confirmedInventory?.meta,
	);
	const storeRequests = useAppSelector(
		(state: RootState) => state.inventory.storeRequests?.data,
	);
	const storeRequestsMeta = useAppSelector(
		(state: RootState) => state.inventory.storeRequests?.meta,
	);
	const usages = useAppSelector(
		(state: RootState) => state.inventory.usages?.data,
	);
	const usagesMeta = useAppSelector(
		(state: RootState) => state.inventory.usages?.meta,
	);

	const companyId = localStorage.getItem("userCompanyID");
	const { projectId } = useParams<{ projectId: string | undefined }>();

	const [searchQuery, setSearchQuery] = useState<string>("");
	const [refreshData, setRefreshData] = useState(false);
	const [pageNumber, setPage] = useState<number>(1);
	const [selectedInventoryItemId, setSelectedInventoryItemId] = useState<
		string | null
	>(null);
	const [selectedStoreRequestId, setSelectedStoreRequestId] = useState<
		string | null
	>(null);
	const [selectedUsageId, setSelectedUsageId] = useState<string | null>(null);

	const [inventoryDropdownOpen, setInventoryDropdownOpen] = useState<{
		[key: number]: boolean;
	}>({});
	const toggleInventoryDropdown = (index: number) => {
		setInventoryDropdownOpen((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};

	const [storeRequestDropdownOpen, setStoreRequestDropdownOpen] = useState<{
		[key: number]: boolean;
	}>({});
	const toggleStoreRequestDropdown = (index: number) => {
		setStoreRequestDropdownOpen((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};

	const [usageDropdownOpen, setUsageDropdownOpen] = useState<{
		[key: number]: boolean;
	}>({});
	const toggleUsageDropdown = (index: number) => {
		setUsageDropdownOpen((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};

	const tabOrder: string[] = [
		"confirm-order",
		"material-list",
		"store-request",
		"usage",
	];

	const handleTabSelect = (key: string | null) => {
		if (key) {
			setActiveTabIndex(tabOrder.indexOf(key));
			setActiveTab(key);
		}
	};

	const [createStoreRequestModalOpen, setCreateStoreRequestModalOpen] =
		useState<boolean>(false);
	const handleOpenCreateStoreRequestModal = () =>
		setCreateStoreRequestModalOpen(true);
	const handleCloseCreateStoreRequestModal = () =>
		setCreateStoreRequestModalOpen(false);

	const [confirmOrderDropdownOpen, setConfirmOrderDropdownOpen] =
		useState<boolean>(false);
	const toggleConfirmOrderDropdown = () =>
		setConfirmOrderDropdownOpen(!confirmOrderDropdownOpen);

	const [confirmOrderModalOpen, setConfirmOrderModalOpen] =
		useState<boolean>(false);
	const handleOpenConfirmOrderModal = (inventoryItemId: string) => {
		setSelectedInventoryItemId(inventoryItemId);
		setConfirmOrderModalOpen(true);
	};
	const handleCloseConfirmOrderModal = () => {
		setConfirmOrderModalOpen(false);
		setSelectedInventoryItemId(null);
	};

	const [viewOrderModalOpen, setViewOrderModalOpen] = useState<boolean>(false);
	const handleOpenViewOrderModal = (inventoryItemId: string) => {
		setSelectedInventoryItemId(inventoryItemId);
		setViewOrderModalOpen(true);
	};
	const handleCloseViewOrderModal = () => {
		setViewOrderModalOpen(false);
		setSelectedInventoryItemId(null);
	};

	const [viewStoreRequestModalOpen, setViewStoreRequestModalOpen] =
		useState<boolean>(false);
	const handleOpenViewStoreRequestModal = (
		storeRequestItemId: string | null,
	) => {
		setSelectedStoreRequestId(storeRequestItemId);
		setViewStoreRequestModalOpen(true);
	};
	const handleCloseViewStoreRequestModal = () => {
		setViewStoreRequestModalOpen(false);
		setSelectedStoreRequestId(null);
	};

	const [viewUsageModalOpen, setViewUsageModalOpen] = useState<boolean>(false);
	const handleOpenViewUsageModal = (usageItemId: string | null) => {
		setSelectedUsageId(usageItemId);
		setViewUsageModalOpen(true);
	};
	const handleCloseViewUsageModal = () => {
		setViewUsageModalOpen(false);
		setSelectedUsageId(null);
	};

	const [returnUsageModalOpen, setReturnUsageModalOpen] =
		useState<boolean>(false);
	const handleOpenReturnUsageModal = (usageItemId: string | null) => {
		setSelectedUsageId(usageItemId);
		setReturnUsageModalOpen(true);
	};
	const handleCloseReturnUsageModal = () => {
		setReturnUsageModalOpen(false);
		setSelectedUsageId(null);
	};

	const handleChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
		if (setPage) {
			setPage(newPage);
		}
	};
	const handleInventoryChange = (
		event: React.ChangeEvent<unknown>,
		newPage: number,
	) => {
		if (setPage) {
			setPage(newPage);
			dispatch(
				searchInventoryAsync({
					company_id: companyId,
					projectId: projectId,
					searchTerm: searchQuery,
				}),
			);
		}
	};
	useEffect(() => {
		setActiveTab("confirm-order");
	}, []);
	useEffect(() => {
		if (companyId && projectId && !searchQuery) {
			dispatch(
				getAllInventoryAsync({
					company_id: companyId,
					projectId: projectId,
					page: pageNumber,
				}),
			);
		}
	}, [companyId, dispatch, pageNumber, projectId, searchQuery]);

	useEffect(() => {
		if (companyId && projectId) {
			dispatch(
				getConfirmedInventoryAsync({
					company_id: companyId,
					projectId: projectId,
					page: pageNumber,
				}),
			);
			dispatch(
				getAllStoreRequestsAsync({
					company_id: companyId,
					projectId: projectId,
					page: pageNumber,
				}),
			);
			dispatch(
				getAllUsagesAsync({
					company_id: companyId,
					projectId: projectId,
					page: pageNumber,
				}),
			);
		}
	}, [companyId, dispatch, projectId, pageNumber]);

	useEffect(() => {
		if (refreshData && companyId && projectId) {
			dispatch(
				getAllInventoryAsync({
					company_id: companyId,
					projectId: projectId,
					page: pageNumber,
				}),
			);
		}
	}, [companyId, dispatch, projectId, refreshData, pageNumber]);

	useEffect(() => {
		if (refreshData && companyId && projectId) {
			dispatch(
				getConfirmedInventoryAsync({
					company_id: companyId,
					projectId: projectId,
					page: pageNumber,
				}),
			);
		}
	}, [companyId, dispatch, projectId, refreshData, pageNumber]);

	useEffect(() => {
		if (refreshData && companyId && projectId) {
			dispatch(
				getAllStoreRequestsAsync({
					company_id: companyId,
					projectId: projectId,
					page: pageNumber,
				}),
			);
		}
	}, [companyId, dispatch, projectId, refreshData, pageNumber]);

	useEffect(() => {
		if (refreshData && companyId && projectId) {
			dispatch(
				getAllUsagesAsync({
					company_id: companyId,
					projectId: projectId,
					page: pageNumber,
				}),
			);
		}
	}, [companyId, dispatch, projectId, refreshData, pageNumber]);

	const handleCreateSuccess = () => {
		setRefreshData(true);
	};

	const getStatusColor = (status: any) => {
		switch (status.toLowerCase()) {
			case "confirmed":
				return "green";
			case "pending":
				return "goldenrod";
			case "incomplete":
				return "red";
			default:
				return "gray";
		}
	};
	const getConfirmedStatusColor = (status: any) => {
		switch (status.toLowerCase()) {
			case "available":
				return "green";
			case "low":
				return "goldenrod";
			default:
				return "red";
		}
	};

	console.log("unconfirmed inventory items: ", inventory);

	console.log("confirmed inventory items: ", confirmedInventory);

	return (
		<div className="bg-color-8">
			<Layout>
				<div className="mt-5 p-5">
					<h3>Inventory</h3>
					<Nav
						activeKey={activeTab}
						onSelect={handleTabSelect}
						className="nav px-2 py-2"
					>
						<Nav.Item>
							<Nav.Link
								className="py-0 px-2 rounded fw-light mx-2"
								eventKey="confirm-order"
								style={{
									color:
										activeTab === "confirm-order" ? " #FFFFFF  " : " #747487",
									textDecoration: "none",
									backgroundColor:
										activeTab === "confirm-order" ? "#0F6CBD " : "transparent",
								}}
							>
								Confirm order
							</Nav.Link>
						</Nav.Item>
						<Nav.Item className="">
							<Nav.Link
								className="py-0 px-2 rounded fw-light mx-2"
								eventKey="material-list"
								style={{
									color:
										activeTab === "material-list" ? " #FFFFFF  " : " #747487",
									textDecoration: "none",
									backgroundColor:
										activeTab === "material-list" ? "#0F6CBD " : "transparent",
								}}
							>
								Material list
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link
								className="py-0 px-2 rounded fw-light mx-2"
								eventKey="store-request"
								style={{
									color:
										activeTab === "store-request" ? " #FFFFFF  " : " #747487",
									textDecoration: "none",
									backgroundColor:
										activeTab === "store-request" ? "#0F6CBD " : "transparent",
								}}
							>
								Store request
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link
								className="py-0 px-2 rounded fw-light mx-2"
								eventKey="usage"
								style={{
									color: activeTab === "usage" ? " #FFFFFF  " : " #747487",
									textDecoration: "none",
									backgroundColor:
										activeTab === "usage" ? "#0F6CBD " : "transparent",
								}}
							>
								Usage
							</Nav.Link>
						</Nav.Item>
					</Nav>
					<div>
						{activeTab === "confirm-order" && (
							<>
								<div className="d-inline-flex justify-content-end w-100 mt-4">
									<Formik
										initialValues={{ searchQuery: "" }}
										validationSchema={Yup.object({
											searchQuery: Yup.string()
												.min(2, "Search term is too short")
												.required("Please enter a search term"),
										})}
										onSubmit={async (values, { setSubmitting, setErrors }) => {
											try {
												setSubmitting(true);
												toast.loading("Searching...");
												setSearchQuery(values.searchQuery);
												await dispatch(
													searchInventoryAsync({
														company_id: companyId,
														projectId: projectId,
														searchTerm: values.searchQuery,
													}),
												).unwrap();
												toast.dismiss();
												toast.success("Search completed successfully");
											} catch (error) {
												toast.dismiss();
												toast.error("An error occurred while searching");
												setErrors({
													searchQuery: "An error occurred. Please try again.",
												});
											} finally {
												setSubmitting(false);
											}
										}}
									>
										{({ isSubmitting, handleSubmit, errors, touched }) => (
											<Form onSubmit={handleSubmit} className="mt-4">
												<div className="mx-2 border border-1 rounded py-1 px-2 d-inline-flex justify-content-between align-items-center my-3">
													<Field
														type="search"
														name="searchQuery"
														placeholder="Search for material"
														className="bg-transparent border-0"
														style={{ outline: "0" }}
													/>
													<button
														type="submit"
														className={`pointer ${isSubmitting ? "disabled" : ""
															}`}
														disabled={isSubmitting}
														style={{
															background: "none",
															border: "none",
															boxShadow: "none",
														}}
													>
														<img src={searchIcon} alt="search icon" />
													</button>
												</div>
												{errors.searchQuery && touched.searchQuery ? (
													<div className="text-danger">
														{errors.searchQuery}
													</div>
												) : (
													<div className="text-transparent">
														errors.searchQuery
													</div>
												)}
											</Form>
										)}
									</Formik>
								</div>
								<div className="bg-color-9 table-container px-5 pt-3">
									<Table borderless responsive>
										<thead>
											<tr>
												<th className="text-color-1 bg-color-3">S/N</th>
												<th className="text-color-1 bg-color-3">Date </th>
												<th className="text-color-1 bg-color-3">Material </th>
												<th className="text-color-1 bg-color-3">
													Qty ordered{" "}
												</th>
												<th className="text-color-1 bg-color-3">
													Qty received{" "}
												</th>
												<th className="text-color-1 bg-color-3">
													Qty remaining
												</th>
												<th className="text-color-1 bg-color-3">Status</th>
												<th className="text-color-1 bg-color-3">Action</th>
											</tr>
										</thead>
										<tbody>
											{inventory?.map((item, index) => (
												<tr key={item.id}>
													<td style={{ verticalAlign: "middle" }}>
														{inventory.indexOf(item) + 1}
													</td>
													<td style={{ verticalAlign: "middle" }}>
														{format(new Date(item.created_at), "dd/MM/yy")}
													</td>
													<td style={{ verticalAlign: "middle" }}>
														{item.material}
													</td>
													<td style={{ verticalAlign: "middle" }}>
														{item.quantity_ordered}
													</td>
													<td style={{ verticalAlign: "middle" }}>
														{item.confirmed_quantity}
													</td>
													<td style={{ verticalAlign: "middle" }}>
														{item.quantity_ordered - item.confirmed_quantity}
													</td>
													<td
														style={{
															color: getStatusColor(item.status),
															verticalAlign: "middle",
														}}
													>
														{item.status}
													</td>
													<td style={{ verticalAlign: "middle" }}>
														<Dropdown
															show={inventoryDropdownOpen[index]}
															onToggle={() => toggleInventoryDropdown(index)}
														>
															<Dropdown.Toggle
																className="dropdown-toggle"
																variant="transparent"
																style={{
																	background: "none",
																	border: "none",
																	boxShadow: "none",
																}}
															>
																<img
																	src={Action}
																	alt="Action"
																	className="action-icon"
																	style={{ margin: "0", padding: "0" }}
																/>
															</Dropdown.Toggle>
															<Dropdown.Menu>
																<Dropdown.Item
																	onClick={() =>
																		handleOpenViewOrderModal(item.id)
																	}
																>
																	View
																</Dropdown.Item>
																{hasPermission(
																	permissions,
																	"confirm-inventory",
																) && (
																		<Dropdown.Item
																			onClick={() =>
																				handleOpenConfirmOrderModal(item.id)
																			}
																		>
																			Confirm
																		</Dropdown.Item>
																	)}
															</Dropdown.Menu>
														</Dropdown>
													</td>
												</tr>
											))}
										</tbody>
									</Table>
									{inventoryMeta && inventoryMeta.total_pages > 1 && (
										<div className="d-flex justify-content-center mt-3 pb-5">
											<Pagination
												count={inventoryMeta && inventoryMeta?.total_pages}
												page={pageNumber}
												onChange={handleInventoryChange}
												shape="rounded"
												color="primary"
											/>
										</div>
									)}
								</div>
							</>
						)}
						{activeTab === "material-list" && (
							<div className="">
								<div className="mt-2 d-inline-flex justify-content-between w-100">
									<div className="d-inline-flex justify-content-between w-">
										<Card className="bg-color-5 text-color-4 border-0 p-2 mx-1 w-fit">
											<small>Material count</small>
											<h3>7</h3>
										</Card>

										<Card className="bg-color-6 text-color-5 border-0 p-2 mx-1 w-fit">
											<small>Total material qty </small>
											<h3>3</h3>
										</Card>

										<Card className="bg-color-7 text-color-6 border-0 p-2 mx-1 w-fit">
											<small> Total out of stock</small>
											<h3>1</h3>
										</Card>
									</div>
									<div className="d-inline-flex align-items-end">
										<div className="mx-2 border border-1 rounded py-1 px-2 d-inline-flex justify-content-between align-items-center">
											<input
												type="search"
												placeholder="Search for material"
												className="bg-transparent border-0"
												style={{ outline: "0" }}
											/>
											<img src={searchIcon} alt="search icon" />
										</div>
									</div>
								</div>
								<div className="bg-color-9 table-container px-5 mt-4">
									<Table borderless responsive>
										<thead>
											<tr>
												<th className="text-color-1 bg-color-3">S/N</th>
												<th className="text-color-1 bg-color-3">
													Material name{" "}
												</th>
												<th className="text-color-1 bg-color-3">
													Unit in stock
												</th>
												<th className="text-color-1 bg-color-3">Status</th>
											</tr>
										</thead>
										<tbody>
											{confirmedInventory?.map((item, index) => (
												<tr key={item.id}>
													<td style={{ verticalAlign: "middle" }}>
														{confirmedInventory.indexOf(item) + 1}
													</td>
													<td style={{ verticalAlign: "middle" }}>
														{item.material}
													</td>
													<td style={{ verticalAlign: "middle" }}>
														{item.Instock}
													</td>
													<td
														style={{
															color: getConfirmedStatusColor(item.Status),
															verticalAlign: "middle",
														}}
													>
														{item.Status}
													</td>
												</tr>
											))}
										</tbody>
									</Table>
									{confirmedInventoryMeta &&
										confirmedInventoryMeta.total_pages > 1 && (
											<div className="d-flex justify-content-center mt-3 pb-5">
												<Pagination
													count={
														confirmedInventoryMeta &&
														confirmedInventoryMeta?.total_pages
													}
													page={pageNumber}
													onChange={handleChange}
													shape="rounded"
													color="primary"
												/>
											</div>
										)}
								</div>
							</div>
						)}
						{activeTab === "store-request" && (
							<>
								<div className="w-100 d-inline-flex justify-content-end mt-4">
									{hasPermission(permissions, "create-inventory") && (
										<Button
											onClick={handleOpenCreateStoreRequestModal}
											className="border-0 bg-color-1 float-end"
										>
											<span className="text-center">Create store request</span>
										</Button>
									)}
									<div className="mx-2 border border-1 rounded py-1 px-2 d-inline-flex justify-content-between align-items-center">
										<input
											type="search"
											placeholder="Search for material"
											className="bg-transparent border-0"
											style={{ outline: "0" }}
										/>
										<img src={searchIcon} alt="search icon" />
									</div>
								</div>

								<div className="bg-color-9 table-container px-5 pt-3">
									<Table borderless responsive>
										<thead>
											<tr>
												<th className="text-color-1 bg-color-3">S/N</th>
												<th className="text-color-1 bg-color-3">Date </th>
												<th className="text-color-1 bg-color-3">Material </th>
												<th className="text-color-1 bg-color-3">
													Requested by{" "}
												</th>
												<th className="text-color-1 bg-color-3">
													Collected by{" "}
												</th>
												<th className="text-color-1 bg-color-3">
													Qty collected
												</th>
												<th className="text-color-1 bg-color-3">Action</th>
											</tr>
										</thead>
										<tbody>
											{storeRequests?.map((item, index) => (
												<tr key={item.id}>
													<td style={{ verticalAlign: "middle" }}>
														{storeRequests.indexOf(item) + 1}
													</td>
													<td>
														{format(
															new Date(item.confirmed_inventory.created_at),
															"dd/MM/yy",
														)}
													</td>
													<td>{item.confirmed_inventory.material_name}</td>
													<td>{item.created_by}</td>
													<td>{item.collected_by}</td>
													<td>{item.quantity_collected}</td>
													<td style={{ padding: "0", verticalAlign: "middle" }}>
														<Dropdown
															show={storeRequestDropdownOpen[index]}
															onToggle={() => toggleStoreRequestDropdown(index)}
														>
															<Dropdown.Toggle
																className="dropdown-toggle"
																variant="transparent"
																style={{
																	background: "none",
																	border: "none",
																	boxShadow: "none",
																}}
															>
																<img
																	src={Action}
																	alt="Action"
																	className="action-icon mb-3"
																	style={{ margin: "0", padding: "0" }}
																/>
															</Dropdown.Toggle>
															<Dropdown.Menu>
																<Dropdown.Item
																	onClick={() =>
																		handleOpenViewStoreRequestModal(item.id)
																	}
																>
																	View
																</Dropdown.Item>
															</Dropdown.Menu>
														</Dropdown>
													</td>
												</tr>
											))}
										</tbody>
									</Table>
									{storeRequestsMeta && storeRequestsMeta.total_pages > 1 && (
										<div className="d-flex justify-content-center mt-3 pb-5">
											<Pagination
												count={
													storeRequestsMeta && storeRequestsMeta?.total_pages
												}
												page={pageNumber}
												onChange={handleChange}
												shape="rounded"
												color="primary"
											/>
										</div>
									)}
								</div>
							</>
						)}
						{/* Usage tab */}
						{activeTab === "usage" && (
							<>
								<div className="d-inline-flex justify-content-end w-100 mt-4">
									<div className="mx-2 border border-1 rounded py-1 px-2 d-inline-flex justify-content-between align-items-center my-3">
										<input
											type="search"
											placeholder="Search for material"
											className="bg-transparent border-0"
											style={{ outline: "0" }}
										/>
										<img src={searchIcon} alt="search icon" />
									</div>
								</div>
								<div className="bg-color-9 table-container px-5 pt-3">
									<Table borderless responsive>
										<thead>
											<tr>
												<th className="text-color-1 bg-color-3">Date </th>
												<th className="text-color-1 bg-color-3">Material </th>
												<th className="text-color-1 bg-color-3">
													Qty collected{" "}
												</th>
												<th className="text-color-1 bg-color-3">
													Qty returned{" "}
												</th>
												<th className="text-color-1 bg-color-3">Qty used</th>
												<th className="text-color-1 bg-color-3">Action</th>
											</tr>
										</thead>
										<tbody>
											{usages?.map((item, index) => (
												<tr key={item.id}>
													<td style={{ verticalAlign: "middle" }}>
														{format(new Date(item.created_at), "dd/MM/yy")}
													</td>
													<td style={{ verticalAlign: "middle" }}>
														{item.material_name}
													</td>
													<td style={{ verticalAlign: "middle" }}>
														{item.quantity}
													</td>
													<td style={{ verticalAlign: "middle" }}>
														{item.quantity_returned === null
															? 0
															: item.quantity_returned}
													</td>
													<td style={{ verticalAlign: "middle" }}>
														{item.quantity_used}
													</td>
													<td style={{ verticalAlign: "middle" }}>
														<Dropdown
															show={usageDropdownOpen[index]}
															onToggle={() => toggleUsageDropdown(index)}
														>
															<Dropdown.Toggle
																className="dropdown-toggle"
																variant="transparent"
																style={{
																	background: "none",
																	border: "none",
																	boxShadow: "none",
																}}
															>
																<img
																	src={Action}
																	alt="Action"
																	className="action-icon"
																	style={{ margin: "0", padding: "0" }}
																/>
															</Dropdown.Toggle>
															<Dropdown.Menu>
																<Dropdown.Item
																	onClick={() =>
																		handleOpenViewUsageModal(item.id)
																	}
																>
																	View
																</Dropdown.Item>
																<Dropdown.Item
																	onClick={() =>
																		handleOpenReturnUsageModal(item.id)
																	}
																>
																	Return
																</Dropdown.Item>
															</Dropdown.Menu>
														</Dropdown>
													</td>
												</tr>
											))}
										</tbody>
									</Table>
									{usagesMeta && usagesMeta.total_pages > 1 && (
										<div className="d-flex justify-content-center mt-3 pb-5">
											<Pagination
												count={usagesMeta && usagesMeta?.total_pages}
												page={pageNumber}
												onChange={handleChange}
												shape="rounded"
												color="primary"
											/>
										</div>
									)}
								</div>
							</>
						)}
					</div>
				</div>
				<ConfirmOrder
					show={confirmOrderModalOpen}
					closeModal={handleCloseConfirmOrderModal}
					inventoryItemId={selectedInventoryItemId}
					onConfirmSuccess={handleCreateSuccess}
					projectId={projectId}
				/>
				<CreateStoreRequest
					show={createStoreRequestModalOpen}
					inventory={confirmedInventory}
					closeModal={handleCloseCreateStoreRequestModal}
					onCreateSuccess={handleCreateSuccess}
				/>
				<ViewOrder
					show={viewOrderModalOpen}
					closeModal={handleCloseViewOrderModal}
					id={selectedInventoryItemId}
					project_id={projectId}
					company_id={companyId}
				/>
				<ViewStoreRequest
					closeModal={handleCloseViewStoreRequestModal}
					company_id={companyId}
					id={selectedStoreRequestId}
					project_id={projectId}
					show={viewStoreRequestModalOpen}
				/>
				<ViewUsage
					closeModal={handleCloseViewUsageModal}
					company_id={companyId}
					id={selectedUsageId}
					project_id={projectId}
					show={viewUsageModalOpen}
				/>
				<ReturnUsage
					closeModal={handleCloseReturnUsageModal}
					onReturnSuccess={handleCreateSuccess}
					projectId={projectId}
					show={returnUsageModalOpen}
					storeRequestId={selectedUsageId}
				/>
			</Layout>
		</div>
	);
}

export default Inventory;
