import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Container, Form } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
	getATaskAsync,
	updateTaskAsync,
} from "../../../../slices/project-management/project-management-slice";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { getAllUsersAsync } from "../../../../slices/user/userSlice";
import { toast } from "react-toastify";
import { RootState } from "../../../../store/store";

interface UpdateIssueValues {
	issue_name: string;
	associate_task: number;
	priority: string;
	end_date: string;
	issue_status: string;
	image: string;
}

interface UpdateIssueProps {
	closeModal: () => void;
	show: boolean;
	project_id: string | undefined | null;
	id: string;
	onEditSuccess: () => void;
}

const UpdateIssue: React.FC<UpdateIssueProps> = ({
	closeModal,
	show,
	project_id,
	id,
	onEditSuccess,
}) => {
	const dispatch = useAppDispatch();
	const [pageNumber, setPage] = useState(1);
	const company_id = localStorage.getItem("userCompanyID");
	const task = useAppSelector((state) => state.projectManagement.taskData);

	useEffect(() => {
		if (project_id && company_id) {
			dispatch(getATaskAsync({ id, company_id, projectId: project_id }));
		}
	}, [company_id, dispatch, id, project_id]);

	const users = useAppSelector((state) => state.user.users?.data);
	const companyId = useAppSelector(
		(state: RootState) => state.auth.user?.company_id || "",
	);
	useEffect(() => {
		if (companyId) {
			dispatch(getAllUsersAsync({ companyId, page: pageNumber }));
		}
	}, [companyId, pageNumber, dispatch]);

	return (
		<Container fluid className="">
			<Modal
				size="lg"
				show={show}
				onHide={closeModal}
				style={{ maxHeight: "100vh", overflowY: "auto" }}
			>
				<div className="px-5">
					<Formik
						enableReinitialize
						initialValues={{
							issue_name:
								//  task?.issue_name ||
								"Issue Name",
							associate_task:
								//  task?.associate_task ||
								0,
							priority:
								//  task?.priority ||
								"",
							end_date:
								//  task?.end_date ||
								"",
							issue_status:
								//  task?.issue_status ||
								"",
							image:
								//  task?.image ||
								"",
						}}
						validationSchema={Yup.object().shape({
							issue_name: Yup.string(),
							associate_task: Yup.number(),
							priority: Yup.string(),
							end_date: Yup.string(),
							issue_status: Yup.string(),
							image: Yup.string(),
						})}
						onSubmit={(values: UpdateIssueValues, { setSubmitting }) => {
							// dispatch(
							// 	updateTaskAsync({
							// 		company_id: company_id,
							// 		projectId: project_id,
							// 		id: id,
							// 		issue_name: values.issue_name,
							// 		associate_task: values.associate_task,
							// 		priority: values.priority,
							// 		end_date: values.end_date,
							// 		issue_status: values.issue_status,
							// 		image: values.image,
							// 	}),
							// )
							// 	.unwrap()
							// 	.then((response) => {
							// 		toast.success(response.message);
							// 		onEditSuccess();
							// 		closeModal();
							// 	})
							// 	.catch((error) => {
							// 		const errorMessage = Object.values(error.message)
							// 			.flat()
							// 			.join(" ");
							// 		toast.error(errorMessage);
							// 	})
							// 	.finally(() => {
							// 		setSubmitting(false);
							// 	});
							console.log("Forms:\n", values);
						}}
						validateOnChange
						validateOnBlur
					>
						{({
							errors,
							touched,
							handleSubmit,
							isSubmitting,
							values,
							handleChange,
							dirty,
						}) => (
							<Form onSubmit={handleSubmit}>
								<Modal.Header closeButton className="text-start mt-2">
									<h4>Edit Task</h4>
								</Modal.Header>
								<Modal.Body>
									<Row>
										<Col md={12} className="mt-2 w-50">
											<label className="mt-2">
												<p>
													Task name <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="issue_name"
												className={`fluent-input  ${
													touched.issue_name && errors.issue_name
														? "is-invalid"
														: ""
												}`}
											></Field>
										</Col>
										<Col md={12} className="mt-2 w-50">
											<label className="mt-2">
												<p>
													Associate task <span className="text-danger">*</span>
												</p>
											</label>
											<FormControl fullWidth>
												<InputLabel>Task</InputLabel>
												<Select
													label="associate_task"
													name="associate_task"
													value={values.associate_task}
													className={`fluent-input  ${
														touched.associate_task && errors.associate_task
															? "is-invalid"
															: ""
													}`}
													onChange={handleChange}
												>
													{users !== undefined && users !== null ? (
														users.map((user) => (
															<MenuItem key={user.id} value={user.id}>
																{user.first_name} {user.last_name}
															</MenuItem>
														))
													) : (
														<MenuItem value="">Task</MenuItem>
													)}
												</Select>
											</FormControl>
										</Col>
									</Row>
									<Row>
										<Col md={12} className="mt-2 w-50">
											<label className="mt-2">
												<p>
													Priority <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="priority"
												className={`fluent-input  ${
													touched.priority && errors.priority
														? "is-invalid"
														: ""
												}`}
											></Field>
										</Col>
										<Col md={12} className="mt-2 w-50">
											<label className="mt-2">
												<p>
													Issue status <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												as="select"
												name="issue_status"
												value={values.issue_status}
												className={`fluent-input  ${
													touched.issue_status && errors.issue_status
														? "is-invalid"
														: ""
												}`}
											>
												<option value="To do">To do</option>
												<option value="In progress">In progress</option>
												<option value="Complete">Complete</option>
											</Field>
										</Col>
									</Row>
									<Row>
										<Col md={12} className="mt-2 w-50">
											<label className="mt-2">
												<p>
													Image <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="file"
												name="image"
												accept="image/*"
												value={values.image}
												className={`fluent-input  ${
													touched.image && errors.image ? "is-invalid" : ""
												}`}
											/>
										</Col>
									</Row>

									<Modal.Footer className="mt-5">
										<Row>
											<Col md={6}>
												<div className=" mt-1">
													<Button onClick={closeModal} className="cancel-btn">
														<span className="text-center">Cancel </span>{" "}
													</Button>
												</div>
											</Col>
											<Col md={6}>
												<div className=" mt-1">
													<Button
														className="border-0 create-btn bg-color-1"
														onClick={() => handleSubmit()}
														disabled={!dirty || isSubmitting}
													>
														{`${
															isSubmitting ? "Editing task..." : "Edit task"
														} `}
													</Button>
												</div>
											</Col>
										</Row>
									</Modal.Footer>
								</Modal.Body>
							</Form>
						)}
					</Formik>
				</div>
			</Modal>
		</Container>
	);
};

export default UpdateIssue;
