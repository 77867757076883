import React from "react";
import { ReactNode } from "react";
import SideBar from "../sidebar/sidebar";
import NavBar from "components/navbar/navbar";
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
interface Props {
	children: ReactNode;
}

const Layout: React.FC<Props> = ({ children }) => {
	const userId = useAppSelector((state: RootState) => state.auth.user?.id);

	return (
		<div className="main-layout">
			<NavBar />
			<div className="layout-content">
				<SideBar userId={userId || ""} />				<main className="content">
					<div>{children}</div>
				</main>
			</div>
		</div>
	);
};

export default Layout;
