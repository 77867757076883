import React from "react";
import { Modal, Row, Col, Container, Button } from 'react-bootstrap';


interface ConfirmUpdateStatusProps {
    closeModal: () => void;
    show: boolean;
    user: { id: string; statusId: number } | null;
    onConfirm: (userId: string, statusId: number) => void;
}

const ConfirmUpdateStatus: React.FC<ConfirmUpdateStatusProps> = ({ closeModal, show, user, onConfirm }) => {
    if (!user) return null;

    const handleConfirm = () => {
        onConfirm(user.id, user.statusId);
        closeModal();
    };

    return (
        <Container>
            <Modal className="confirm-modal" size="lg" show={show} onHide={closeModal} style={{ maxHeight: "100vh", overflowY: "auto", maxWidth: "90vh" }}>
                <div className='px-4'>
                    <Modal.Header closeButton className='text-start'>
                        <h4 className="confirm-header">{user.statusId === 1 ? "Activate User" : "Deactivate User"}</h4>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="confirm-body">Are you sure you want to {user.statusId === 1 ? "activate" : "deactivate"} this user?</p>
                    </Modal.Body>
                    <Modal.Footer className='mt-2'>
                        <Row>
                            <Col md={6}>
                                <div className=' mt-1'>
                                    <Button onClick={closeModal} className='cancel-btn'>
                                        <span className="text-center">No</span>
                                    </Button>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className=' mt-1'>
                                    <Button onClick={handleConfirm} className=' border-0 create-btn bg-color-1'>
                                        <span className="text-center">Yes</span>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </div>
            </Modal>
        </Container>
    );
};


export default ConfirmUpdateStatus;