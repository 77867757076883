import React from "react";
import { Container, Modal, CloseButton, Row } from "react-bootstrap";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/store";

interface ViewImageProps {
	closeModal: () => void;
	// id: string | null;
	// project_id: string | undefined;
	// company_id: string | null;
	show: boolean;
}
const ViewImage: React.FC<ViewImageProps> = ({
	closeModal,
	// company_id,
	// id,
	// project_id,
	show,
}) => {
	const dispatch = useAppDispatch();

	return (
		<Container>
			<Modal
				show={show}
				onHide={closeModal}
				centered
				style={{ borderRadius: "20px" }}
			>
				<div>
					<Modal.Header closeButton className="text-start">
						<h4 className="confirm-header">View image</h4>
					</Modal.Header>
					<Modal.Body>
						<h1 className="text-center">Image</h1>
					</Modal.Body>
				</div>
			</Modal>
		</Container>
	);
};

export default ViewImage;
