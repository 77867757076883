import axios from 'axios';

export interface SignUpData {
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    company_name: string;
    company_size: string;
    password: string;
    confirm_password: string;
    terms: boolean;
}
export interface UserData {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    company_id: string;
    company_name: string;
    created_at: string;
    updated_at: string;
}
export interface GetUserResponse {
    message: string;
    data: UserData;
}

export interface CompanyData {
    id: string;
    company_name: string;
    company_size: string;
    created_at: string;
    updated_at: string;
}

export interface SignUpResponse {
    data: {
        user: UserData;
        company: CompanyData;
    };
    message: string;
}
export interface CheckVerificationResponse {
    isVerified: boolean;
}
export interface ResendData {
    email: string;
}
export interface ResendResponse {
    data: {
        email: string;
    };
    message: string;
}
export interface LogInData {
    email: string;
    password: string;
}
export interface LogInResponse {
    data: {
        user: UserData;
        token: string;
    };
    message: string;
}
export interface LogoutResponse {
    message: string;
}
export interface ForgotPasswordData {
    email: string;
}
export interface ForgotPasswordResponse {
    data: {
        email: string;
        token: string;
    } | null;
    message: string;
}
export interface ResetPasswordData {
    email: string;
    token: string;
    password: string;
    password_confirmation: string;
}
export interface ResetPasswordResponse {
    data: {
        email: string;
    }
    message: string;
}
export interface SetNewPasswordData {
    email: string;
    token: string;
    old_password: string;
    password: string;
    password_confirmation: string;
}

export interface SetNewPasswordResponse {
    data: {
        email: string;
    }
    message: string;
}
export const signup = async (values: SignUpData): Promise<SignUpResponse> => {
    const response = await axios.post<SignUpResponse>('https://syteboxx-api.remsys.com.ng/api/v1/onboarding/register', values);
    return response.data;
}
export const checkVerification = async () => {
    try {
        const response = await axios.get('https://syteboxx-api.remsys.com.ng/api/v1/onboarding/verify');
        return response.data;
    } catch (error) {
        console.error('Verification check failed:', error);
        return { isVerified: false };
    }
};
export const resend = async (values: ResendData): Promise<ResendResponse> => {
    const response = await axios.post<ResendResponse>('https://syteboxx-api.remsys.com.ng/api/v1/onboarding/resend', values);
    return response.data;
}
export const login = async (values: LogInData): Promise<LogInResponse> => {
    const response = await axios.post<LogInResponse>('https://syteboxx-api.remsys.com.ng/api/v1/auth/login', values);
    return response.data;
}
export const getUser = async (): Promise<GetUserResponse> => {
    const response = await axios.get<GetUserResponse>('https://syteboxx-api.remsys.com.ng/api/v1/users/get-auth-user');
    return response.data;
}

export const logout = async (): Promise<LogoutResponse> => {
    const response = await axios.post<LogoutResponse>('https://syteboxx-api.remsys.com.ng/api/v1/auth/logout');
    return response.data;
}
export const forgotPassword = async (values: ForgotPasswordData): Promise<ForgotPasswordResponse> => {
    const response = await axios.post<ForgotPasswordResponse>('https://syteboxx-api.remsys.com.ng/api/v1/password/forgot', values);
    console.log('Forgot password response:', response.data);
    return response.data;
}
export const resetPassword = async (values: ResetPasswordData): Promise<ResetPasswordResponse> => {
    const response = await axios.post<ResetPasswordResponse>('https://syteboxx-api.remsys.com.ng/api/v1/password/reset', values);
    return response.data;
}
export const setNewPassword = async (values: SetNewPasswordData): Promise<SetNewPasswordResponse> => {
    const response = await axios.post<SetNewPasswordResponse>('https://syteboxx-api.remsys.com.ng/api/v1/password/change', values);
    return response.data;
}

