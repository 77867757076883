import axios from "axios";

axios.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem("token");
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

export interface User {
	company_id: string;
	company_name: string;
	id: string;
	first_name: string;
	last_name: string;
	email: string;
	phone_number: string;
	role_id: string;
	role_name: string;
	projects: string[];
	permissions: string[];
	system_role: string;
	created_at: string;
	status_id: number;
}
export interface CreateUserData {
	company_id: string;
	first_name: string;
	last_name: string;
	email: string;
	phone_number: string;
	role_id: string;
	projects: string[];
	status_id: number;
}
export interface CreateUserResponse {
	data: User;
	message: string;
	error: boolean;
}
export interface UpdateUserData {
	company_id: string;
	id: string;
	first_name: string;
	last_name: string;
	phone_number: string;
	role_id: string;
	projects: string[];
}
export interface UpdateUserResponse {
	data: User;
	message: string;
	error: boolean;
}
export interface GetAllUsersResponse {
	data: User[];
	message: string;
	meta: {
		total: number;
		count: number;
		per_page: number;
		current_page: number;
		last_page: number;
		total_pages: number;
	};
}
export interface SearchUserResponse {
	data: User[];
	message: string;
	meta: {
		total: number;
		count: number;
		per_page: number;
		current_page: number;
		last_page: number;
		total_pages: number;
	};
}
export interface GetAUserResponse {
	data: User;
	message: string;
}
export interface UpdateUserStatusData {
	companyId: string;
	id: string;
	statusId: number;
}

export interface UpdateUserStatusResponse {
	data: User;
	message: string;
	error: boolean;
}

export const createUser = async (
	values: CreateUserData,
): Promise<CreateUserResponse> => {
	const response = await axios.post<CreateUserResponse>(
		`https://syteboxx-api.remsys.com.ng/api/v1/users/create-user?company_id=${values.company_id}`,
		values,
	);
	return response.data;
};
export const updateUser = async (
	values: UpdateUserData,
	id: string,
): Promise<UpdateUserResponse> => {
	const response = await axios.put<UpdateUserResponse>(
		`https://syteboxx-api.remsys.com.ng/api/v1/users/admin-update-user/${id}?company_id=${values.company_id}`,
		values,
	);
	return response.data;
};
export const getAllUsers = async (
	companyId: string,
	page: number,
): Promise<GetAllUsersResponse> => {
	const response = await axios.get<GetAllUsersResponse>(
		`https://syteboxx-api.remsys.com.ng/api/v1/users/?company_id=${companyId}&page=${page}`,
	);
	return response.data;
};
export const searchUser = async (
	company_id: string,
	searchTerm: string,
): Promise<SearchUserResponse> => {
	const response = await axios.get(
		`https://syteboxx-api.remsys.com.ng/api/v1/users/search/${searchTerm}?company_id=${company_id}`,
	);
	return response.data;
};
export const getAUser = async (
	companyId: string,
	id: string,
): Promise<User> => {
	const response = await axios.get<GetAUserResponse>(
		`https://syteboxx-api.remsys.com.ng/api/v1/users/${id}?company_id=${companyId}`,
	);
	return response.data.data;
};
export const updateUserStatus = async (
	data: UpdateUserStatusData,
): Promise<UpdateUserStatusResponse> => {
	const { companyId, id, statusId } = data;
	const response = await axios.put<UpdateUserStatusResponse>(
		`https://syteboxx-api.remsys.com.ng/api/v1/users/admin-update-user/${id}`,
		{ company_id: companyId, status_id: statusId },
	);
	return response.data;
};
