import React from "react";
import { Container, Modal, CloseButton, Row } from "react-bootstrap";
import { getAnInventoryItemAsync } from "slices/inventory/inventorySlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/store";
import { format, isValid } from "date-fns";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
interface ViewOrderProps {
	closeModal: () => void;
	id: string | null;
	project_id: string | undefined;
	company_id: string | null;
	show: boolean;
}
const ViewOrder: React.FC<ViewOrderProps> = ({
	closeModal,
	company_id,
	id,
	project_id,
	show,
}) => {
	const dispatch = useAppDispatch();
	const inventoryItem = useAppSelector(
		(state: RootState) => state.inventory.inventoryItem,
	);
	React.useEffect(() => {
		if (project_id && company_id && id !== null) {
			dispatch(
				getAnInventoryItemAsync({ id, company_id, projectId: project_id }),
			);
		}
	}, [company_id, dispatch, id, project_id]);

	console.log(inventoryItem);

	const formatDateTime = (dateTime: string | Date): string => {
		const date = new Date(dateTime);
		return isValid(date) ? format(date, "dd/MM/yy h:mm a") : "Invalid Date";
	};

	return (
		<Container>
			<Modal
				show={show}
				onHide={closeModal}
				centered
				style={{ borderRadius: "20px" }}
			>
				<div>
					<Modal.Header className="text-start">
						<h4 className="confirm-header">View confirm order</h4>
						<CloseButton onClick={closeModal} />
					</Modal.Header>

					<Modal.Body>
						<Row
							style={{ fontWeight: "600", fontSize: "12px" }}
							className=" d-flex justify-content-end px-3"
						>
							Total qty remaining:{" "}
							{(inventoryItem?.quantity_ordered ?? 0) -
								(inventoryItem?.confirmed_quantity ?? 0)}
						</Row>

						<Timeline
							placeholder="Timeline"
							onPointerEnterCapture={() => { }}
							onPointerLeaveCapture={() => { }}
							sx={{
								[`& .${timelineItemClasses.root}:before`]: {
									flex: 0,
									padding: 0,
								},
							}}
						>
							{inventoryItem?.confirmations.map((item, index) => (
								<TimelineItem key={index}>
									<TimelineSeparator>
										<TimelineDot
											sx={{
												bgcolor: "#0f6cbd",
												width: 15,
												height: 15,
											}}
											className="mt-4"
										/>
									</TimelineSeparator>
									<TimelineContent>
										<div style={{ fontSize: "14px" }} className="mx-3 mb-1">
											<Row>Qty received: {item.confirmed_quantity}</Row>
											<Row>Received by {item.confirmed_by}</Row>
											<Row
												className="text-color-2"
												style={{ fontSize: "0.7rem" }}
											>
												{formatDateTime(item.confirmed_at)}
											</Row>
										</div>
									</TimelineContent>
								</TimelineItem>
							))}
						</Timeline>
					</Modal.Body>
				</div>
			</Modal>
		</Container>
	);
};

export default ViewOrder;
