import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Container, Form, Spinner } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import {
	updateUserAsync,
	getAllUsersAsync,
	getAUserAsync,
	setUserId,
} from "../../../../slices/user/userSlice";
import { RootState } from "../../../../store/store";
import MultipleSelect from "../../../../components/form/custom-select";
import { Select, FormControl, InputLabel, MenuItem } from "@mui/material";
import { toast } from "react-toastify";
import { handlePhoneNumberChange } from "util/inputUtils";

interface EditTeamValues {
	id: string;
	company_id: string;
	first_name: string;
	last_name: string;
	phone_number: string;
	role_id: string;
	projects: string[];
}

interface EditTeamProps {
	closeModal: () => void;
	show: boolean;
	userId: string;
	onEditSuccess: () => void;
}

const EditTeam: React.FC<EditTeamProps> = ({
	closeModal,
	show,
	userId,
	onEditSuccess,
}) => {
	const dispatch = useAppDispatch();
	const companyId = useAppSelector(
		(state: RootState) => state.auth.user?.company_id || "",
	);
	const userData = useAppSelector((state: any) => state.user.userData || "");
	const roles = useAppSelector((state) => state.settings?.roles?.data);
	const projects = useAppSelector((state) => state.project?.projects || "");
	const updateUserResponse = useAppSelector(
		(state: any) => state.user.updateUserResponse,
	);
	const [fetchTriggered, setFetchTriggered] = useState(false);
	const [loading, setLoading] = useState(true);
	const [pageNumber, setPage] = useState(1);

	useEffect(() => {
		if (userId) {
			dispatch(setUserId(userId));
			console.log("Fetching user with ID:", userId);
			dispatch(getAUserAsync({ companyId, id: userId })).then(() =>
				setLoading(false),
			);
		} else {
			console.log("userId is not set");
		}
	}, [dispatch, companyId, userId]);

	useEffect(() => {
		if (updateUserResponse && !updateUserResponse.error && !fetchTriggered && pageNumber) {
			console.log("success", updateUserResponse);
			dispatch(getAllUsersAsync({ companyId, page: pageNumber }));
			closeModal();
			setFetchTriggered(true);
		}
	}, [updateUserResponse, closeModal, dispatch, companyId, pageNumber, fetchTriggered]);

	if (loading) {
		return null;
	}
	console.log("User:", userData);
	console.log("Roles:", roles);
	console.log("Projects:", projects);
	console.log("Project:", userData?.user?.projects[0]);

	return (
		<Container fluid className="">
			<Modal
				centered
				size="lg"
				show={show}
				onHide={closeModal}
				style={{ maxHeight: "100vh", overflowY: "auto" }}
			>
				<div className="px-5">
					<Formik
						enableReinitialize
						initialValues={{
							id: userData?.user.id || "",
							company_id: companyId || "",
							first_name: userData?.user.first_name || "",
							last_name: userData?.user.last_name || "",
							phone_number: userData?.user.phone_number || "",
							role_id: userData?.user.role_id || "",
							projects:
								userData?.user.projects.map(
									(project: { id: any }) => project.id,
								) || [],
						}}
						validationSchema={Yup.object().shape({
							id: Yup.string(),
							company_id: Yup.string(),
							first_name: Yup.string(),
							last_name: Yup.string(),
							phone_number: Yup.string()
								.matches(
									/^(070|080|081|090|091)[0-9]{8}$/,
									"Please enter a valid 11 digit phone number in Nigerian format e.g 08012345678",
								)
								.required("Please enter a valid 11 digit phone number"),
							role_id: Yup.string(),
							projects: Yup.array()
								.of(Yup.string())
								.min(1, "At least one project is required"),
						})}
						onSubmit={(values: EditTeamValues, { setSubmitting }) => {
							console.log("Submitting values:", values);
							dispatch(
								updateUserAsync({
									updateUserData: values,
									id: userId || "",
								}),
							)
								.unwrap()
								.then((response) => {
									toast.success("Team member updated successfully");
									dispatch(getAllUsersAsync({ companyId, page: pageNumber }));
									onEditSuccess();
									closeModal();
								})
								.catch((error) => {
									const errorMessage = Object.values(error.message)
										.flat()
										.join(" ");
									toast.error(errorMessage);
								})
								.finally(() => {
									setSubmitting(false);
								});
						}}
					>
						{({
							errors,
							touched,
							handleSubmit,
							isSubmitting,
							values,
							handleChange,
							setFieldValue,
						}) => (
							<Form onSubmit={handleSubmit}>
								<Modal.Header closeButton className="text-start mt-2">
									<h4 className="confirm-header">Edit member</h4>
								</Modal.Header>
								<Modal.Body>
									<Row>
										<Col md={6} className="mt-2">
											<label className="mt-2">
												<p>
													First name <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="first_name"
												placeholder="Enter first name"
												className={`fluent-input ${touched.first_name && errors.first_name
													? "is-invalid"
													: ""
													}`}
												required
											/>
										</Col>
										<Col md={6} className="mt-2">
											<label className="mt-2">
												<p>
													Last name <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="last_name"
												placeholder="Enter last name"
												className={`fluent-input ${touched.last_name && errors.last_name
													? "is-invalid"
													: ""
													}`}
												required
											/>
										</Col>
									</Row>
									<Row>
										<Col md={6} className="mt-2">
											<label className="mt-2">
												<p>
													Email address <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="email"
												name="email"
												value={userData?.user.email}
												placeholder="Enter email address"
												className={`fluent-input`}
												required
												disabled
											/>
										</Col>
										<Col md={6} className="mt-2">
											<label className="mt-2">
												<p>
													Phone Number <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="phone_number"
												placeholder="Enter phone number"
												className={`fluent-input ${touched.phone_number && errors.phone_number
													? "is-invalid"
													: ""
													}`}
												onInput={handlePhoneNumberChange}
												required
											/>
										</Col>
									</Row>
									<Row>
										<Col md={6} className="mt-2">
											<label className="mt-2">
												<p>
													Role <span className="text-danger">*</span>
												</p>
											</label>
											<FormControl fullWidth>
												<InputLabel>Select role</InputLabel>
												<Select
													label="Select role"
													name="role_id"
													value={values.role_id}
													onChange={handleChange}
												>
													{roles?.map((role) => (
														<MenuItem key={role.id} value={role.id}>
															{role.role_name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Col>
										<Col md={6} className="mt-2">
											<label className="mt-2">
												<p>
													Assign project <span className="text-danger">*</span>
												</p>
											</label>
											<MultipleSelect
												label="Select project(s)"
												name="projects"
												options={projects.map((project: any) => ({
													value: project.id,
													label: project.project_name,
												}))}
												value={values.projects}
												onChange={(selectedProjects: string[]) =>
													setFieldValue("projects", selectedProjects)
												}
											/>
										</Col>
									</Row>
									<Modal.Footer className="mt-5">
										<Row>
											<Col md={6}>
												<div className="mt-1">
													<Button onClick={closeModal} className="cancel-btn">
														<span className="text-center">Cancel</span>
													</Button>
												</div>
											</Col>
											<Col md={6}>
												<div className="mt-1">
													<Button
														type="submit"
														className="border-0 create-btn bg-color-1"
													>
														{isSubmitting ? (
															<Spinner animation="border" size="sm" />
														) : (
															<span className="text-center">Edit member</span>
														)}
													</Button>
												</div>
											</Col>
										</Row>
									</Modal.Footer>
								</Modal.Body>
							</Form>
						)}
					</Formik>
				</div>
			</Modal>
		</Container>
	);
};

export default EditTeam;
