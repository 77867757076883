import React, { useEffect, useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import {
	createProjectAsync,
	getAllProjectsAsync,
} from "../../slices/project/projectSlice";
import { toast } from "react-toastify";
import errorIcon from "../../assets/images/error-icon.svg";

interface CreateProjectValues {
	company_id: string;
	project_name: string;
	project_location: string;
	project_start_date: string;
	project_end_date: string;
	project_description: string;
}

function CreateFirstProject() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [showToast, setShowToast] = useState(false);

	const companyId = useAppSelector((state: any) => state.auth.companyId) || "";
	const createProjectResponse = useAppSelector(
		(state: any) => state.project.createProjectResponse,
	);

	useEffect(() => {
		if (createProjectResponse && !createProjectResponse.error) {
			setShowToast(true);
			setTimeout(() => {
				setShowToast(false);
				navigate("/projects");
			}, 3000);
		}
	}, [createProjectResponse, navigate]);

	return (
		<div
			style={{ background: "#d3d3d359" }}
			className="vh-100 d-flex flex-column justify-content-center align-items-center py-5"
		>
			<img src={logo} alt="Logo" />
			<h1>Let's setup your first project</h1>
			<p className="fw-bold">
				Fill in project details, you can always edit later
			</p>
			<Formik
				initialValues={{
					company_id: companyId || "",
					project_name: "",
					project_location: "",
					project_start_date: "",
					project_end_date: "",
					project_description: "",
				}}
				validationSchema={Yup.object().shape({
					company_id: Yup.string().required("Required"),
					project_name: Yup.string().required("Required"),
					project_location: Yup.string().required("Required"),
					project_start_date: Yup.string().required("Required"),
					project_end_date: Yup.date()
						.required("Required")
						.test(
							"is-after-start",
							"End date must be after start date",
							function (value) {
								const { project_start_date } = this.parent;
								return project_start_date && value
									? new Date(value) > new Date(project_start_date)
									: true;
							},
						),
					project_description: Yup.string(),
				})}
				onSubmit={(values: CreateProjectValues, { setSubmitting }) => {
					dispatch(
						createProjectAsync({
							...values,
							company_id: companyId || "",
							project_description: values.project_description || "",
						}),
					)
						.unwrap()
						.then((response) => {
							toast.success("Project created successfully");
							dispatch(getAllProjectsAsync(companyId));
						})
						.catch((error) => {
							const errorMessage = Object.values(error.message)
								.flat()
								.join(" ");
							toast.error(errorMessage);
						})
						.finally(() => {
							setSubmitting(false);
						});
				}}
			>
				{({
					errors,
					touched,
					handleSubmit,
					isSubmitting,
					values,
					handleChange,
				}) => (
					<Form onSubmit={handleSubmit}>
						<h4 className="confirm-header">Create project</h4>

						<Row>
							<Col md={6} className="mt-2">
								<label className="mt-2">
									Project name <span className="text-danger">*</span>
								</label>
								<Field
									type="text"
									name="project_name"
									placeholder="Enter your project name"
									className={`fluent-input  ${
										touched.project_name && errors.project_name
											? "is-invalid"
											: ""
									}`}
									required
								/>
								{touched.project_name && errors.project_name ? (
									<p className="error-message mb-0 d-inline-flex align-items-center">
										<img src={errorIcon} alt="error-icon" />
										{errors.project_name}
									</p>
								) : (
									<p className="error-message-placeholder mb-0">no errors</p>
								)}
							</Col>
							<Col md={6} className="mt-2">
								<label className="mt-2">
									Location <span className="text-danger">*</span>
								</label>
								<Field
									type="text"
									name="project_location"
									placeholder="Enter your location"
									className={`fluent-input  ${
										touched.project_location && errors.project_location
											? "is-invalid"
											: ""
									}`}
									required
								/>
								{touched.project_location && errors.project_location ? (
									<p className="error-message mb-0 d-inline-flex align-items-center">
										<img src={errorIcon} alt="error-icon" />
										{errors.project_location}
									</p>
								) : (
									<p className="error-message-placeholder mb-0">no errors</p>
								)}
							</Col>
						</Row>
						<Row>
							<Col md={6} className="mt-2">
								<label className="mt-2">
									Start date <span className="text-danger">*</span>
								</label>
								<Field
									type="date"
									name="project_start_date"
									className={`fluent-input  ${
										touched.project_start_date && errors.project_start_date
											? "is-invalid"
											: ""
									}`}
									required
								/>
							</Col>
							<Col md={6} className="mt-2">
								<label className="mt-2">
									End date <span className="text-danger">*</span>
								</label>
								<Field
									type="date"
									name="project_end_date"
									className={`fluent-input  ${
										touched.project_end_date && errors.project_end_date
											? "is-invalid"
											: ""
									}`}
									required
								/>
								{touched.project_end_date && errors.project_end_date ? (
									<p className="error-message mb-0 d-inline-flex align-items-center">
										<img src={errorIcon} alt="error-icon" />
										{errors.project_end_date}
									</p>
								) : (
									<p className="error-message-placeholder mb-0">no errors</p>
								)}
							</Col>
						</Row>
						<Row>
							<Col md={12} className="mt-2">
								<label className="mt-2">Description</label>
								<Field
									as="textarea"
									name="project_description"
									placeholder="Enter a company description"
									className={`form-control  ${
										touched.project_description && errors.project_description
											? "is-invalid"
											: ""
									}`}
									rows={5}
									cols={40}
								/>
							</Col>
						</Row>

						<Row>
							<Col md={6}>
								<div className=" mt-1"></div>
							</Col>
							<Col md={6}>
								<div className=" mt-1">
									<Button
										type="submit"
										className="border-0 create-btn bg-color-1"
										disabled={isSubmitting}
									>
										<span className="text-center">Create project</span>{" "}
									</Button>
								</div>
							</Col>
						</Row>
					</Form>
				)}
			</Formik>
		</div>
	);
}

export default CreateFirstProject;
