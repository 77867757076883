import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Container, Form, Spinner } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import {
	updatePettyRequestAsync,
	getAPettyRequestAsync,
	setPettyRequestId,
} from "../../../../slices/requests/requestsSlice";
import { RootState } from "../../../../store/store";
import { Select, FormControl, InputLabel, MenuItem } from "@mui/material";
import { toast } from "react-toastify";
import CurrencyInput from "../../../../components/form/currency-input";
import {
	handleAccountNameChange,
	handleAccountNumberChange,
} from "util/inputUtils";

interface EditUtilityRequestValues {
	company_id: string;
	title: string;
	receiver_name: string;
	bank_id: string;
	account_number: string;
	amount: string;
	projectId: string;
	id: string;
}
interface EditUtilityRequestProps {
	closeModal: () => void;
	show: boolean;
	pettyRequestId: string;
	onEditSuccess: () => void;
	projectId: string;
}

const EditUtilityRequest: React.FC<EditUtilityRequestProps> = ({
	closeModal,
	show,
	pettyRequestId,
	projectId,
	onEditSuccess,
}) => {
	const dispatch = useAppDispatch();
	const companyId = useAppSelector(
		(state: RootState) => state.auth.user?.company_id || "",
	);
	const banks = useAppSelector((state: any) => state.settings.banks);
	const pettyRequestData =
		useAppSelector((state: any) => state.requests.pettyRequestData) || {};
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (pettyRequestId) {
			dispatch(setPettyRequestId(pettyRequestId));
			dispatch(
				getAPettyRequestAsync({ companyId, id: pettyRequestId, projectId }),
			).then(() => setLoading(false));
		} else {
			console.log("pettyRequestId is not set");
		}
	}, [dispatch, companyId, pettyRequestId, projectId]);


	if (loading) {
		return null;
	}

	console.log("pettyRequestData", pettyRequestData);

	return (
		<Container fluid className="">
			<Modal
				centered
				size="lg"
				show={show}
				onHide={closeModal}
				style={{ maxHeight: "100vh", overflowY: "auto" }}
			>
				<div className="px-5">
					<Formik
						initialValues={{
							company_id: companyId || "",
							title: pettyRequestData?.petty_cash_requests?.title || "",
							receiver_name:
								pettyRequestData?.petty_cash_requests?.receiver_name || "",
							bank_id: pettyRequestData?.petty_cash_requests?.bank_id || "",
							account_number:
								pettyRequestData?.petty_cash_requests?.account_number || "",
							amount: pettyRequestData?.petty_cash_requests?.amount || "",
							projectId: projectId || "",
							id: pettyRequestData?.petty_cash_requests?.id || "",
						}}
						validationSchema={Yup.object().shape({
							title: Yup.string().required("Required"),
							receiver_name: Yup.string().required("Required"),
							bank_id: Yup.string().required("Required"),
							account_number: Yup.string().required("Required"),
							amount: Yup.string().required("Required"),
						})}
						onSubmit={(values: EditUtilityRequestValues, { setSubmitting }) => {
							console.log(values);
							const formattedAmount =
								typeof values.amount === "string"
									? values.amount.replace(/,/g, "")
									: values.amount;

							dispatch(
								updatePettyRequestAsync({
									updatePettyRequestData: {
										company_id: values.company_id,
										title: values.title,
										receiver_name: values.receiver_name,
										bank_id: values.bank_id,
										account_number: values.account_number,
										amount: formattedAmount,
										projectId: values.projectId,
										id: values.id,
									},
									id: pettyRequestId || "",
								}),
							)
								.unwrap()
								.then((response) => {
									toast.success("Petty cash request updated successfully");
									onEditSuccess();
									closeModal();
								})
								.catch((error) => {
									const errorMessage = Object.values(error.message)
										.flat()
										.join(" ");
									toast.error(errorMessage);
								})
								.finally(() => {
									setSubmitting(false);
								});
						}}
					>
						{({
							errors,
							touched,
							handleSubmit,
							isSubmitting,
							values,
							handleChange,
							handleBlur,
						}) => (
							<Form onSubmit={handleSubmit}>
								<Modal.Header closeButton className="text-start mt-3">
									<h4 className="confirm-header">Edit petty cash request</h4>

								</Modal.Header>
								<Modal.Body>
									<Row>
										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Title <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="title"
												className={`fluent-input  ${touched.title && errors.title ? "is-invalid" : ""
													}`}
												required
											/>
										</Col>
									</Row>
									<Row>
										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Receiver name <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="receiver_name"
												className={`fluent-input  ${touched.receiver_name && errors.receiver_name
													? "is-invalid"
													: ""
													}`}
												onInput={handleAccountNameChange}
												required
											/>
										</Col>

										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Bank <span className="text-danger">*</span>
												</p>
											</label>
											<FormControl fullWidth>
												<InputLabel>Select bank </InputLabel>
												<Select
													label="Select bank "
													name="bank_id"
													value={values.bank_id}
													onChange={handleChange}
												>
													{Array.isArray(banks) && banks.length > 0 ? (
														banks.map((bank) => (
															<MenuItem key={bank.id} value={bank.id}>
																{bank.bank_name}
															</MenuItem>
														))
													) : (
														<p> no banks </p>
													)}
												</Select>
											</FormControl>
										</Col>
									</Row>
									<Row>
										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Account no <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="number"
												name="account_number"
												className={`fluent-input  ${touched.account_number && errors.account_number
													? "is-invalid"
													: ""
													}`}
												required
												onInput={handleAccountNumberChange}
											/>
										</Col>

										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Amount <span className="text-danger">*</span>
												</p>
											</label>
											<CurrencyInput
												name="amount"
												placeholder="Enter amount"
												className={`fluent-input  ${touched.amount && errors.amount ? "is-invalid" : ""
													}`}
												value={values.amount}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Col>
									</Row>

									<Modal.Footer className="mt-5">
										<Row>
											<Col md={6}>
												<div className=" mt-1">
													<Button onClick={closeModal} className="cancel-btn">
														<span className="text-center">Cancel </span>{" "}
													</Button>
												</div>
											</Col>
											<Col md={6}>
												<div className=" mt-1">
													<Button
														type="submit"
														className=" border-0 create-btn bg-color-1"
													>
														{isSubmitting ? (
															<Spinner animation="border" size="sm" />
														) : (
															<span className="text-center">Edit request</span>
														)}													</Button>
												</div>
											</Col>
										</Row>
									</Modal.Footer>
								</Modal.Body>
							</Form>
						)}
					</Formik>
				</div>
			</Modal>
		</Container>
	);
};

export default EditUtilityRequest;
