/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import Layout from '../../../components/layouts/layout'
import { Nav, Table, Button, Row, Col, Dropdown } from 'react-bootstrap';
import CreateMaterialRequest from './modals/create-material-request';
import EditMaterialRequest from './modals/edit-material-request';
import ViewMaterialRequest from './modals/view-material-request';
import ReviewMaterialRequest from './modals/review-material-request';
import ApproveMaterialRequest from './modals/approve-material-request';
import RejectMaterialRequest from './modals/reject-material-request';
import CreateLabourRequest from './modals/create-labour-request';
import EditLabourRequest from './modals/edit-labour-request';
import ViewLabourRequest from './modals/view-labour-request';
import ReviewLabourRequest from './modals/review-labour-request';
import ApproveLabourRequest from './modals/approve-labour-request';
import RejectLabourRequest from './modals/reject-labour-request';
import CreateUtilityRequest from './modals/create-utility-request';
import EditUtilityRequest from './modals/edit-utility-request';
import ViewUtilityRequest from './modals/view-utility-request';
import ReviewUtilityRequest from './modals/review-utility-request';
import ApproveUtilityRequest from './modals/approve-utility-request';
import RejectUtilityRequest from './modals/reject-utility-request';
import Action from "../../../assets/images/action-vector.png"
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { getAllLabourRequestsAsync, getAllMaterialRequestsAsync, getAllPettyRequestsAsync } from '../../../slices/requests/requestsSlice';
import { getUserAsync } from "../../../slices/auth/authSlice";
import { format } from 'date-fns';
import { hasPermission } from "../../../util/permissionchecker";
import { extractPermissionNames } from '../../../util/transformPermissions';
import Pagination from '@mui/material/Pagination';

type RequestCache = {
    [key: string]: any;
};

function Procurement() {
    const formatCurrency = (amount: number): string => {
        return `₦${amount.toLocaleString('en-US')}`;
    };
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getUserAsync());
    }, [dispatch]);
    const userData = useAppSelector((state: any) => state.user?.userData?.user);
    const isAdmin = userData?.system_role === 'admin';
    const permissions = isAdmin ? null : extractPermissionNames(userData?.permissions?.permissions || []);

    console.log('User permissions:', permissions);
    const { projectId } = useParams<{ projectId: string }>();
    const companyId = useAppSelector(
        (state: RootState) => state.auth.user?.company_id || "",
    );
    const [activeTab, setActiveTab] = useState<'material-request' | 'labour-request' | 'utility-request'>('material-request');
    const handleTabSelect = (eventKey: string | null) => {
        if (eventKey) {
            setActiveTab(eventKey as 'material-request' | 'labour-request' | 'utility-request');
        }
    };
    const [selectedMaterialRequestId, setSelectedMaterialRequestId] = useState<string | null>(null);
    const [selectedLabourRequestId, setSelectedLabourRequestId] = useState<string | null>(null);
    const [selectedPettyRequestId, setSelectedPettyRequestId] = useState<string | null>(null);
    const [approveMaterialRequestModalOpen, setApproveMaterialRequestModalOpen] = useState<boolean>(false);
    const handleOpenApproveMaterialRequestModal = (materialRequestId: string) => {
        setSelectedMaterialRequestId(materialRequestId);
        setApproveMaterialRequestModalOpen(true);
    };
    const handleCloseApproveMaterialRequestModal = () => setApproveMaterialRequestModalOpen(false);
    const [approveLabourRequestModalOpen, setApproveLabourRequestModalOpen] = useState<boolean>(false);
    const handleOpenApproveLabourRequestModal = (labourRequestId: string) => {
        setSelectedLabourRequestId(labourRequestId);
        setApproveLabourRequestModalOpen(true);
    };
    const handleCloseApproveLabourRequestModal = () => setApproveLabourRequestModalOpen(false);
    const [approvePettyRequestModalOpen, setApprovePettyRequestModalOpen] = useState<boolean>(false);
    const handleOpenApprovePettyRequestModal = (pettyRequestId: string) => {
        setSelectedPettyRequestId(pettyRequestId);
        setApprovePettyRequestModalOpen(true);
    };
    const handleCloseApprovePettyRequestModal = () => setApprovePettyRequestModalOpen(false);
    const [reviewMaterialRequestModalOpen, setReviewMaterialRequestModalOpen] = useState<boolean>(false);
    const handleOpenReviewMaterialRequestModal = (materialRequestId: string) => {
        setSelectedMaterialRequestId(materialRequestId);
        setReviewMaterialRequestModalOpen(true);
    };
    const handleCloseReviewMaterialRequestModal = () => setReviewMaterialRequestModalOpen(false);
    const [reviewLabourRequestModalOpen, setReviewLabourRequestModalOpen] = useState<boolean>(false);
    const handleOpenReviewLabourRequestModal = (labourRequestId: string) => {
        setSelectedLabourRequestId(labourRequestId);
        setReviewLabourRequestModalOpen(true);
    };
    const handleCloseReviewLabourRequestModal = () => setReviewLabourRequestModalOpen(false);
    const [reviewPettyRequestModalOpen, setReviewPettyRequestModalOpen] = useState<boolean>(false);
    const handleOpenReviewPettyRequestModal = (pettyRequestId: string) => {
        setSelectedPettyRequestId(pettyRequestId);
        setReviewPettyRequestModalOpen(true);
    };
    const handleCloseReviewPettyRequestModal = () => setReviewPettyRequestModalOpen(false);
    const [createRequestType, setCreateRequestType] = useState<'material' | 'labour' | 'petty' | null>(null);
    const [editRequestType, setEditRequestType] = useState<'material' | 'labour' | 'utility' | null>(null);

    const { materialRequests, labourRequests, pettyRequests, materialRequestsMeta, labourRequestsMeta, pettyRequestsMeta } = useAppSelector(state => ({
        materialRequests: state.requests.materialRequests?.data,
        labourRequests: state.requests.labourRequests?.data,
        pettyRequests: state.requests.pettyRequests?.data,
        materialRequestsMeta: state.requests.materialRequests?.meta,
        labourRequestsMeta: state.requests.labourRequests?.meta,
        pettyRequestsMeta: state.requests.pettyRequests?.meta,
    }));

    const [openModal, setOpenModal] = useState<{
        editMaterial: string | null;
        viewMaterial: string | null;
        createMaterial: string | null;
        rejectMaterial: string | null;
        editLabour: string | null;
        viewLabour: string | null;
        createLabour: string | null;
        rejectLabour: string | null;
        editPetty: string | null;
        viewPetty: string | null;
        createPetty: string | null;
        rejectPetty: string | null;
    }>({
        editMaterial: null,
        viewMaterial: null,
        createMaterial: null,
        rejectMaterial: null,
        editLabour: null,
        viewLabour: null,
        createLabour: null,
        rejectLabour: null,
        editPetty: null,
        viewPetty: null,
        createPetty: null,
        rejectPetty: null
    });

    const handleOpenModal = (modalType: string, requestId: string) => {
        setOpenModal(prevState => ({
            ...prevState,
            [modalType]: requestId,
        }));
    };


    const handleCloseModal = (modalType: string) => {
        setOpenModal(prevState => ({
            ...prevState,
            [modalType]: null,
        }));
    };

    const handleOpenCreateModal = (type: 'material' | 'labour' | 'petty') => {
        setCreateRequestType(type);
        setOpenModal(prevState => ({
            ...prevState,
            [`create${type.charAt(0).toUpperCase() + type.slice(1)}`]: type, // This will correctly set 'createMaterial', 'createLabour', 'createPetty'
        }));
    };

    const handleCloseCreateModal = () => {
        setOpenModal(prevState => ({
            ...prevState,
            createMaterial: null,
            createLabour: null,
            createPetty: null,
        }));
    };
    const handleOpenEditModal = (type: 'material' | 'labour' | 'utility') => {
        setEditRequestType(type);
        setOpenModal(prevState => ({
            ...prevState,
            [`create${type.charAt(0).toUpperCase() + type.slice(1)}`]: type,
        }));
    };

    const handleCloseEditModal = () => {
        setOpenModal(prevState => ({
            ...prevState,
            editMaterial: null,
            editLabour: null,
            editPetty: null,
        }));
    };


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
    const [materialRequestCache, setMaterialRequestCache] = useState<RequestCache>({});
    const [labourRequestCache, setLabourRequestCache] = useState<RequestCache>({});
    const [pettyRequestCache, setPettyRequestCache] = useState<RequestCache>({});

    const [materialRequestDropdownOpen, setMaterialRequestDropdownOpen] = useState<{
        [key: number]: boolean;
    }>({});
    const toggleMaterialRequestDropdown = (index: number) => {
        setMaterialRequestDropdownOpen((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const [labourRequestDropdownOpen, setLabourRequestDropdownOpen] = useState<{
        [key: number]: boolean;
    }>({});

    const toggleLabourRequestDropdown = (index: number) => {
        setLabourRequestDropdownOpen((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const [utilityRequestDropdownOpen, setUtilityRequestDropdownOpen] = useState<{
        [key: number]: boolean;
    }>({});

    const toggleUtilityRequestDropdown = (index: number) => {
        setUtilityRequestDropdownOpen((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };
    const [pageNumber, setPage] = useState(1);
    const handleChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
        if (setPage) {
            setPage(newPage);
        }
    };
    const fetchRequests = () => {
        if (companyId && projectId) {
            if (activeTab === "material-request" && !materialRequestCache[projectId]) {
                dispatch(getAllMaterialRequestsAsync({ companyId, projectId, page: pageNumber }));
            } else if (activeTab === "labour-request" && !labourRequestCache[projectId]) {
                dispatch(getAllLabourRequestsAsync({ companyId, projectId, page: pageNumber }));
            } else if (activeTab === "utility-request" && !pettyRequestCache[projectId]) {
                dispatch(getAllPettyRequestsAsync({ companyId, projectId, page: pageNumber }));
            }
        }
    };

    useEffect(() => {
        fetchRequests();
    }, [companyId, projectId, activeTab, materialRequestCache, labourRequestCache, pettyRequestCache, dispatch, pageNumber]);

    const handleCreateSuccess = () => {
        fetchRequests();
    };

    const handleEditSuccess = () => {
        fetchRequests();
    };


    return (
        <div className="bg-color-8">
            <Layout>
                <div className="mt-5 p-5">
                    <h3>Requests</h3>
                    <Nav
                        activeKey={activeTab}
                        onSelect={handleTabSelect}
                        className="nav px-2 py-2"
                    >
                        <Nav.Item >
                            <Nav.Link
                                className="py-0 px-2 rounded fw-light mx-2"
                                eventKey="material-request"
                                style={{
                                    color:
                                        activeTab === 'material-request'
                                            ? ' #FFFFFF  '
                                            : ' #747487', textDecoration: 'none',
                                    backgroundColor:
                                        activeTab === "material-request" ? "#0F6CBD " : "transparent",
                                }}>Material request</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className=''>
                            <Nav.Link eventKey="labour-request"
                                className="py-0 px-2 rounded fw-light mx-2"

                                style={{
                                    color:
                                        activeTab === 'labour-request'
                                            ? ' #FFFFFF  '
                                            : ' #747487', textDecoration: 'none',
                                    backgroundColor:
                                        activeTab === "labour-request" ? "#0F6CBD " : "transparent",
                                }}>Labour request</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="utility-request"
                                className="py-0 px-2 rounded fw-light mx-2"

                                style={{
                                    color:
                                        activeTab === 'utility-request'
                                            ? ' #FFFFFF  '
                                            : ' #747487', textDecoration: 'none',
                                    backgroundColor:
                                        activeTab === "utility-request" ? "#0F6CBD " : "transparent",
                                }}>Petty cash request</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <div>
                        {activeTab === "material-request" && (
                            <div className=" bg-color-9 px-5 pt-3 mt-4">
                                <Row>
                                    <Col md={12}>
                                        {hasPermission(permissions, "create-request") && (

                                            <Button onClick={() => handleOpenCreateModal('material')} className='py-3 px-5 border-0 bg-color-1 float-end'>
                                                <span className='text-center'>Create request</span>
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                                <Table borderless responsive className='request-table'>
                                    <thead >
                                        <tr>
                                            <th className='text-color-1 bg-color-3'>S/N</th>
                                            <th className='text-color-1 bg-color-3'>Date </th>
                                            <th className='text-color-1 bg-color-3'>Material  </th>
                                            <th className='text-color-1 bg-color-3'>Total qty </th>
                                            <th className='text-color-1 bg-color-3'>Total amount </th>
                                            <th className='text-color-1 bg-color-3'>Vendor </th>
                                            <th className='text-color-1 bg-color-3'>Status</th>
                                            <th className='text-color-1 bg-color-3'>Action</th>

                                        </tr>
                                    </thead>                                    <tbody>
                                        {Array.isArray(materialRequests) && materialRequests.length > 0 ? (
                                            materialRequests.map((materialRequest, index) => {
                                                const { status } = materialRequest;
                                                const getStatusClass = (status: string) => {
                                                    switch (status) {
                                                        case 'pending':
                                                            return 'text-color-8'; // Color: #e08213
                                                        case 'reviewed':
                                                            return 'text-color-4'; // Color: #0f6cbd
                                                        case 'approved':
                                                            return 'text-color-10'; // Color: #187E2280
                                                        case 'rejected':
                                                            return 'text-color-9'; // Color: #E01313B2
                                                        default:
                                                            return '';
                                                    }
                                                };

                                                const capitalizeFirstLetter = (status: string) => {
                                                    return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
                                                };

                                                return (
                                                    <tr key={materialRequest.id}>
                                                        <td>{index + 1}</td>
                                                        <td>{format(new Date(materialRequest.created_on), "dd/MM/yy")}</td>
                                                        <td>{materialRequest.batch_materials}</td>
                                                        <td>{materialRequest.total_quantity}</td>
                                                        <td>{formatCurrency(parseFloat(materialRequest.amount))}</td>
                                                        <td>{materialRequest.vendor_name}</td>
                                                        <td className={`${getStatusClass(materialRequest.status)}  `}>
                                                            {capitalizeFirstLetter(materialRequest.status)}
                                                        </td>
                                                        <td style={{ padding: '0', verticalAlign: 'middle' }}>
                                                            <Dropdown show={materialRequestDropdownOpen[index]} onToggle={() => toggleMaterialRequestDropdown(index)}>
                                                                <Dropdown.Toggle
                                                                    className="dropdown-toggle"
                                                                    variant="transparent"
                                                                    style={{ background: 'none', border: 'none', boxShadow: 'none' }}>
                                                                    <img src={Action} alt="Action" className="action-icon mb-3" style={{ margin: '0', padding: '0' }} />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    {status === 'pending' && (
                                                                        <>
                                                                            {hasPermission(permissions, "edit-request") && (
                                                                                <Dropdown.Item onClick={() => handleOpenModal('editMaterial', materialRequest.id)}>Edit</Dropdown.Item>
                                                                            )}
                                                                            <Dropdown.Item onClick={() => handleOpenModal('viewMaterial', materialRequest.id)}>View</Dropdown.Item>
                                                                            {hasPermission(permissions, "review/reject-request") && (
                                                                                <Dropdown.Item onClick={() => handleOpenReviewMaterialRequestModal(materialRequest.id)}>Review</Dropdown.Item>
                                                                            )}
                                                                            {hasPermission(permissions, "approve/reject-request") && (
                                                                                <Dropdown.Item onClick={() => handleOpenApproveMaterialRequestModal(materialRequest.id)}>Approve</Dropdown.Item>
                                                                            )}
                                                                            {(hasPermission(permissions, "approve/reject-request") || hasPermission(permissions, "review/reject-request")) && (
                                                                                <Dropdown.Item onClick={() => handleOpenModal('rejectMaterial', materialRequest.id)}>Reject</Dropdown.Item>
                                                                            )}
                                                                        </>
                                                                    )}

                                                                    {status === 'reviewed' && (
                                                                        <>
                                                                            <Dropdown.Item onClick={() => handleOpenModal('viewMaterial', materialRequest.id)}>View</Dropdown.Item>
                                                                            {hasPermission(permissions, "approve/reject-request") && (
                                                                                <Dropdown.Item onClick={() => handleOpenApproveMaterialRequestModal(materialRequest.id)}>Approve</Dropdown.Item>
                                                                            )}
                                                                            {(hasPermission(permissions, "approve/reject-request") || hasPermission(permissions, "review/reject-request")) && (
                                                                                <Dropdown.Item onClick={() => handleOpenModal('rejectMaterial', materialRequest.id)}>Reject</Dropdown.Item>
                                                                            )}
                                                                        </>
                                                                    )}

                                                                    {status === 'approved' && (
                                                                        <>
                                                                            <Dropdown.Item onClick={() => handleOpenModal('viewMaterial', materialRequest.id)}>View</Dropdown.Item>
                                                                        </>
                                                                    )}
                                                                    {status === 'rejected' && (
                                                                        <>
                                                                            <Dropdown.Item onClick={() => handleOpenModal('viewMaterial', materialRequest.id)}>View</Dropdown.Item>
                                                                        </>
                                                                    )}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan={8} className="text-center">No material requests found.</td>
                                            </tr>
                                        )}
                                    </tbody>

                                </Table>
                                {materialRequestsMeta && materialRequestsMeta.total_pages > 1 && (
                                    <div className="d-flex justify-content-center mt-3 pb-5">
                                        <Pagination
                                            count={materialRequestsMeta && materialRequestsMeta?.total_pages}
                                            page={pageNumber}
                                            onChange={handleChange}
                                            shape="rounded"
                                            color="primary"
                                        />
                                    </div>
                                )}
                            </div>
                        )}

                    </div>
                    <div>
                        {activeTab === "labour-request" && (
                            <div className="table-container bg-color-9 px-5 pt-3 mt-4">
                                <Row>
                                    <Col md={12}>
                                        {hasPermission(permissions, "create-request") && (

                                            <Button onClick={() => handleOpenCreateModal('labour')} className='py-3 px-5 border-0 bg-color-1 float-end'>
                                                <span className='text-center'>Create request</span>
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                                <Table borderless responsive className='request-table'>
                                    <thead >
                                        <tr>
                                            <th className='text-color-1 bg-color-3'>S/N</th>
                                            <th className='text-color-1 bg-color-3'>Date </th>
                                            <th className='text-color-1 bg-color-3'>Labour type </th>
                                            <th className='text-color-1 bg-color-3'>Stage </th>
                                            <th className='text-color-1 bg-color-3'>Amount </th>
                                            <th className='text-color-1 bg-color-3'>Status</th>
                                            <th className='text-color-1 bg-color-3'>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(labourRequests) && labourRequests.length > 0 ? (
                                            labourRequests.map((labourRequest, index) => {
                                                const { status } = labourRequest;
                                                const getStatusClass = (status: string) => {
                                                    switch (status) {
                                                        case 'pending':
                                                            return 'text-color-8'; // Color: #e08213
                                                        case 'reviewed':
                                                            return 'text-color-4'; // Color: #0f6cbd
                                                        case 'approved':
                                                            return 'text-color-10'; // Color: #187E2280
                                                        case 'rejected':
                                                            return 'text-color-9'; // Color: #E01313B2
                                                        default:
                                                            return '';
                                                    }
                                                };

                                                const capitalizeFirstLetter = (status: string) => {
                                                    return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
                                                };
                                                return (
                                                    <tr key={labourRequest.id}>
                                                        <td>{index + 1}</td>
                                                        <td> {format(new Date(labourRequest.created_on), "dd/MM/yy")} </td>
                                                        <td>{labourRequest.labour_type} </td>
                                                        <td>{labourRequest.stage}</td>
                                                        <td>{formatCurrency(parseFloat(labourRequest.amount))}</td>
                                                        <td className={`${getStatusClass(labourRequest.status)}  `}>
                                                            {capitalizeFirstLetter(labourRequest.status)}
                                                        </td>
                                                        <td style={{ padding: '0', verticalAlign: 'middle' }}>
                                                            <Dropdown show={labourRequestDropdownOpen[index]} onToggle={() => toggleLabourRequestDropdown(index)}>
                                                                <Dropdown.Toggle className="dropdown-toggle" variant="transparent" style={{ background: 'none', border: 'none', boxShadow: 'none' }}>
                                                                    <img src={Action} alt="Action" className="action-icon mb-3" style={{ margin: '0', padding: '0' }} />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    {status === 'pending' && (
                                                                        <>
                                                                            {hasPermission(permissions, "edit-request") && (
                                                                                <Dropdown.Item onClick={() => handleOpenModal('editLabour', labourRequest.id)}>Edit</Dropdown.Item>
                                                                            )}
                                                                            <Dropdown.Item onClick={() => handleOpenModal('viewLabour', labourRequest.id)}>View</Dropdown.Item>
                                                                            {hasPermission(permissions, "review/reject-request") && (
                                                                                <Dropdown.Item onClick={() => handleOpenReviewLabourRequestModal(labourRequest.id)}>Review</Dropdown.Item>
                                                                            )}
                                                                            {hasPermission(permissions, "approve/reject-request") && (
                                                                                <Dropdown.Item onClick={() => handleOpenApproveLabourRequestModal(labourRequest.id)}>Approve</Dropdown.Item>
                                                                            )}
                                                                            {(hasPermission(permissions, "approve/reject-request") || hasPermission(permissions, "review/reject-request")) && (
                                                                                <Dropdown.Item onClick={() => handleOpenModal('rejectLabour', labourRequest.id)}>Reject</Dropdown.Item>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                    {status === 'reviewed' && (
                                                                        <>
                                                                            <Dropdown.Item onClick={() => handleOpenModal('viewLabour', labourRequest.id)}>View</Dropdown.Item>
                                                                            {hasPermission(permissions, "approve/reject-request") && (
                                                                                <Dropdown.Item onClick={() => handleOpenApproveLabourRequestModal(labourRequest.id)}>Approve</Dropdown.Item>
                                                                            )}
                                                                            {(hasPermission(permissions, "approve/reject-request") || hasPermission(permissions, "review/reject-request")) && (
                                                                                <Dropdown.Item onClick={() => handleOpenModal('rejectLabour', labourRequest.id)}>Reject</Dropdown.Item>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                    {status === 'approved' && (
                                                                        <>
                                                                            <Dropdown.Item onClick={() => handleOpenModal('viewLabour', labourRequest.id)}>View</Dropdown.Item>
                                                                        </>
                                                                    )}
                                                                    {status === 'rejected' && (
                                                                        <>
                                                                            <Dropdown.Item onClick={() => handleOpenModal('viewLabour', labourRequest.id)}>View</Dropdown.Item>
                                                                        </>
                                                                    )}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan={8} className="text-center">No labour requests found.</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                                {labourRequestsMeta && labourRequestsMeta.total_pages > 1 && (
                                    <div className="d-flex justify-content-center mt-3 pb-5">
                                        <Pagination
                                            count={labourRequestsMeta && labourRequestsMeta?.total_pages}
                                            page={pageNumber}
                                            onChange={handleChange}
                                            shape="rounded"
                                            color="primary"
                                        />
                                    </div>
                                )}
                            </div>
                        )}

                    </div>
                    <div>
                        {activeTab === "utility-request" && (
                            <div className="table-container bg-color-9 px-5 pt-3 mt-4">
                                <Row>
                                    <Col md={12}>
                                        {hasPermission(permissions, "create-request") && (

                                            <Button onClick={() => handleOpenCreateModal('petty')} className='py-3 px-5 border-0 bg-color-1 float-end'>
                                                <span className='text-center'>Create request</span>
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                                <Table borderless responsive className='request-table'>
                                    <thead >
                                        <tr>
                                            <th className='text-color-1 bg-color-3'>S/N</th>
                                            <th className='text-color-1 bg-color-3'>Date </th>
                                            <th className='text-color-1 bg-color-3'> Title </th>
                                            <th className='text-color-1 bg-color-3'>Receiver name </th>
                                            <th className='text-color-1 bg-color-3'>Amount </th>
                                            <th className='text-color-1 bg-color-3'> Status</th>
                                            <th className='text-color-1 bg-color-3'>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(pettyRequests) && pettyRequests.length > 0 ? (
                                            pettyRequests.map((pettyRequest, index) => {
                                                const { status } = pettyRequest;
                                                const getStatusClass = (status: string) => {
                                                    switch (status) {
                                                        case 'pending':
                                                            return 'text-color-8'; // Color: #e08213
                                                        case 'reviewed':
                                                            return 'text-color-4'; // Color: #0f6cbd
                                                        case 'approved':
                                                            return 'text-color-10'; // Color: #187E2280
                                                        case 'rejected':
                                                            return 'text-color-9'; // Color: #E01313B2
                                                        default:
                                                            return '';
                                                    }
                                                };

                                                const capitalizeFirstLetter = (status: string) => {
                                                    return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
                                                };
                                                return (
                                                    <tr key={pettyRequest.id}>
                                                        <td>{index + 1}</td>
                                                        <td> {format(new Date(pettyRequest.created_on), "dd/MM/yy")} </td>
                                                        <td>{pettyRequest.title}</td>
                                                        <td>{pettyRequest.receiver_name}</td>
                                                        <td>{formatCurrency(parseFloat(pettyRequest.amount))}</td>
                                                        <td className={`${getStatusClass(pettyRequest.status)}  `}>
                                                            {capitalizeFirstLetter(pettyRequest.status)}
                                                        </td>
                                                        <td style={{ padding: '0', verticalAlign: 'middle' }}>
                                                            <Dropdown show={utilityRequestDropdownOpen[index]} onToggle={() => toggleUtilityRequestDropdown(index)}>
                                                                <Dropdown.Toggle className="dropdown-toggle" variant="transparent" style={{ background: 'none', border: 'none', boxShadow: 'none' }}>
                                                                    <img src={Action} alt="Action" className="action-icon mb-3" style={{ margin: '0', padding: '0' }} />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    {status === 'pending' && (
                                                                        <>
                                                                            {hasPermission(permissions, "edit-request") && (
                                                                                <Dropdown.Item onClick={() => handleOpenModal('editPetty', pettyRequest.id)}>Edit</Dropdown.Item>
                                                                            )}
                                                                            <Dropdown.Item onClick={() => handleOpenModal('viewPetty', pettyRequest.id)}>View</Dropdown.Item>
                                                                            {hasPermission(permissions, "review/reject-request") && (
                                                                                <Dropdown.Item onClick={() => handleOpenReviewPettyRequestModal(pettyRequest.id)}>Review</Dropdown.Item>
                                                                            )}
                                                                            {hasPermission(permissions, "approve/reject-request") && (
                                                                                <Dropdown.Item onClick={() => handleOpenApprovePettyRequestModal(pettyRequest.id)}>Approve</Dropdown.Item>
                                                                            )}
                                                                            {(hasPermission(permissions, "approve/reject-request") || hasPermission(permissions, "review/reject-request")) && (
                                                                                <Dropdown.Item onClick={() => handleOpenModal('rejectPetty', pettyRequest.id)}>Reject</Dropdown.Item>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                    {status === 'reviewed' && (
                                                                        <>
                                                                            <Dropdown.Item onClick={() => handleOpenModal('viewPetty', pettyRequest.id)}>View</Dropdown.Item>
                                                                            {hasPermission(permissions, "approve/reject-request") && (
                                                                                <Dropdown.Item onClick={() => handleOpenApprovePettyRequestModal(pettyRequest.id)}>Approve</Dropdown.Item>
                                                                            )}
                                                                            {(hasPermission(permissions, "approve/reject-request") || hasPermission(permissions, "review/reject-request")) && (
                                                                                <Dropdown.Item onClick={() => handleOpenModal('rejectPetty', pettyRequest.id)}>Reject</Dropdown.Item>
                                                                            )}
                                                                        </>
                                                                    )}

                                                                    {status === 'approved' && (
                                                                        <>
                                                                            <Dropdown.Item onClick={() => handleOpenModal('viewPetty', pettyRequest.id)}>View</Dropdown.Item>
                                                                        </>
                                                                    )}
                                                                    {status === 'rejected' && (
                                                                        <>
                                                                            <Dropdown.Item onClick={() => handleOpenModal('viewPetty', pettyRequest.id)}>View</Dropdown.Item>
                                                                        </>
                                                                    )}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan={8} className="text-center">No petty cash requests found.</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                                {pettyRequestsMeta && pettyRequestsMeta.total_pages > 1 && (
                                    <div className="d-flex justify-content-center mt-3 pb-5">
                                        <Pagination
                                            count={pettyRequestsMeta && pettyRequestsMeta?.total_pages}
                                            page={pageNumber}
                                            onChange={handleChange}
                                            shape="rounded"
                                            color="primary"
                                        />
                                    </div>
                                )}
                            </div>
                        )}

                    </div>
                </div>
                {openModal.editMaterial && <EditMaterialRequest show={openModal.editMaterial !== null} materialRequestId={openModal.editMaterial} closeModal={() => handleCloseModal('editMaterial')} onEditSuccess={handleEditSuccess} projectId={projectId!} />}
                {openModal.viewMaterial && <ViewMaterialRequest show={openModal.viewMaterial !== null} materialRequestId={openModal.viewMaterial} closeModal={() => handleCloseModal('viewMaterial')} projectId={projectId!} />}
                <ReviewMaterialRequest show={reviewMaterialRequestModalOpen} materialRequestId={selectedMaterialRequestId!} closeModal={handleCloseReviewMaterialRequestModal} projectId={projectId!} />
                <ApproveMaterialRequest show={approveMaterialRequestModalOpen} materialRequestId={selectedMaterialRequestId!} closeModal={handleCloseApproveMaterialRequestModal} projectId={projectId!} />
                {openModal.rejectMaterial && <RejectMaterialRequest show={openModal.rejectMaterial !== null} materialRequestId={openModal.rejectMaterial} closeModal={() => handleCloseModal('rejectMaterial')} projectId={projectId!} />}
                {openModal.editLabour && <EditLabourRequest show={openModal.editLabour !== null} labourRequestId={openModal.editLabour} closeModal={() => handleCloseModal('editLabour')} onEditSuccess={handleEditSuccess} projectId={projectId!} />}
                {openModal.viewLabour && <ViewLabourRequest show={openModal.viewLabour !== null} labourRequestId={openModal.viewLabour} closeModal={() => handleCloseModal('viewLabour')} projectId={projectId!} />}
                <ReviewLabourRequest show={reviewLabourRequestModalOpen} labourRequestId={selectedLabourRequestId!} closeModal={handleCloseReviewLabourRequestModal} projectId={projectId!} />
                <ApproveLabourRequest show={approveLabourRequestModalOpen} labourRequestId={selectedLabourRequestId!} closeModal={handleCloseApproveLabourRequestModal} projectId={projectId!} />
                {openModal.rejectLabour && <RejectLabourRequest show={openModal.rejectLabour !== null} labourRequestId={openModal.rejectLabour} closeModal={() => handleCloseModal('rejectLabour')} projectId={projectId!} />}
                {openModal.editPetty && <EditUtilityRequest show={openModal.editPetty !== null} pettyRequestId={openModal.editPetty} closeModal={() => handleCloseModal('editPetty')} onEditSuccess={handleEditSuccess} projectId={projectId!} />}
                {openModal.viewPetty && <ViewUtilityRequest show={openModal.viewPetty !== null} pettyRequestId={openModal.viewPetty} closeModal={() => handleCloseModal('viewPetty')} projectId={projectId!} />}
                <ReviewUtilityRequest show={reviewPettyRequestModalOpen} pettyRequestId={selectedPettyRequestId!} closeModal={handleCloseReviewPettyRequestModal} projectId={projectId!} />
                <ApproveUtilityRequest show={approvePettyRequestModalOpen} pettyRequestId={selectedPettyRequestId!} closeModal={handleCloseApprovePettyRequestModal} projectId={projectId!} />
                {openModal.rejectPetty && <RejectUtilityRequest show={openModal.rejectPetty !== null} pettyRequestId={openModal.rejectPetty} closeModal={() => handleCloseModal('rejectPetty')} projectId={projectId!} />}
                {openModal.createMaterial === 'material' && <CreateMaterialRequest show={openModal.createMaterial !== null} closeModal={handleCloseCreateModal} onCreateSuccess={handleCreateSuccess} projectId={projectId} />}
                {openModal.createLabour === 'labour' && <CreateLabourRequest show={openModal.createLabour !== null} closeModal={handleCloseCreateModal} onCreateSuccess={handleCreateSuccess} projectId={projectId} />}
                {openModal.createPetty === 'petty' && <CreateUtilityRequest show={openModal.createPetty !== null} closeModal={handleCloseCreateModal} onCreateSuccess={handleCreateSuccess} projectId={projectId} />}

            </Layout>
        </div>)
}

export default Procurement;
