import React, { useState, useEffect } from "react";
import SettingsLayout from "../settings-layout";
import { Table, Button, Row, Col, Dropdown } from "react-bootstrap";
import CreateVendor from "./modals/create-vendor";
import EditVendor from "./modals/edit-vendor";
import ViewVendor from "./modals/view-vendor";
import Action from "../../../assets/images/action-vector.png";
import searchIcon from "../../../assets/images/search-icon.svg";
import {
	getAllVendorsAsync,
	getAllBanksAsync,
	searchVendorAsync,
} from "../../../slices/settings/settingsSlice";
import { getUserAsync } from "../../../slices/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import CloseButton from "../../../components/close-button/close-button";
import Pagination from "@mui/material/Pagination";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

function Vendors() {
	const dispatch = useAppDispatch();
	const vendors = useAppSelector((state) => state.settings.vendors?.data);
	const meta = useAppSelector((state) => state.settings.vendors?.meta);
	const companyId = useAppSelector(
		(state: RootState) => state.auth.user?.company_id || "",
	);

	const [selectedVendorId, setSelectedVendorId] = useState<string | null>(null);
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
	const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
	const [viewModalOpen, setViewModalOpen] = useState<boolean>(false);
	const [dropdownOpen, setDropdownOpen] = useState<{ [key: number]: boolean }>(
		{},
	);
	const [refreshData, setRefreshData] = useState(false);
	const [pageNumber, setPage] = useState(1);

	// Fetch user data on mount
	useEffect(() => {
		dispatch(getUserAsync());
	}, [dispatch]);

	const handleOpenCreateModal = () => setCreateModalOpen(true);
	const handleCloseCreateModal = () => setCreateModalOpen(false);

	const handleOpenEditModal = (vendorId: string) => {
		setSelectedVendorId(vendorId);
		setEditModalOpen(true);
	};
	const handleCloseEditModal = () => {
		setEditModalOpen(false);
		setSelectedVendorId(null);
	};

	const handleOpenViewModal = (vendorId: string) => {
		setSelectedVendorId(vendorId);
		setViewModalOpen(true);
	};
	const handleCloseViewModal = () => {
		setViewModalOpen(false);
		setSelectedVendorId(null);
	};

	const toggleDropdown = (index: number) => {
		setDropdownOpen((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};

	const handleChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
		if (setPage) {
			setPage(newPage);
			dispatch(
				searchVendorAsync({
					company_id: companyId,
					searchTerm: searchQuery,
				}),
			);
		}
	};

	useEffect(() => {
		if (companyId && !searchQuery) {
			dispatch(getAllVendorsAsync({ companyId, page: pageNumber }));
		}
	}, [companyId, dispatch, pageNumber, searchQuery]);

	useEffect(() => {
		if (companyId) {
			dispatch(getAllBanksAsync());
		}
	}, [companyId, pageNumber, dispatch]);

	useEffect(() => {
		if (refreshData) {
			dispatch(getAllVendorsAsync({ companyId, page: pageNumber }));
			setRefreshData(false);
		}
	}, [refreshData, companyId, pageNumber, dispatch]);

	const handleCreateSuccess = () => setRefreshData(true);
	const handleEditSuccess = () => setRefreshData(true);

	return (
		<div className="bg-color-8">
			<SettingsLayout>
				<div className="pt-5 p-5">
					<div className="w-100 mb-5 d-inline-flex justify-content-between align-items-center">
						<h4 className="text-color-2">Vendors</h4>
						<CloseButton />
					</div>
					<div className="bg-color-9 px-5 pt-3 mt-4">
						<Row className="w-100 justify-content-end align-items-center mb-3">
							<Col md={6} sm={6} xs={12} className="mt-2">
								<Formik
									initialValues={{ searchQuery: "" }}
									validationSchema={Yup.object({
										searchQuery: Yup.string()
											.min(2, "Search term is too short")
											.required("Please enter a search term"),
									})}
									onSubmit={async (values, { setSubmitting, setErrors }) => {
										try {
											setSubmitting(true);
											toast.loading("Searching...");
											setSearchQuery(values.searchQuery);
											await dispatch(
												searchVendorAsync({
													company_id: companyId,
													searchTerm: values.searchQuery,
												}),
											);
											toast.dismiss();
											toast.success("Search successful");
										} catch (error) {
											toast.dismiss();
											toast.error("An error occurred while searching");
											setErrors({
												searchQuery: "An error occurred. Please try again.",
											});
										} finally {
											setSubmitting(false);
										}
									}}
								>
									{({ isSubmitting, handleSubmit, errors, touched }) => (
										<Form onSubmit={handleSubmit} className="mt-4">
											<div className="mx-2 border border-1 rounded py-1 px-2 d-inline-flex justify-content-between align-items-center my-3">
												<Field
													type="search"
													name="searchQuery"
													placeholder="Search for vendor"
													className="bg-transparent border-0"
													style={{ outline: "0" }}
												/>
												<button
													type="submit"
													className={`pointer ${isSubmitting ? "disabled" : ""
														}`}
													disabled={isSubmitting}
													style={{
														background: "none",
														border: "none",
														boxShadow: "none",
													}}
												>
													<img src={searchIcon} alt="search icon" />
												</button>
											</div>
											{errors.searchQuery && touched.searchQuery ? (
												<div className="text-danger">{errors.searchQuery}</div>
											) : (
												<div className="text-transparent">
													errors.searchQuery
												</div>
											)}
										</Form>
									)}
								</Formik>
							</Col>
							<Col md={6} sm={6} xs={12} className="mt-2">
								<Button
									onClick={handleOpenCreateModal}
									className="py-3 px-5 border-0 bg-color-1 float-end"
								>
									<span className="text-center">Add vendor</span>
								</Button>
							</Col>
						</Row>
						<Table borderless responsive className="pb-5 mb-5">
							<thead>
								<tr>
									<th className="text-color-1 bg-color-3">S/N</th>
									<th className="text-color-1 bg-color-3">Vendor </th>
									<th className="text-color-1 bg-color-3">Email</th>
									<th className="text-color-1 bg-color-3"> Phone number</th>
									<th className="text-color-1 bg-color-3">Action</th>
								</tr>
							</thead>
							<tbody>
								{Array.isArray(vendors) && vendors.length > 0 ? (
									vendors.map((vendor, index) => (
										<tr key={vendor.id}>
											<td>{index + 1}</td>
											<td>{vendor.vendor_name} </td>
											<td>{vendor.vendor_email}</td>
											<td>{vendor.vendor_phone_number}</td>

											<td style={{ padding: "0", verticalAlign: "middle" }}>
												<Dropdown
													show={dropdownOpen[index]}
													onToggle={() => toggleDropdown(index)}
												>
													<Dropdown.Toggle
														className="dropdown-toggle"
														variant="transparent"
														style={{
															background: "none",
															border: "none",
															boxShadow: "none",
														}}
													>
														<img
															src={Action}
															alt="Action"
															className="action-icon mb-3"
															style={{ margin: "0", padding: "0" }}
														/>
													</Dropdown.Toggle>
													<Dropdown.Menu>
														<Dropdown.Item
															onClick={() => handleOpenEditModal(vendor.id)}
														>
															Edit
														</Dropdown.Item>
														<Dropdown.Item
															onClick={() => handleOpenViewModal(vendor.id)}
														>
															View
														</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											</td>
										</tr>
									))
								) : (
									<tr>
										<td colSpan={6} className="text-center">
											No vendors found.
										</td>
									</tr>
								)}
							</tbody>
						</Table>
						{meta && meta.total_pages > 1 && (
							<div className="d-flex justify-content-center mt-3 pb-5">
								<Pagination
									count={meta && meta?.total_pages}
									page={pageNumber}
									onChange={handleChange}
									shape="rounded"
									color="primary"
								/>
							</div>
						)}
					</div>
				</div>
				<CreateVendor
					show={createModalOpen}
					closeModal={handleCloseCreateModal}
					onCreateSuccess={handleCreateSuccess}
				/>
				{selectedVendorId && (
					<>
						<EditVendor
							show={editModalOpen}
							closeModal={handleCloseEditModal}
							vendorId={selectedVendorId}
							onEditSuccess={handleEditSuccess}
						/>
						<ViewVendor
							show={viewModalOpen}
							closeModal={handleCloseViewModal}
							vendorId={selectedVendorId}
						/>
					</>
				)}
			</SettingsLayout>
		</div>
	);
}

export default Vendors;
