import React, { useEffect, useState } from 'react';
import ResetIcon from '../../../assets/images/reset-password-icon.png';
import { Container, Button, Image, Card } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { forgotPasswordAsync, setEmail } from '../../../slices/auth/authSlice';
import { toast } from "react-toastify";

const PasswordVerification: React.FC = () => {
    const [countdown, setCountdown] = useState(120);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const dispatch = useAppDispatch();
    const email = useAppSelector((state: any) => state.auth.email) || localStorage.getItem('resetEmail');

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prev) => {
                if (prev > 1) {
                    return prev - 1;
                } else {
                    setIsButtonDisabled(false);
                    clearInterval(timer);
                    return 0;
                }
            });
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (!email) {
            const savedEmail = localStorage.getItem('resetEmail');
            console.log('Saved email from local storage:', savedEmail);
            if (savedEmail) {
                dispatch(setEmail(savedEmail));
            }
        }
    }, [dispatch, email]);

    const handleResendLink = async () => {
        if (email) {
            console.log("Email in handleResendLink:", email);
            try {
                await dispatch(forgotPasswordAsync({ email })).unwrap();
                toast.success("Password reset link resent successfully");
            } catch (error: any) {
                toast.error(error.message || "Failed to resend password reset link");
            }
            setCountdown(120);
            setIsButtonDisabled(true);
        } else {
            console.error("Email is required to resend verification link.");
        }
    };

    return (
        <Container fluid>
            <div className='bg-color-2'>
                <Card className='auth-card-3 p-5'>
                    <div className='text-center'>
                        <Image src={ResetIcon} className='mt-5' />
                        <h1 className='my-3'>Reset password</h1>
                        <p>An email reset password link has been sent to
                            <br /> your registered email address</p>
                        <div className='d-grid'>
                            <Button type='button'
                                className='bg-color-1 my-3 py-3 border-0'
                                disabled={isButtonDisabled}
                                onClick={handleResendLink}
                            >
                                Resend link
                            </Button>
                        </div>
                        <p>{String(Math.floor(countdown / 60)).padStart(2, '0')}:{String(countdown % 60).padStart(2, '0')}</p>
                    </div>
                </Card>
            </div>
        </Container>
    );
};

export default PasswordVerification;
