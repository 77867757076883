export const hasPermission = (permissions: string[] | null, requiredPermission: string): boolean => {
    if (permissions === null) {
        return true;
    }

    if (!permissions || permissions.length === 0) {
        return false; // If permissions are empty or undefined, return false
    }


    return permissions.includes(requiredPermission);
};