import React from 'react'
import { ReactNode } from 'react';
import SettingsSideBar from './settings-sidebar'

interface Props {
    children: ReactNode;
}


const SettingsLayout: React.FC<Props> = ({ children }) => {
    return (
        <div className='settings-layout'>
            <SettingsSideBar />
            <main className='content'>
                <div>{children}</div>
            </main>
        </div>
    )
}

export default SettingsLayout