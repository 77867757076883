import React from 'react';

interface CurrencyInputProps {
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
    className: string;
    placeholder: string;
}
export const currencyMask = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d)(\d{2})$/, '$1.$2');
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    e.target.value = value;
    return e;
};

const CurrencyInput: React.FC<CurrencyInputProps> = ({ name, value, onChange, onBlur, className, placeholder }) => {
    return (
        <input
            type="text"
            name={name}
            value={value}
            onChange={(e) => onChange(currencyMask(e))}
            onBlur={onBlur}
            className={className}
            placeholder={placeholder}
        />
    );
};

export default CurrencyInput;
