import React from "react";
import { Link } from "react-router-dom";

function FirstTimeLogin() {
	return (
		<div className="vh-100 d-flex flex-column justify-content-center align-items-center">
			<h1 className="text-center mb-4">Watch this demo video to get started</h1>
			<div
				style={{ height: "400px" }}
				className="w-75 border d-flex flex-column justify-content-center align-items-center mb-4"
			>
				<h1>Video</h1>
			</div>
			<div className="w-75 text-end">
				<Link to="/create-first-project" className="text-reset">
					{" "}
					Skip video{" "}
				</Link>
			</div>
		</div>
	);
}

export default FirstTimeLogin;
