import React, { useState, useEffect } from 'react';
import Layout from '../../../components/layouts/layout';
import CreateMaterialBudget from './modals/create-material-budget';
import CreateLabourBudget from './modals/create-labour-budget';
import CreatePettyBudget from './modals/create-petty-budget';
import { Table, Dropdown, Row, Col, Card } from 'react-bootstrap';
import Action from "../../../assets/images/action-vector.png";
import EditBudget from './modals/edit-budget';
import { getAllBudgetsAsync } from '../../../slices/budget/budgetSlice';
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import NoBudget from './no-budget';
import { hasPermission } from "../../../util/permissionchecker";
import { extractPermissionNames } from '../../../util/transformPermissions';

function Budget() {
    const formatCurrency = (amount: number): string => {
        return `₦${amount.toLocaleString('en-US')}`;
    };
    const { projectId } = useParams<{ projectId: string | undefined }>();
    const userData = useAppSelector((state: any) => state.user?.userData?.user);
    const isAdmin = userData?.system_role === 'admin';
    const permissions = isAdmin ? null : extractPermissionNames(userData?.permissions?.permissions || []);

    console.log('User permissions:', permissions);
    const dispatch = useAppDispatch();
    const budgetsData = useAppSelector((state) => state.budget.budgets);
    const companyId = useAppSelector((state: RootState) => state.auth.user?.company_id || "");

    const [budgets, setBudgets] = useState<any[]>([]);
    const [refreshData, setRefreshData] = useState(false);
    const [editBudgetModalOpen, setEditBudgetModalOpen] = useState<boolean>(false);
    const [selectedBudgetType, setSelectedBudgetType] = useState<string | null>(null);
    const [createBudgetModalOpen, setCreateBudgetModalOpen] = useState<boolean>(false);
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [createdBudgetTypes, setCreatedBudgetTypes] = useState<Set<string>>(new Set());

    const handleOpenEditBudgetModal = () => setEditBudgetModalOpen(true);
    const handleCloseEditBudgetModal = () => setEditBudgetModalOpen(false);

    const handleOpenCreateBudgetModal = (budgetType: string) => {
        setSelectedBudgetType(budgetType);
        setCreateBudgetModalOpen(true);
    };

    const handleCloseCreateBudgetModal = () => {
        setSelectedBudgetType(null);
        setCreateBudgetModalOpen(false);
    };

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    useEffect(() => {
        const fetchBudgets = async () => {
            if (companyId && projectId) {
                await dispatch(getAllBudgetsAsync({ companyId: companyId, projectId: projectId }));
            }
        };

        fetchBudgets();
    }, [companyId, dispatch, projectId]);

    useEffect(() => {
        if (Array.isArray(budgetsData)) {
            setBudgets(budgetsData);
            const createdTypes = new Set(budgetsData.map(budget => budget.budget_type.toLowerCase()));
            setCreatedBudgetTypes(createdTypes);
        } else {
            setBudgets([]);
            setCreatedBudgetTypes(new Set()); // Reset created types if budgetsData is not valid
        }
    }, [budgetsData]);


    const handleCreateSuccess = (budgetType: string) => {
        setCreatedBudgetTypes(prev => new Set(prev.add(budgetType.toLowerCase())));
        setRefreshData(true);
    };

    const availableBudgetTypes = ['material', 'labour', 'petty-cash'].filter(type => !createdBudgetTypes.has(type));
    const isAddButtonDisabled = createdBudgetTypes.size === 3;

    console.log('Created Budget Types:', Array.from(createdBudgetTypes));
    console.log('Available Budget Types:', availableBudgetTypes);

    const totalBudget = budgets.reduce((total, budget) => {
        const amount = parseFloat(budget.budget_amount) || 0;
        return total + amount;
    }, 0);
    const totalActualSpent = budgets.reduce((total, budget) => {
        const amount = parseFloat(budget.amount_spent) || 0;
        return total + amount;
    }, 0);
    const totalDifference = totalBudget - totalActualSpent;

    return (
        <div className="bg-color-8">
            <Layout>
                <div className="mt-5 p-5">
                    <h3>Budget</h3>
                    {budgets.length > 0 ? (
                        <>
                            <Row className='mt-5'>
                                <Col md={4} sm={4} xs={12} className='mt-2'>
                                    <Card className='bg-color-5 text-color-4 border-0 p-2'>
                                        <p>Total budget</p>
                                        <h3>{formatCurrency(totalBudget)}</h3>
                                    </Card>
                                </Col>
                                <Col md={4} sm={4} xs={12} className='mt-2'>
                                    <Card className='bg-color-6 text-color-5 border-0 p-2'>
                                        <p>Actual spent</p>
                                        <h3>{formatCurrency(totalActualSpent)}</h3>
                                    </Card>
                                </Col>
                                <Col md={4} sm={4} xs={12} className='mt-2'>
                                    <Card className='bg-color-7 text-color-6 border-0 p-2'>
                                        <p>Total difference</p>
                                        <h3>{formatCurrency(totalDifference)}</h3>
                                    </Card>
                                </Col>
                            </Row>
                            <div className="table-container bg-color-9 px-5 pt-3 mt-5">
                                <Row>
                                    {hasPermission(permissions, "create-budget") && (
                                        <Dropdown>
                                            <Dropdown.Toggle variant="primary" id="dropdown-basic" className=" px-5 border-0 bg-color-1 budget-btn float-end" disabled={isAddButtonDisabled}>
                                                Add budget
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {availableBudgetTypes.map(type => (
                                                    <Dropdown.Item key={type} onClick={() => handleOpenCreateBudgetModal(type)}>
                                                        {type.charAt(0).toUpperCase() + type.slice(1)}
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    )}
                                </Row>

                                <Table borderless responsive className='mt-3'>
                                    <thead>
                                        <tr>
                                            <th className='text-color-1 bg-color-3'>Budget name </th>
                                            <th className='text-color-1 bg-color-3'>Estimated total budget </th>
                                            <th className='text-color-1 bg-color-3'>Actual spent </th>
                                            <th className='text-color-1 bg-color-3'>Total difference </th>
                                            <th className='text-color-1 bg-color-3'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {budgets.map((budget) => {
                                            const totalBudget = parseFloat(budget.budget_amount) || 0;
                                            const amountSpent = parseFloat(budget.amount_spent) || 0;
                                            const totalDifference = totalBudget - amountSpent;

                                            return (
                                                <tr key={budget.id}>
                                                    <td>{budget.budget_type}</td>
                                                    <td>{formatCurrency(totalBudget)}</td>
                                                    <td>{formatCurrency(amountSpent)}</td>
                                                    <td>{formatCurrency(totalDifference)}</td>
                                                    <td style={{ padding: '0', verticalAlign: 'middle' }}>
                                                        <Dropdown show={dropdownOpen} onToggle={toggleDropdown}>
                                                            <Dropdown.Toggle className="dropdown-toggle" variant="transparent" style={{ background: 'none', border: 'none', boxShadow: 'none' }}>
                                                                <img src={Action} alt="Action" className="action-icon mb-3" style={{ margin: '0', padding: '0' }} />
                                                            </Dropdown.Toggle>
                                                            {/* <Dropdown.Menu>
                                                        <Dropdown.Item onClick={handleOpenEditBudgetModal}>Edit</Dropdown.Item>
                                                    </Dropdown.Menu> */}
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </>
                    ) : (
                        <NoBudget />
                    )}
                </div>
                {selectedBudgetType === 'material' && (
                    <CreateMaterialBudget show={createBudgetModalOpen} closeModal={handleCloseCreateBudgetModal} onCreateSuccess={() => handleCreateSuccess('material')} projectId={projectId!} />
                )}
                {selectedBudgetType === 'labour' && (
                    <CreateLabourBudget show={createBudgetModalOpen} closeModal={handleCloseCreateBudgetModal} onCreateSuccess={() => handleCreateSuccess('labour')} projectId={projectId!} />
                )}
                {selectedBudgetType === 'petty-cash' && (
                    <CreatePettyBudget show={createBudgetModalOpen} closeModal={handleCloseCreateBudgetModal} onCreateSuccess={() => handleCreateSuccess('petty-cash')} projectId={projectId!} />
                )}
                <EditBudget show={editBudgetModalOpen} closeModal={handleCloseEditBudgetModal} />
            </Layout>
        </div>
    );
}

export default Budget;
