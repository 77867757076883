import React, { useState, useEffect } from 'react';
import { Container, Button, Image, Card } from "react-bootstrap";
import EmailIcon from "../../../assets/images/email-icon.png";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { checkUserVerificationAsync, resendAsync, setEmail } from '../../../slices/auth/authSlice';

const UserVerification: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [countdown, setCountdown] = useState(120);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const isVerified = useAppSelector((state: any) => state.auth.isVerified);
    const email = useAppSelector((state: any) => state.auth.email);

    useEffect(() => {
        const checkVerificationStatus = async () => {
            await dispatch(checkUserVerificationAsync());
        };

        checkVerificationStatus();
    }, [dispatch]);

    useEffect(() => {
        console.log('isVerified:', isVerified);
        if (isVerified) {
            navigate('/verification-successful');
        }
    }, [isVerified, navigate]);

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prev) => {
                if (prev > 1) {
                    return prev - 1;
                } else {
                    setIsButtonDisabled(false);
                    clearInterval(timer);
                    return 0;
                }
            });
        }, 1000);
        return () => clearInterval(timer);
    }, []);


    useEffect(() => {
        if (!email) {
            const savedEmail = localStorage.getItem('userEmail');
            console.log('Saved email from local storage:', savedEmail);
            if (savedEmail) {
                dispatch(setEmail(savedEmail));
            }
        }
    }, [dispatch, email]);

    const handleResendLink = () => {
        if (email) {
            console.log('Dispatching resendAsync with email:', email);
            dispatch(resendAsync({ email }))
                .unwrap()
                .then((response) => {
                    console.log('Resend response:', response);
                })
                .catch((error) => {
                    console.error('Resend error:', error);
                });
            setCountdown(120);
            setIsButtonDisabled(true);
        } else {
            console.error('Email is required to resend verification link.');
        }
    };

    return (
        <Container fluid>
            <div className='bg-color-2'>
                <Card className='auth-card-3'>
                    <div className='text-center p-5'>
                        <Image src={EmailIcon} className='mt-5' />
                        <h1 className='my-3'>Thank you for registering</h1>
                        <p>An email verification link has been sent to your <br /> registered email address</p>
                        <div className='d-grid'>
                            <Button type='button'
                                onClick={handleResendLink}
                                className='bg-color-1 my-3 py-3 border-0'
                                disabled={isButtonDisabled}
                            >
                                Resend link
                            </Button>
                        </div>
                        <p>{String(Math.floor(countdown / 60)).padStart(2, '0')}:{String(countdown % 60).padStart(2, '0')}</p>
                    </div>
                </Card>
            </div>
        </Container>
    );
};

export default UserVerification;
