import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../slices/auth/authSlice";
import projectReducer from "../slices/project/projectSlice";
import projectManagementReducer from '../slices/project-management/project-management-slice'
import settingsReducer from "../slices/settings/settingsSlice";
import userReducer from "../slices/user/userSlice";
import budgetReducer from "../slices/budget/budgetSlice";
import approvalReducer from "../slices/approve/approveSlice"
import requestsReducer from "../slices/requests/requestsSlice";
import accountsReducer from "../slices/accounts/accountsSlice";
import inventoryReducer from "../slices/inventory/inventorySlice";
import reportsReducer from "../slices/reports/reportsSlice";

const initialLoadingState = localStorage.getItem("loading") === "true";

const store = configureStore({
    reducer: {
        auth: authReducer,
        project: projectReducer,
        projectManagement: projectManagementReducer,
        settings: settingsReducer,
        user: userReducer,
        budget: budgetReducer,
        approver: approvalReducer,
        requests: requestsReducer,
        accounts: accountsReducer,
        inventory: inventoryReducer,
        reports: reportsReducer,
        loading: (state = initialLoadingState, action) => {
            if (action.type.endsWith("/pending")) {
                localStorage.setItem("loading", "true");
                return true;
            } else if (
                action.type.endsWith("/fulfilled") ||
                action.type.endsWith("/rejected")
            ) {
                localStorage.removeItem("loading");
                return false;
            }
            return state;
        },
    },
});

// Exporting store types
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
