import React, { useEffect, useState } from 'react';
import { Container, Modal, Form, Button, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { createBudgetAsync, getAllBudgetsAsync } from '../../../../slices/budget/budgetSlice';
import { getUserAsync } from '../../../../slices/auth/authSlice';
import { RootState } from '../../../../store/store';
import { toast } from "react-toastify";
import CurrencyInput from '../../../../components/form/currency-input';

interface CreatePettyBudgetValues {
    budget_amount: string;
    budget_type_id: string;
    company_id: string;
}
interface CreatePettyBudgetProps {
    closeModal: () => void;
    show: boolean;
    onCreateSuccess: () => void;
    projectId: string;

}
const CreatePettyBudget: React.FC<CreatePettyBudgetProps> = ({ show, closeModal, onCreateSuccess, projectId }) => {
    const dispatch = useAppDispatch();
    const [selectedBudgetType, setSelectedBudgetType] = useState<string>("3");

    useEffect(() => {
        dispatch(getUserAsync());
    }, [dispatch]);

    const companyId = useAppSelector(
        (state: RootState) => state.auth.user?.company_id || "",
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleBudgetTypeChange = (typeId: string) => {
        setSelectedBudgetType(typeId);
    };
    return (
        <Container fluid className=''>
            <Modal size="lg" show={show} onHide={closeModal} centered >
                <div className='px-5 py-3'>
                    <Formik
                        initialValues={{
                            budget_amount: "",
                            budget_type_id: selectedBudgetType,
                            company_id: companyId || "",
                            projectId: projectId || '',
                        }}
                        validationSchema={Yup.object().shape({
                            budget_amount: Yup.string().required('Required'),
                            budget_type_id: Yup.string().required('Required'),
                            company_id: Yup.string().required('Required'),
                            projectId: Yup.string().required('Required'),
                        })}
                        onSubmit={(values: CreatePettyBudgetValues, { setSubmitting }) => {
                            console.log(values);
                            const plainBudgetAmount = parseFloat(values.budget_amount.replace(/[^0-9.]/g, ''));
                            const roundedBudgetAmount = Math.round(plainBudgetAmount);
                            dispatch(
                                createBudgetAsync({
                                    ...values,
                                    budget_amount: roundedBudgetAmount.toString(),
                                    company_id: companyId || "",
                                    projectId: projectId || '',

                                })
                            ).unwrap()
                                .then((response) => {
                                    toast.success("Petty cash budget created successfully");
                                    dispatch(getAllBudgetsAsync({ companyId, projectId }));
                                    onCreateSuccess();
                                    closeModal();
                                })
                                .catch((error) => {
                                    const errorMessage = Object.values(error.message).flat().join(" ");
                                    toast.error(errorMessage);
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                });
                        }}                >
                        {({ handleSubmit, handleChange, handleBlur, touched, errors, values }) => (
                            <Form onSubmit={handleSubmit}>
                                <Modal.Header className='text-start mt-3' closeButton>
                                    <h5> Petty cash </h5>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row>
                                        <Col className='mt-2'>
                                            <label className='mt-2'>
                                                <p>Estimated cost for petty cash{" "}
                                                    <span className="text-danger">*</span>
                                                </p>
                                            </label>
                                            <CurrencyInput
                                                name="budget_amount"
                                                placeholder="Enter amount"
                                                className={`fluent-input ${touched.budget_amount && errors.budget_amount ? 'is-invalid' : ''}`}
                                                value={values.budget_amount}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </Col>
                                    </Row>
                                    <Modal.Footer className='mt-5'>
                                        <Row >
                                            <Col md={6}>
                                                <div className=' mt-1'>
                                                    <Button onClick={closeModal} className='cancel-btn'>
                                                        <span className="text-center">Cancel </span>                                        </Button>
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className=' mt-1'>
                                                    <Button type='submit' className=' border-0 create-btn bg-color-1'>
                                                        <span className="text-center">Create </span>                                        </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Modal.Footer>
                                </Modal.Body>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </Container>
    );
};

export default CreatePettyBudget; 
