export const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
) => {
    const { value } = event.target;
    let numericValue = value.replace(/\D/g, "");
    if (numericValue.length > 11) {
        numericValue = numericValue.slice(0, 11);
    }
    event.target.value = numericValue;
};

export const handleAccountNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
) => {
    const { value } = event.target;
    let numericValue = value.replace(/\D/g, "");
    if (numericValue.length > 10) {
        numericValue = numericValue.slice(0, 10);
    }
    event.target.value = numericValue;
};

export const handleAccountNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
) => {
    const { value } = event.target;
    let letterValue = value.replace(/[^a-zA-Z\s]/g, "");
    event.target.value = letterValue;
};
