import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Container, Form, Spinner } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import {
	updateLabourAsync,
	getALabourAsync,
	setLabourId,
	getAllLabourAsync,
} from "../../../../slices/settings/settingsSlice";
import { RootState } from "../../../../store/store";
import { toast } from "react-toastify";
interface EditLabourValues {
	company_id: string;
	id: string;
	labour_type: string;
	labour_description: string;
}
interface EditLabourProps {
	closeModal: () => void;
	show: boolean;
	labourId: string;
	onEditSuccess: () => void;
}

const EditLabour: React.FC<EditLabourProps> = ({
	closeModal,
	show,
	labourId,
	onEditSuccess,
}) => {
	const dispatch = useAppDispatch();
	const [currentPage, setCurrentPage] = useState(1);

	const companyId = useAppSelector(
		(state: RootState) => state.auth.user?.company_id || "",
	);
	const labourData = useAppSelector((state: any) => state.settings.labourData);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (labourId) {
			dispatch(setLabourId(labourId));
			console.log("Fetching labour with ID:", labourId);
			dispatch(getALabourAsync({ companyId, id: labourId })).then(() =>
				setLoading(false),
			);
		} else {
			console.log("labourId is not set");
		}
	}, [dispatch, companyId, labourId]);

	if (loading) {
		return null;
	}

	return (
		<Container fluid className="">
			<Modal
				centered
				size="lg"
				show={show}
				onHide={closeModal}
				style={{ maxHeight: "100vh", overflowY: "auto" }}
			>
				<div className="px-5">
					<Formik
						enableReinitialize
						initialValues={{
							company_id: companyId || "",
							id: labourData?.id || "",
							labour_type: labourData?.labour_type || "",
							labour_description: labourData?.labour_description || "",
						}}
						validationSchema={Yup.object().shape({
							company_id: Yup.string(),
							id: Yup.string(),
							labour_type: Yup.string(),
							labour_description: Yup.string(),
						})}
						onSubmit={(values: EditLabourValues, { setSubmitting }) => {
							console.log(values);
							dispatch(
								updateLabourAsync({
									updateLabourData: values,
									id: labourId || "",
								}),
							).unwrap()
								.then((response) => {
									toast.success("Labour updated successfully");
									dispatch(getAllLabourAsync({ companyId, page: currentPage }));
									onEditSuccess();
									closeModal();
								})
								.catch((error) => {
									const errorMessage = Object.values(error.message).flat().join(" ");
									toast.error(errorMessage);
								})
								.finally(() => {
									setSubmitting(false);
								});
						}}
					>
						{({
							errors,
							touched,
							handleSubmit,
							isSubmitting,
							values,
							handleChange,
						}) => (
							<Form onSubmit={handleSubmit}>
								<Modal.Header closeButton className="text-start mt-3">
									<h4 className="confirm-header">Edit labour type</h4>
								</Modal.Header>
								<Modal.Body>
									<Row>
										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Labour type <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="labour_type"
												placeholder="Enter labour type"
												className={`fluent-input  ${touched.labour_type && errors.labour_type
													? "is-invalid"
													: ""
													}`}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Description
												</p>
											</label>
											<Field
												type="text"
												name="labour_description"
												className={`fluent-input  ${touched.labour_description &&
													errors.labour_description
													? "is-invalid"
													: ""
													}`}
											/>
										</Col>
									</Row>
									<Modal.Footer className="mt-5">
										<Row>
											<Col md={6}>
												<div className=" mt-1">
													<Button onClick={closeModal} className="cancel-btn">
														<span className="text-center">Cancel </span>{" "}
													</Button>
												</div>
											</Col>
											<Col md={6}>
												<div className=" mt-1">
													<Button
														type="submit"
														className=" border-0 create-btn bg-color-1"
													>
														{isSubmitting ? (
															<Spinner animation="border" size="sm" />
														) : (
															<span className="text-center">Edit labour</span>
														)}
													</Button>
												</div>
											</Col>
										</Row>
									</Modal.Footer>
								</Modal.Body>
							</Form>
						)}
					</Formik>
				</div>
			</Modal>
		</Container>
	);
};

export default EditLabour;
