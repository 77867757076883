import React from "react";
import {
	Modal,
	Button,
	Row,
	Col,
	Container,
	Form,
	CloseButton,
} from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";

import { toast } from "react-toastify";
import { useAppDispatch } from "store/hooks";
import { returnUsageAsync } from "slices/inventory/inventorySlice";

interface ReturnUsageValues {
	quantity_returned: number;
}
interface ReturnUsageProps {
	closeModal: () => void;
	onReturnSuccess: () => void;
	projectId: string | null | undefined;
	show: boolean;
	storeRequestId: string | null;
}

const ReturnUsage: React.FC<ReturnUsageProps> = ({
	closeModal,
	onReturnSuccess,
	projectId,
	show,
	storeRequestId,
}) => {
	const companyId = localStorage.getItem("userCompanyID");
	const dispatch = useAppDispatch();

	return (
		<Container fluid className="">
			<Modal
				show={show}
				onHide={closeModal}
				centered
			>
				<div className="px-3">
					<Formik
						initialValues={{
							quantity_returned: 0,
						}}
						validationSchema={Yup.object().shape({
							quantity_returned: Yup.number()
								.required("Required")
								.min(1, "Must be at least 1"),
						})}
						onSubmit={(values: ReturnUsageValues, { setSubmitting }) => {
							if (companyId && projectId) {
								dispatch(
									returnUsageAsync({
										...values,
										projectId: `${projectId}`,
										company_id: `${companyId}`,
										storeRequestId: `${storeRequestId}`,
									}),
								)
									.unwrap()
									.then((response) => {
										toast.success(response.message);
										onReturnSuccess();
										closeModal();
									})
									.catch((error) => {})
									.catch((error) => {
										const errorMessage = Object.values(error.message)
											.flat()
											.join(" ");
										toast.error(errorMessage);
									})
									.finally(() => {
										setSubmitting(false);
									});
								// } else {
								// 	console.error("project_id or company_id is undefined");
							}
						}}
						validateOnChange
						validateOnBlur
					>
						{({ errors, touched, handleSubmit, isSubmitting }) => (
							<Form onSubmit={handleSubmit}>
								<div className="w-100 pt-4 d-inline-flex justify-content-between position-sticky top-0 z-3 bg-color-9 rounded-top">
									<h4>View confirm order</h4>
									<CloseButton onClick={closeModal} />
								</div>
								<Modal.Body>
									<Row>
										<Col className="mt-2">
											<label className="mt-2" htmlFor="quantity_returned">
												Quantity returned <span className="text-danger">*</span>
											</label>
											<Field
												id="quantity_returned"
												type="number"
												name="quantity_returned"
												placeholder="Enter quantity returned"
												className={`fluent-input ${
													touched.quantity_returned && errors.quantity_returned
														? "is-invalid"
														: ""
												}`}
												min={0}
												required
												aria-invalid={
													touched.quantity_returned && errors.quantity_returned
														? "true"
														: "false"
												}
												aria-describedby="quantity_returned_error"
											/>
											{errors.quantity_returned &&
												touched.quantity_returned && (
													<span
														id="quantity_returned_error"
														className="text-danger"
													>
														{errors.quantity_returned}
													</span>
												)}
										</Col>
									</Row>
									<div className="d-grid mt-1">
										<Button
											className="my-4 py-3 border-0 bg-color-1"
											onClick={() => handleSubmit()}
											// disabled={isSubmitting}
										>
											Confirm
										</Button>
									</div>
								</Modal.Body>
							</Form>
						)}
					</Formik>
				</div>
			</Modal>
		</Container>
	);
};

export default ReturnUsage;
