import React from 'react';
import { Container, Button, Image, Card } from 'react-bootstrap';
import VerifiedIcon from '../../../assets/images/verified-icon.png';
import { useNavigate } from 'react-router-dom';

const VerificationSuccessful: React.FC = () => {
    const navigate = useNavigate();

    const backToSignIn = () => {
        navigate('/sign-in');
    }
    return (
        <Container fluid>
            <div className='bg-color-2 '>
                <Card className='auth-card-3'>
                    <div className='text-center p-5'>
                        <Image src={VerifiedIcon} className='mt-5' />
                        <h1 className='my-3'>Verification successful</h1>
                        <p>You have successfully verified your account</p>
                        <div className='d-grid'>
                            <Button type='submit' onClick={backToSignIn} className='bg-color-1 my-3 py-3 border-0'>
                                Back to Sign in
                            </Button>
                        </div>
                    </div>
                </Card>
            </div>

        </Container>
    );
};

export default VerificationSuccessful;
