import React from "react";
import Projects from "./projects";
import { useAppSelector } from "../../store/hooks";

const ProjectsWrapper: React.FC = () => {
    const userId = useAppSelector((state) => state.auth.user?.id);

    return <Projects userId={userId} />;
};

export default ProjectsWrapper;
