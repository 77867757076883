import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Container, Form, Spinner } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import {
	updateLabourRequestAsync,
	getALabourRequestAsync,
	setLabourRequestId,
} from "../../../../slices/requests/requestsSlice";
import { RootState } from "../../../../store/store";
import { Select, FormControl, InputLabel, MenuItem } from "@mui/material";
import { toast } from "react-toastify";
import CurrencyInput from "../../../../components/form/currency-input";
import { handleAccountNumberChange } from "util/inputUtils";

interface EditLabourRequestValues {
	company_id: string;
	head_name: string;
	stage_id: string;
	labour_type_id: string;
	bank_id: string;
	account_number: string;
	amount: string;
	projectId: string;
	id: string;
}
interface EditLabourRequestProps {
	closeModal: () => void;
	show: boolean;
	labourRequestId: string;
	onEditSuccess: () => void;
	projectId: string;
}

const EditLabourRequest: React.FC<EditLabourRequestProps> = ({
	closeModal,
	show,
	labourRequestId,
	projectId,
	onEditSuccess,
}) => {
	const dispatch = useAppDispatch();
	const companyId = useAppSelector(
		(state: RootState) => state.auth.user?.company_id || "",
	);
	const banks = useAppSelector((state) => state.settings.banks);
	const labourTypes = useAppSelector((state) => state.settings.labourTypes?.data);
	const stages = useAppSelector((state) => state.settings.stages?.data);
	const labourRequestData = useAppSelector(
		(state: any) => state.requests.labourRequestData,
	);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (labourRequestId) {
			dispatch(setLabourRequestId(labourRequestId));
			console.log("Fetching labour request with ID:", labourRequestId);
			dispatch(
				getALabourRequestAsync({ companyId, id: labourRequestId, projectId }),
			).then(() => setLoading(false));
		} else {
			console.log("labourRequestId is not set");
		}
	}, [dispatch, companyId, labourRequestId, projectId]);

	if (loading) {
		return null;
	}

	return (
		<Container fluid className="">
			<Modal
				centered
				size="lg"
				show={show}
				onHide={closeModal}
				style={{ maxHeight: "100vh", overflowY: "auto" }}
			>
				<div className="px-5">
					<Formik
						enableReinitialize
						initialValues={{
							company_id: companyId || "",
							head_name: labourRequestData?.Labour_requests?.head_name || "",
							stage_id: labourRequestData?.Labour_requests?.stage_id || "",
							labour_type_id:
								labourRequestData?.Labour_requests?.labour_type_id || "",
							bank_id: labourRequestData?.Labour_requests?.bank_id || "",
							account_number:
								labourRequestData?.Labour_requests?.account_number || "",
							amount: labourRequestData?.Labour_requests?.amount || "",
							projectId: projectId || "",
							id: labourRequestData?.Labour_requests?.id || "",
						}}
						validationSchema={Yup.object().shape({
							head_name: Yup.string(),
							stage_id: Yup.string(),
							labour_type_id: Yup.string(),
							bank_id: Yup.string(),
							account_number: Yup.string(),
							amount: Yup.string(),
						})}
						onSubmit={(values: EditLabourRequestValues, { setSubmitting }) => {
							console.log(values);
							const formattedAmount =
								typeof values.amount === "string"
									? values.amount.replace(/,/g, "")
									: values.amount;
							console.log("Formatted amount:", formattedAmount);
							dispatch(
								updateLabourRequestAsync({
									updateLabourRequestData: {
										company_id: values.company_id,
										head_name: values.head_name,
										stage_id: values.stage_id,
										labour_type_id: values.labour_type_id,
										bank_id: values.bank_id,
										account_number: values.account_number,
										amount: formattedAmount,
										projectId: values.projectId,
										id: values.id,
									},
									id: labourRequestId || "",
								}),
							)
								.unwrap()
								.then((response) => {
									toast.success("Labour request updated successfully");
									onEditSuccess();
									closeModal();
								})
								.catch((error) => {
									const errorMessage = Object.values(error.message)
										.flat()
										.join(" ");
									toast.error(errorMessage);
								})
								.finally(() => {
									setSubmitting(false);
								});
						}}
					>
						{({
							errors,
							touched,
							handleSubmit,
							isSubmitting,
							values,
							handleChange,
							handleBlur,
						}) => (
							<Form onSubmit={handleSubmit}>
								<Modal.Header closeButton className="text-start mt-3">
									<h4 className="confirm-header">Edit labour request</h4>
								</Modal.Header>
								<Modal.Body>
									<Row>
										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Head name <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="head_name"
												className={`fluent-input  ${touched.head_name && errors.head_name
													? "is-invalid"
													: ""
													}`}
											/>
										</Col>
										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Stage <span className="text-danger">*</span>
												</p>
											</label>
											<FormControl fullWidth>
												<InputLabel>Select stage</InputLabel>
												<Select
													label="Select stage"
													name="stage_id"
													value={values.stage_id}
													onChange={handleChange}
												>
													{stages?.map((stage) => (
														<MenuItem key={stage.id} value={stage.id}>
															{stage.stage}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Col>
									</Row>
									<Row>
										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Labour type <span className="text-danger">*</span>
												</p>
											</label>
											<FormControl fullWidth>
												<InputLabel>Select labour type</InputLabel>
												<Select
													label="Select labour type"
													name="labour_type_id"
													value={values.labour_type_id}
													onChange={handleChange}
												>
													{labourTypes?.map((labourType) => (
														<MenuItem key={labourType.id} value={labourType.id}>
															{labourType.labour_type}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Col>
										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Amount <span className="text-danger">*</span>
												</p>
											</label>
											<CurrencyInput
												name="amount"
												placeholder="Enter amount"
												className={`fluent-input  ${touched.amount && errors.amount ? "is-invalid" : ""
													}`}
												value={values.amount}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Bank <span className="text-danger">*</span>
												</p>
											</label>
											<FormControl fullWidth>
												<InputLabel>Select bank </InputLabel>
												<Select
													label="Select bank "
													name="bank_id"
													value={values.bank_id}
													onChange={handleChange}
												>
													{Array.isArray(banks) && banks.length > 0 ? (
														banks.map((bank) => (
															<MenuItem key={bank.id} value={bank.id}>
																{bank.bank_name}
															</MenuItem>
														))
													) : (
														<p> no banks </p>
													)}
												</Select>
											</FormControl>
										</Col>
										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Account number <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="account_number"
												className={`fluent-input  ${touched.account_number && errors.account_number
													? "is-invalid"
													: ""
													}`}
												onInput={handleAccountNumberChange}
											/>
										</Col>
									</Row>

									<Modal.Footer className="mt-5">
										<Row>
											<Col md={6}>
												<div className=" mt-1">
													<Button onClick={closeModal} className="cancel-btn">
														<span className="text-center">Cancel </span>{" "}
													</Button>
												</div>
											</Col>
											<Col md={6}>
												<div className=" mt-1">
													<Button
														type="submit"
														className=" border-0 create-btn bg-color-1"
														onClick={() =>
															console.log("Edit request button clicked")
														}
													>
														{isSubmitting ? (
															<Spinner animation="border" size="sm" />
														) : (
															<span className="text-center">Edit request</span>
														)}
													</Button>
												</div>
											</Col>
										</Row>
									</Modal.Footer>
								</Modal.Body>
							</Form>
						)}
					</Formik>
				</div>
			</Modal>
		</Container>
	);
};

export default EditLabourRequest;
