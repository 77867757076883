export const hasPermission = (permissions: string[] | null, requiredPermissions: string[]): boolean => {
    if (permissions === null) {
        // If permissions are null, this means the user is an admin and has full access
        return true;
    }

    if (!permissions || permissions.length === 0) {
        return false; // If permissions are empty or undefined, return false
    }

    // Check if the user has any of the required permissions
    return requiredPermissions.some(permission => permissions.includes(permission));

};

