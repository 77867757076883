import axios from "axios";

interface Confirmations {
	confirmation_id: number;
	confirmed_by: string;
	confirmed_quantity: number;
	confirmed_at: string;
}
interface Confirmed_inventory {
	id: string;
	material_name: string;
	quantity: number;
	status: string;
	created_at: string;
}
export interface Inventory {
	id: string;
	created_at: string;
	material: string;
	quantity_ordered: number;
	qty_received: number;
	confirmed_quantity: number;
	status: string;
	company_id: string;
	projectId: string;
	confirmations: Confirmations[];
}
export interface ConfirmedInventory {
	Instock: number;
	material: string;
	unit: string;
	Status: string;
	id: string;
	projectId: string;
}

export interface ConfirmAnInventoryData {
	comment: string;
	company_id: string;
	confirmed_quantity: number;
	id: string;
	projectId: string;
}

export interface StoreRequestData {
	collected_by: string;
	company_id: string;
	confirmedInventoryId: string;
	head_name: string;
	labour_type_id: string;
	stage_id: string;
	projectId: string;
	quantity: number;
}
export interface StoreRequests extends StoreRequestData {
	id: string | null;
	labour_type: string;
	stage: string;
	quantity_collected: number;
	quantity_returned: number;
	confirmed_inventory: {
		id: string;
		material_name: string;
		quantity: number;
		status: string;
		created_at: string;
	};
	project: string;
	created_by: string;
	usage: Usage[];
}

export interface GetAllInventoryResponse {
	data: Inventory[];
	message: string;
	meta: {
		total: number;
		count: number;
		per_page: number;
		current_page: number;
		last_page: number;
		total_pages: number;
	};
}
export interface GetConfirmedInventoryResponse {
	data: ConfirmedInventory[];
	message: string;
	meta: {
		total: number;
		count: number;
		per_page: number;
		current_page: number;
		last_page: number;
		total_pages: number;
	};
}
export interface GetAnInventoryResponse {
	data: Inventory;
	message: string;
}
export interface ConfirmAnInventoryResponse {
	data: ConfirmAnInventoryData;
	message: string;
}
export interface CreateStoreRequestResponse {
	data: StoreRequestData;
	message: string;
}
export interface GetAllStoreRequestsResponse {
	data: StoreRequests[];
	message: string;
	meta: {
		total: number;
		count: number;
		per_page: number;
		current_page: number;
		last_page: number;
		total_pages: number;
	};
}
export interface SearchInventoryResponse {
	data: Inventory[];
	message: string;
	meta: {
		total: number;
		count: number;
		per_page: number;
		current_page: number;
		last_page: number;
		total_pages: number;
	};
}

export interface GetAStoreRequestResponse {
	data: StoreRequests;
	message: string;
}
export interface ReturnUsageData {
	company_id: string | null;
	projectId: string;
	quantity_returned: number | null;
	storeRequestId: string;
}
export interface Usage extends ReturnUsageData {
	collected_by: string;
	confirmed_inventory: number;
	created_at: string;
	created_by: number;
	head_name: string;
	id: string | null;
	labour_type: number;
	material_name: string;
	quantity: number;
	quantity_used: number;
	stage: number;
}
export interface ReturnUsageResponse {
	data: Usage;
	message: string;
}
export interface GetAllUsagesResponse {
	data: Usage[];
	message: string;
	meta: {
		total: number;
		count: number;
		per_page: number;
		current_page: number;
		last_page: number;
		total_pages: number;
	};
}
export interface GetAUsageResponse {
	data: Usage;
	message: string;
}
export interface GetLowStockResponse {
	data: Inventory[];
	message: string;
}

export const getAllInventory = async (
	company_id: string,
	projectId: string,
	page: number,
): Promise<GetAllInventoryResponse> => {
	const response = await axios.get(
		`https://syteboxx-api.remsys.com.ng/api/v1/projects/${projectId}/inventory?company_id=${company_id}&page=${page}`,
	);
	return response.data;
};
export const searchInventory = async (
	company_id: string | null,
	projectId: string | undefined,
	searchTerm: string | null,
): Promise<SearchInventoryResponse> => {
	const response = await axios.get(
		`https://syteboxx-api.remsys.com.ng/api/v1/projects/${projectId}/inventory/search/${searchTerm}?company_id=${company_id}`,
	);
	return response.data;
};

export const getAnInventory = async (
	company_id: string,
	projectId: string,
	id: string | null,
): Promise<GetAnInventoryResponse> => {
	const response = await axios.get(
		`https://syteboxx-api.remsys.com.ng/api/v1/projects/${projectId}/inventory/${id}?company_id=${company_id}`,
	);
	return response.data;
};
export const getConfirmedInventory = async (
	company_id: string,
	projectId: string,
	page: number,
): Promise<GetConfirmedInventoryResponse> => {
	const response = await axios.get(
		`https://syteboxx-api.remsys.com.ng/api/v1/projects/${projectId}/inventory/requests/materials?company_id=${company_id}&page=${page}`,
	);
	return response.data;
};
export const confirmAnInventory = async (
	data: ConfirmAnInventoryData,
): Promise<ConfirmAnInventoryResponse> => {
	const response = await axios.post(
		`https://syteboxx-api.remsys.com.ng/api/v1/projects/${data.projectId}/inventory/${data.id}/confirm?company_id=${data.company_id}&confirmed_quantity=${data.confirmed_quantity}&comment=${data.comment}`,
	);
	return response.data;
};
export const createStoreRequest = async (
	data: StoreRequestData,
): Promise<CreateStoreRequestResponse> => {
	const response = await axios.post(
		`https://syteboxx-api.remsys.com.ng/api/v1/projects/${data.projectId}/inventory/${data.confirmedInventoryId}/requests/store/create?company_id=${data.company_id}&head_name=${data.head_name}&collected_by=${data.collected_by}&stage_id=${data.stage_id}&labour_type_id=${data.labour_type_id}&quantity=${data.quantity}`,
	);
	return response.data;
};
export const getAllStoreRequests = async (
	company_id: string,
	projectId: string,
	page: number,
): Promise<GetAllStoreRequestsResponse> => {
	const response = await axios.get(
		`https://syteboxx-api.remsys.com.ng/api/v1/projects/${projectId}/inventory/requests/store?company_id=${company_id}&page=${page}`,
	);
	return response.data;
};
export const getAStoreRequest = async (
	company_id: string,
	projectId: string,
	id: string | null,
): Promise<GetAStoreRequestResponse> => {
	const response = await axios.get(
		`https://syteboxx-api.remsys.com.ng/api/v1/projects/${projectId}/inventory/requests/store/${id}?company_id=${company_id}`,
	);
	return response.data;
};
export const returnUsage = async (
	data: ReturnUsageData,
): Promise<ReturnUsageResponse> => {
	const response = await axios.post(
		`https://syteboxx-api.remsys.com.ng/api/v1/projects/${data.projectId}/inventory/requests/store/${data.storeRequestId}/return?company_id=${data.company_id}&quantity_returned=${data.quantity_returned}`,
	);
	return response.data;
};
export const getAllUsages = async (
	company_id: string,
	projectId: string,
	page: number,
): Promise<GetAllUsagesResponse> => {
	const response = await axios.get(
		`https://syteboxx-api.remsys.com.ng/api/v1/projects/${projectId}/inventory/requests/store/usages?company_id=${company_id}&page=${page}`,
	);
	return response.data;
};
export const getAUsage = async (
	company_id: string,
	projectId: string,
	id: string | null,
): Promise<GetAUsageResponse> => {
	const response = await axios.get(
		`https://syteboxx-api.remsys.com.ng/api/v1/projects/${projectId}/inventory/usage?company_id=${company_id}`,
	);
	return response.data;
};
export const getLowStock = async (
	company_id: string,
): Promise<GetLowStockResponse> => {
	const response = await axios.get(
		`https://syteboxx-api.remsys.com.ng/api/inventory/${company_id}`,
	);
	return response.data;
};
