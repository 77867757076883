import React, { useEffect, useCallback, useState } from "react";
import { Container, Modal, Form, Button, Row, Col } from "react-bootstrap";
import { Formik, Field } from "formik";
import {
	Select,
	FormControl,
	InputLabel,
	MenuItem,
	Tooltip,
} from "@mui/material";
import Info from "../../../../assets/images/info-icon.svg";
import * as Yup from "yup";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { RootState } from "../../../../store/store";
import { getUserAsync } from "../../../../slices/auth/authSlice";
import {
	getAllLabourAsync,
	getAllStagesAsync,
} from "slices/settings/settingsSlice";
import { ConfirmedInventory } from "services/inventory/inventoryService";

import { toast } from "react-toastify";
import { createStoreRequestAsync } from "slices/inventory/inventorySlice";
import { useParams } from "react-router-dom";

interface CreateStoreRequestValues {
	collected_by: string;
	company_id: string;
	confirmedInventoryId: string;
	head_name: string;
	labour_type_id: string;
	projectId: string;
	quantity: number;
	stage_id: string;
}
interface CreateStoreRequestProps {
	inventory: ConfirmedInventory[] | null | undefined;
	closeModal: () => void;
	show: boolean;
	onCreateSuccess: () => void;
}
const CreateStoreRequest: React.FC<CreateStoreRequestProps> = ({
	show,
	closeModal,
	onCreateSuccess,
	inventory,
}) => {
	const dispatch = useAppDispatch();

	const companyId = localStorage.getItem("userCompanyID");
	const { projectId } = useParams<{ projectId: string }>();

	const labourTypes = useAppSelector(
		(state: RootState) => state.settings.labourTypes?.data,
	);

	const stages = useAppSelector(
		(state: RootState) => state.settings.stages?.data,
	);
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		dispatch(getUserAsync());
	}, [dispatch]);

	useEffect(() => {
		if (companyId) {
			dispatch(getAllLabourAsync({ companyId, page: currentPage }));
		}
	}, [companyId, currentPage, dispatch]);

	useEffect(() => {
		if (companyId) {
			dispatch(getAllStagesAsync({ companyId, page: currentPage }));
		}
	}, [dispatch, currentPage, companyId]);

	// Memoized closeModal to prevent re-rendering
	const handleCloseModal = useCallback(() => {
		closeModal();
	}, [closeModal]);

	return (
		<Container fluid className="">
			<Modal size="lg" show={show} onHide={handleCloseModal} centered>
				<div className="px-5 py-2">
					<Formik
						initialValues={{
							company_id: companyId || "",
							confirmedInventoryId: "",
							labour_type_id: "",
							stage_id: "",
							quantity: 0,
							collected_by: "",
							projectId: projectId || "",
							head_name: "",
						}}
						validationSchema={Yup.object().shape({
							company_id: Yup.string().required("Required"),
							collected_by: Yup.string().required("Required"),
							confirmedInventoryId: Yup.string().required("Required"),
							head_name: Yup.string().required("Required"),
							labour_type_id: Yup.string().required("Required"),
							stage_id: Yup.string().required("Required"),
							quantity: Yup.number().required("Required").min(1, "Minimum 1"),
						})}
						onSubmit={(values: CreateStoreRequestValues, { setSubmitting }) => {
							dispatch(createStoreRequestAsync({ ...values }))
								.unwrap()
								.then(() => {
									toast.success("Store request created successfully");
									onCreateSuccess();
									handleCloseModal();
								})
								.catch((error) => {
									const errorMessage = Object.values(error.message)
										.flat()
										.join(" ");
									toast.error(errorMessage);
								})
								.finally(() => {
									setSubmitting(false);
								});
						}}
						validateOnChange
						validateOnBlur
					>
						{({
							handleSubmit,
							isSubmitting,
							handleChange,
							touched,
							errors,
							values,
						}) => (
							<Form onSubmit={handleSubmit}>
								<Modal.Header className="text-start mt-2" closeButton>
									<h4 className="confirm-header"> Create store request </h4>
								</Modal.Header>
								<Modal.Body>
									<Row>
										<Col md={12} className="mt-1 w-50">
											<label>
												Material <span className="text-danger">*</span>
											</label>
											<FormControl fullWidth>
												<InputLabel>Select material</InputLabel>
												<Select
													label="Select material"
													name="confirmedInventoryId"
													value={values.confirmedInventoryId}
													onChange={handleChange}
												>
													{inventory?.length ? (
														inventory.map((inventory) => (
															<MenuItem key={inventory.id} value={inventory.id}>
																{inventory.material}
															</MenuItem>
														))
													) : (
														<MenuItem disabled>No materials available</MenuItem>
													)}
												</Select>
											</FormControl>
											<div className="invalid-feedback d-block">
												{touched.confirmedInventoryId &&
													errors.confirmedInventoryId}
											</div>
										</Col>
										<Col md={12} className="mt-1 w-50">
											<label className="mt-1">
												Labour type <span className="text-danger">*</span>
											</label>
											<FormControl fullWidth>
												<InputLabel>Select labour type</InputLabel>
												<Select
													label="Select labour type"
													name="labour_type_id"
													value={values.labour_type_id}
													onChange={handleChange}
												>
													{labourTypes?.map((labour) => (
														<MenuItem key={labour.id} value={labour.id}>
															{labour.labour_type}
														</MenuItem>
													))}
												</Select>
											</FormControl>
											<div className="invalid-feedback d-block">
												{touched.labour_type_id && errors.labour_type_id}
											</div>
										</Col>
									</Row>
									<Row>
										<Col md={12} className="mt-1 w-50">
											<label className="mt-1">
												Stage <span className="text-danger">*</span>
											</label>
											<FormControl fullWidth>
												<InputLabel>Select stage</InputLabel>
												<Select
													label="Select stage"
													name="stage_id"
													value={values.stage_id}
													onChange={handleChange}
												>
													{stages?.map((stage) => (
														<MenuItem key={stage.id} value={stage.id}>
															{stage.stage}
														</MenuItem>
													))}
												</Select>
											</FormControl>
											<div className="invalid-feedback d-block">
												{touched.stage_id && errors.stage_id}
											</div>
										</Col>
										<Col md={12} className="mt-1 w-50">
											<label className="mt-1">
												Quantity <span className="text-danger">*</span>
											</label>
											<Field
												type="number"
												name="quantity"
												inputMode="numeric"
												pattern="[0-9]*"
												placeholder="Enter a number"
												className={`fluent-input ${
													touched.quantity && errors.quantity
														? "is-invalid"
														: ""
												}`}
												required
											/>
											<div className="invalid-feedback d-block">
												{touched.quantity && errors.quantity}
											</div>
										</Col>
									</Row>
									<Row>
										<Col md={12} className="mt-1 w-50">
											<label>
												Collected by <span className="text-danger">*</span>
											</label>
											<Field
												type="text"
												name="collected_by"
												placeholder="Enter a name"
												className={`fluent-input ${
													touched.collected_by && errors.collected_by
														? "is-invalid"
														: ""
												}`}
												required
											/>
											<div className="invalid-feedback d-block">
												{touched.collected_by && errors.collected_by}
											</div>
										</Col>
										<Col md={12} className="mt-1 w-50">
											<label htmlFor="head_name">
												Headname <span className="text-danger">*</span>
											</label>
											<Tooltip
												title="Name of the head of the labour type"
												placement="top"
												arrow
												enterDelay={200}
												leaveDelay={200}
												sx={{ margin: "0", padding: "0" }}
											>
												<img
													src={Info}
													alt="info-icon"
													width="15px"
													height="15px"
													className="mx-2 pointer"
												/>
											</Tooltip>
											<Field
												type="text"
												name="head_name"
												placeholder="Enter a name"
												className={`fluent-input ${
													touched.head_name && errors.head_name
														? "is-invalid"
														: ""
												}`}
												required
											/>
											<div className="invalid-feedback d-block">
												{touched.head_name && errors.head_name}
											</div>
										</Col>
									</Row>
								</Modal.Body>
								<Modal.Footer>
									<Button onClick={closeModal} className="cancel-btn">
										<span className="text-center">Cancel </span>{" "}
									</Button>
									<Button
										className=" border-0 create-btn bg-color-1"
										disabled={isSubmitting}
										type="submit"
									>
										<span className="text-center">
											{isSubmitting ? `Creating...` : `Create`}
										</span>{" "}
									</Button>
								</Modal.Footer>
							</Form>
						)}
					</Formik>
				</div>
			</Modal>
		</Container>
	);
};

export default CreateStoreRequest;
