import React, { useState, useEffect } from "react";
import Layout from '../../../components/layouts/layout';
import { Row, Col, Card, Nav, Image } from 'react-bootstrap';
import LinearProgress from '@mui/material/LinearProgress';
import { PieChart } from '@mui/x-charts/PieChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { BarChart } from '@mui/x-charts/BarChart';
import Export from "../../../assets/images/export.png";
import Calendar from "../../../assets/images/calender.png";
import DropdownIcon from "../../../assets/images/dropdown-vector.png"
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { getAllReportsAsync } from "slices/reports/reportsSlice";


function Report() {
    const dispatch = useAppDispatch();
    const { projectId } = useParams<{ projectId: string }>();
    const companyId = useAppSelector(
        (state: RootState) => state.auth.user?.company_id || "",
    );
    const reportData = useAppSelector((state: RootState) => state.reports.reports?.data);
    const [activeTab, setActiveTab] = useState<string>("material_bought");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
    useEffect(() => {
        setActiveTab("material_bought");
    }, []);

    const tabOrder: string[] = ["material_bought", "material_used"];

    const handleTabSelect = (key: string | null) => {
        if (key) {
            setActiveTabIndex(tabOrder.indexOf(key));
            setActiveTab(key);
        }
    };
    useEffect(() => {
        if (companyId && projectId) {
            dispatch(getAllReportsAsync({ companyId, projectId }));
        }
    }, [companyId, dispatch, projectId]);
    const budgetChartColors = ['#0F6CBD', '#6CBD0F', '#E08213B2', ''];

    const budgetValues = [
        { label: "Materials", value: reportData?.budgets_summary?.material_budgets || 0, color: budgetChartColors[0], spent: reportData?.budgets_summary?.material_spent || 0 },
        { label: "Labour", value: reportData?.budgets_summary?.labour_budgets || 0, color: budgetChartColors[1], spent: reportData?.budgets_summary?.labour_spent || 0 },
        { label: "Petty Cash", value: reportData?.budgets_summary?.petty_cash_budgets || 0, color: budgetChartColors[2], spent: reportData?.budgets_summary?.petty_cash_spent || 0 }
    ];
    const totalBudget = reportData?.budgets_summary?.total_budgets || 0;

    const projectChartColors = ['#0F6CBD', '#6CBD0F', '#BD0F6C'];
    const projectValues = [
        { label: "In progress", value: reportData?.projects_summary?.in_progress_percentage || 0, color: projectChartColors[0] },
        { label: "Completed", value: reportData?.projects_summary?.done_percentage || 0, color: projectChartColors[1] },
        { label: "Delayed", value: reportData?.projects_summary?.overdue_percentage || 0, color: projectChartColors[2] }
    ];
    const issuesChartColors = ['#BD0F6CB2', '#6CBD0FB2'];

    console.log(reportData?.monthly_spent_summary);

    return (
        <div className="bg-color-8">
            <Layout>
                <div className="mt-5 p-5">
                    <h3>Reports</h3>
                    <Row className='mt-5'>
                        <Col md={4} sm={12} xs={12}>
                            <Card className='bg-color-9 border-0 p-2'>
                                <p className='report-text-1'>Budget breakdown</p>
                                <Row>
                                    <Col md={7} sm={7} xs={7}>
                                        <div style={{ position: 'relative', width: 280, height: 180 }}>
                                            <PieChart
                                                series={[{
                                                    data: [
                                                        { value: reportData?.budgets_summary?.material_budgets || 0 },
                                                        { value: reportData?.budgets_summary?.labour_budgets || 0 },
                                                        { value: reportData?.budgets_summary?.petty_cash_budgets || 0 }
                                                    ],
                                                    innerRadius: 73
                                                }]}
                                                width={280}
                                                height={180}
                                                colors={['#0F6CBD', '#6CBD0F', '#E08213B2']}
                                            />
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '55%',
                                                    left: '33%',
                                                    transform: 'translate(-50%, -50%)',
                                                    textAlign: 'center',
                                                    fontSize: '16px',
                                                    fontWeight: 'bold',
                                                    pointerEvents: 'none'
                                                }}
                                            >
                                                <p className="report-text-8 mb-0">Total Budget</p>
                                                <p className="report-text-9 mt-0">{`₦${totalBudget?.toLocaleString()}`}</p>
                                            </div>
                                        </div>

                                    </Col>
                                    <Col md={5} sm={5} xs={5}>
                                        {budgetValues.map((budget, index) => {
                                            return (
                                                <div key={index} className="mb-3">
                                                    <div className="d-flex justify-content-between align-items-center report-text-6">
                                                        <span className="mt-0">{budget.label}</span>
                                                    </div>
                                                    <p className="mt-0 mb-0  report-text-10" style={{ color: budget.color }}>Spent: <span>{`₦${budget.spent.toLocaleString()}`}</span></p>
                                                    <p className="mt-0 mb-0  report-text-11" style={{ color: budget.color }}>Budget: <span>{`₦${budget.value.toLocaleString()}`}</span></p>
                                                </div>
                                            );
                                        })}
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col md={4} sm={12} xs={12}>
                            <Card className='bg-color-9 border-0 p-2'>
                                <p className='report-text-1'>Project status</p>
                                <Row>
                                    <Col md={7} sm={7} xs={7}>
                                        <PieChart
                                            series={[{
                                                data: [
                                                    { value: reportData?.projects_summary?.in_progress_percentage || 0 },
                                                    { value: reportData?.projects_summary?.done_percentage || 0 },
                                                    { value: reportData?.projects_summary?.overdue_percentage || 0 }
                                                ],
                                                innerRadius: 73
                                            }]}
                                            width={280}
                                            height={180}
                                            colors={['#0F6CBD', '#6CBD0F', '#BD0F6C']}
                                        />
                                    </Col>
                                    <Col md={5} sm={5} xs={5}>
                                        {projectValues.map((project, index) => {
                                            return (
                                                <div key={index} className="mb-3">
                                                    <div className="d-flex justify-content-between align-items-center report-text-6">
                                                        <span className="mt-0">{project.label}</span>
                                                    </div>
                                                    <p className="mb-1 text-muted report-text-7">{project.value.toFixed()}%</p>
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={+project.value}
                                                        sx={{
                                                            '& .MuiLinearProgress-bar': {
                                                                backgroundColor: project.color
                                                            }
                                                        }}
                                                        className="report-progress-bar"
                                                    />
                                                </div>
                                            );
                                        })}
                                    </Col>

                                </Row>
                            </Card>
                        </Col>
                        <Col md={4} sm={12} xs={12}>
                            <Card className='bg-color-9 border-0 p-2'>
                                <p className='report-text-1'>Issues</p>
                                <Row>
                                    <Col md={7} sm={7} xs={7}>
                                        <PieChart
                                            series={[{
                                                data: [
                                                    { value: reportData?.projects_summary?.overdue_percentage || 0 },
                                                    { value: reportData?.projects_summary?.done_percentage || 0 },
                                                ],
                                                innerRadius: 73
                                            }]}
                                            width={280}
                                            height={180}
                                            colors={['#BD0F6CB2', '#6CBD0FB2']}
                                        />
                                    </Col>
                                    <Col md={5} sm={5} xs={5} className="mt-3">
                                        <div className="mb-3">
                                            <div className="d-flex justify-content-between align-items-center report-text-6">
                                                <span className="mt-0">Open</span>
                                            </div>
                                            <p className="mb-1 text-muted report-text-7">{reportData?.projects_summary?.done_percentage.toFixed()}%</p>
                                            <LinearProgress
                                                variant="determinate"
                                                value={reportData?.projects_summary?.done_percentage}
                                                sx={{
                                                    '& .MuiLinearProgress-bar': {
                                                        backgroundColor: issuesChartColors[0]
                                                    }
                                                }}
                                                className="report-progress-bar"

                                            />
                                        </div>
                                        <div className="mb-3">
                                            <div className="d-flex justify-content-between align-items-center report-text-6">
                                                <span className="mt-0">Resolved</span>
                                            </div>
                                            <p className="mb-1 text-muted report-text-7">{reportData?.projects_summary?.overdue_percentage.toFixed()}%</p>
                                            <LinearProgress
                                                variant="determinate"
                                                value={reportData?.projects_summary?.overdue_percentage}
                                                sx={{
                                                    '& .MuiLinearProgress-bar': {
                                                        backgroundColor: issuesChartColors[1]
                                                    },
                                                }}
                                                className="report-progress-bar"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                    </Row>
                    <Row className="mt-5 bg-color-9">
                        <p>Project & Budget History</p>
                        <LineChart
                            xAxis={[
                                {
                                    scaleType: 'point',
                                    data: reportData?.monthly_spent_summary?.map((entry) => entry.month) || Array(12).fill('Month'),
                                },
                            ]}
                            series={[
                                {
                                    data: reportData?.monthly_spent_summary?.map((entry) => entry.total_spent) || Array(12).fill(0),
                                    showMark: false,
                                },
                            ]}
                            height={300}
                            grid={{ horizontal: true }}
                        />
                    </Row>


                    <Row className='mt-5 bg-color-9'>
                        <Row className="mt-2">
                            <Col md={1} sm={3} xs={3} className="">
                                <div className="mx-3 mt-1 report-text-3">
                                    Material
                                </div>
                            </Col>
                            <Col md={2} sm={5} xs={5} className="d-flex">
                                <div className="mx-4">
                                    <small className="report-text-4">Amount spent</small>
                                    <p className="report-text-5">{`₦${reportData?.materials_paid_summary?.total_monthly_cost?.toLocaleString()}`}</p>
                                </div>
                            </Col>
                            <Col md={2} sm={4} xs={4} className="d-flex">
                                <div className="">
                                    <small className="report-text-4">No. of material used</small>
                                    <p className="report-text-5">{reportData?.materials_paid_summary?.total_monthly_usage}</p>
                                </div>
                            </Col>
                            <Col md={4}>
                                <Nav
                                    activeKey={activeTab}
                                    onSelect={handleTabSelect}
                                    className="report-nav bg-color-9 py-1"
                                >
                                    <Nav.Item>
                                        <Nav.Link
                                            className=" px-2 py-0 rounded fw-light mx-2"
                                            eventKey="material_bought"
                                            style={{
                                                color: activeTab === "material_bought" ? " #FFFFFF " : " #747487",
                                                textDecoration: "none",
                                                backgroundColor:
                                                    activeTab === "material_bought" ? "#0F6CBD" : "transparent",
                                            }}
                                        >
                                            Material bought
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            className=" px-2 py-0 rounded fw-light mx-2"
                                            eventKey="material_used"
                                            style={{
                                                textDecoration: "none",
                                                color: activeTab === "material_used" ? " #FFFFFF " : " #747487",
                                                backgroundColor:
                                                    activeTab === "material_used" ? "#0F6CBD" : "transparent",
                                            }}
                                        >
                                            Materials used
                                        </Nav.Link>
                                    </Nav.Item>

                                </Nav>
                            </Col>
                            <Col md={3} className="mt-1">
                                <div className="report-text-2 float-end">
                                    <Image src={Export} className="mx-3" />
                                    <span className="date-border px-1">
                                        <Image src={Calendar} />
                                        <small className="mx-2">Today</small>
                                        <Image src={DropdownIcon} />
                                    </span>
                                </div>
                            </Col>
                        </Row>
                        <div>
                            {activeTab === "material_bought" && (
                                <BarChart
                                    xAxis={[{
                                        scaleType: 'band',
                                        data: reportData?.materials_paid_summary?.monthly_paid_materials.map(m => m.material_name) || [],
                                    }]}
                                    series={[{ data: reportData?.materials_paid_summary?.monthly_paid_materials.map(m => m.total_cost) || [] }]}
                                    height={300}
                                    colors={['#f5d8b8']}
                                    grid={{ horizontal: true }}
                                />
                            )}
                        </div>
                        <div>
                            {activeTab === "material_used" && (
                                <BarChart
                                    xAxis={[{ scaleType: 'band', data: reportData?.materials_paid_summary?.monthly_paid_materials.map(m => m.material_name) || [] }]}
                                    series={[{ data: reportData?.materials_paid_summary?.monthly_paid_materials.map(m => m.total_quantity) || [] }]}
                                    height={300}
                                    colors={['#d3ebb7']}
                                    grid={{ horizontal: true }}
                                />
                            )}
                        </div>


                    </Row>
                    <Row className='mt-5 bg-color-9'>
                        <Row className="mt-2">
                            <Col md={1} sm={6} xs={6} className="">
                                <div className="mx-3 mt-1 report-text-3">
                                    Labour
                                </div>
                            </Col>
                            <Col md={3} sm={6} xs={6} className="d-flex">
                                <div className="mx-3">
                                    <small className="report-text-4">Amount spent</small>
                                    <p className="report-text-5">{`₦${reportData?.labour_paid_summary?.total_monthly_cost?.toLocaleString()}`}</p>
                                </div>
                            </Col>
                            <Col md={8} className="mt-1">
                                <div className="report-text-2 float-end">
                                    <Image src={Export} className="mx-3" />
                                    <span className="date-border px-1">
                                        <Image src={Calendar} />
                                        <small className="mx-2">Today</small>
                                        <Image src={DropdownIcon} />
                                    </span>
                                </div>
                            </Col>
                        </Row>

                        <BarChart
                            xAxis={[{ scaleType: 'band', data: reportData?.labour_paid_summary?.monthly_paid_labour.map(l => l.labour_type) || [] }]}
                            series={[{ data: reportData?.labour_paid_summary?.monthly_paid_labour.map(l => l.total_cost) || [] }]}
                            height={300}
                            colors={['#9fc3e3']}
                            grid={{ horizontal: true }}
                        />
                    </Row>
                    <Row className='mt-5 bg-color-9'>
                        <Col md={6} >
                            <Row className="mt-2">
                                <Col md={2} sm={6} xs={6} className="">
                                    <div className="mx-3 mt-1 report-text-3">
                                        Vendor
                                    </div>
                                </Col>
                                <Col md={3} sm={6} xs={6} className="d-flex">
                                    <div className="mx-3">
                                        <small className="report-text-4">Amount spent</small>
                                        <p className="report-text-5">{`₦${reportData?.materials_by_vendor_summary?.total_amount_from_vendors?.toLocaleString()}`}</p>
                                    </div>
                                </Col>
                                <Col md={6} className="mt-1">
                                    <div className="report-text-2 float-end">
                                        <Image src={Export} className="mx-3" />
                                        <span className="date-border px-1">
                                            <Image src={Calendar} />
                                            <small className="mx-2">Today</small>
                                            <Image src={DropdownIcon} />
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                            <BarChart
                                yAxis={[{ scaleType: 'band', data: reportData?.materials_by_vendor_summary?.materials_by_vendor.map(v => v.vendor) || [] }]}
                                series={[{ data: reportData?.materials_by_vendor_summary?.materials_by_vendor.map(v => v.total_amount) || [] }]}
                                height={300}
                                layout="horizontal"
                                colors={['#0F6CBD66']}
                            />
                        </Col>
                        <Col md={6}>
                            <Row className="mt-2">
                                <Col md={3} sm={6} xs={6} className="">
                                    <div className="mx-3 mt-1 report-text-3">
                                        Petty cash
                                    </div>
                                </Col>
                                <Col md={3} sm={6} xs={6} className="d-flex">
                                    <div className="">
                                        <small className="report-text-4">Amount spent</small>
                                        <p className="report-text-5">{`₦${reportData?.petty_cash_requests_by_title_summary?.total_petty_cash_amount?.toLocaleString()}`}</p>
                                    </div>
                                </Col>
                                <Col md={6} className="mt-1">
                                    <div className="report-text-2 float-end">
                                        <Image src={Export} className="mx-3" />
                                        <span className="date-border px-1">
                                            <Image src={Calendar} />
                                            <small className="mx-2">Today</small>
                                            <Image src={DropdownIcon} />
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                            <BarChart
                                yAxis={[{ scaleType: 'band', data: reportData?.petty_cash_requests_by_title_summary?.petty_cash_requests_by_title.map(t => t.petty_cash_title) || [] }]}
                                series={[{ data: reportData?.petty_cash_requests_by_title_summary?.petty_cash_requests_by_title.map(t => t.total_amount) || [] }]}
                                height={300}
                                layout="horizontal"
                                colors={['#187E2266']}
                            />
                        </Col>
                    </Row>
                </div>
            </Layout>
        </div>)
}

export default Report