import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createProject, updateProject, getAllProjects, getAProject } from "../../services/project/projectService";
import { CreateProjectData, CreateProjectResponse, UpdateProjectData, UpdateProjectResponse, Project } from "../../services/project/projectService";

export interface ProjectState {
    createProjectResponse?: CreateProjectResponse | null;
    updateProjectResponse?: UpdateProjectResponse | null;
    projects: Project[];
    projectId?: string;
    companyId?: string | undefined;
    projectData?: Project | null;
}

const initialState: ProjectState = {
    createProjectResponse: null,
    updateProjectResponse: null,
    projects: [],
    projectId: "",
    companyId: "",
    projectData: null,
}

export const createProjectAsync = createAsyncThunk<CreateProjectResponse, CreateProjectData>(
    'project/createProject',
    async (createProjectData: CreateProjectData, { dispatch, rejectWithValue }) => {
        try {
            const response = await createProject(createProjectData);
            if (response.data && response.data.id) {
                const projectId = response.data.id;
                localStorage.setItem('projectId', projectId);
                dispatch(setProjectId(projectId));
            }
            return response;
        } catch (error: any) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ error: true, message: "An unexpected error occurred" });
        }
    }
);

export const updateProjectAsync = createAsyncThunk(
    'project/updateProject',
    async ({ updateProjectData, id }: { updateProjectData: UpdateProjectData, id: string }) => {
        const response = await updateProject(updateProjectData, id);
        return response;
    }
)
export const getAllProjectsAsync = createAsyncThunk(
    'project/getAllProjects',
    async (companyId: string) => {
        try {
            console.log('Company ID:', companyId);
            const projects = await getAllProjects(companyId);
            return projects;
        } catch (error) {
            console.error('Error fetching projects:', error);
            throw error;
        }
    }
);
export const getAProjectAsync = createAsyncThunk(
    'project/getAProject',
    async ({ companyId, id }: { companyId: string; id: string }) => {
        const project = await getAProject(companyId, id);
        return project;
    }
)

const projectSlice = createSlice({
    name: "project",
    initialState,
    reducers: {
        setProjectId: (state, action) => {
            state.projectId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createProjectAsync.fulfilled, (state, action) => {
            state.createProjectResponse = action.payload;
        });
        builder.addCase(createProjectAsync.rejected, (state, action) => {
            state.createProjectResponse = action.payload as CreateProjectResponse;
        });
        builder.addCase(updateProjectAsync.fulfilled, (state, action) => {
            state.updateProjectResponse = action.payload;
        });
        builder.addCase(getAllProjectsAsync.fulfilled, (state, action) => {
            state.projects = action.payload;
        });
        builder.addCase(getAProjectAsync.fulfilled, (state, action) => {
            state.projectData = action.payload;
        });
    }
})

export const { setProjectId } = projectSlice.actions;
export default projectSlice.reducer;
