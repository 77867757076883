import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/style/style.css";
import LandingPage from "./pages/landing-page/landing-page";
import SignIn from "./pages/auth/sign-in";
import SignUp from "./pages/auth/sign-up";
import UserVerification from "./pages/auth/verification/user-verification";
import VerificationSuccessful from "./pages/auth/verification/verification-successful";
import ForgotPassword from "./pages/auth/forgot-password/forgot-password";
import PasswordVerification from "./pages/auth/forgot-password/password-verification";
import ResetPassword from "./pages/auth/forgot-password/reset-password";
import ResetSuccessful from "./pages/auth/forgot-password/reset-successful";
import Projects from "./pages/project/projectsWrapper";
import DashBoard from "./pages/dashboard/dashboard";
import Profile from "./pages/settings/profile/profile";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Team from "./pages/settings/team/team";
import ApprovalLevel from "./pages/settings/approval-level/approval-level";
import Materials from "./pages/settings/materials/materials";
import Billings from "./pages/settings/billings/billings";
import Vendors from "./pages/settings/vendors/vendors";
import Stages from "./pages/settings/stages/stages";
import Procurement from "./pages/dashboard/procurement/procurement";
import Accounts from "./pages/dashboard/accounts/accounts";
import Budget from "./pages/dashboard/budget/budget";
import NoBudget from "./pages/dashboard/budget/no-budget";
import ProjectManagement from "./pages/dashboard/project-management/project-management";
import Report from "pages/dashboard/report/report";
import { Provider } from "react-redux";
import store from "./store/store";
import Inventory from "./pages/dashboard/inventory/inventory";
import FirstTimeLogin from "./pages/auth/first-time-login/first-time-login";
import CreateFirstProject from "./pages/project/create-first-project";
import SetNewPassword from "./pages/auth/first-time-login/set-new-password";

//toast
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);

root.render(
	<Provider store={store}>
		<React.StrictMode>
			<Router>
				<Routes>
					<Route path="/" element={<LandingPage />} />
					<Route path="/sign-in" element={<SignIn />} />
					<Route path="/sign-up" element={<SignUp />} />
					<Route path="/first-time-login" element={<FirstTimeLogin />} />
					<Route path="/user-verification" element={<UserVerification />} />
					<Route
						path="/verification-successful"
						element={<VerificationSuccessful />}
					/>
					<Route path="/set-new-password" element={<SetNewPassword />} />
					<Route path="/forgot-password" element={<ForgotPassword />} />
					<Route
						path="/password-verification"
						element={<PasswordVerification />}
					/>
					<Route path="/reset-password" element={<ResetPassword />} />
					<Route path="/reset-successful" element={<ResetSuccessful />} />
					<Route path="/projects" element={<Projects />} />
					<Route
						path="/create-first-project"
						element={<CreateFirstProject />}
					/>
					<Route path="/settings-profile" element={<Profile />} />
					<Route path="/settings-team" element={<Team />} />
					<Route path="/settings-approval-level" element={<ApprovalLevel />} />
					<Route path="/settings-materials" element={<Materials />} />
					<Route path="/settings-vendors" element={<Vendors />} />
					<Route path="/settings-stages" element={<Stages />} />
					<Route path="/settings-billings" element={<Billings />} />
					<Route path="/dashboard/:projectId" element={<DashBoard />} />
					<Route path="/procurement/:projectId" element={<Procurement />} />
					<Route path="/accounts/:projectId" element={<Accounts />} />
					<Route path="/no-budget/:projectId" element={<NoBudget />} />
					<Route path="/budget/:projectId" element={<Budget />} />
					<Route
						path="/project-management/:projectId"
						element={<ProjectManagement />}
					/>
					<Route path="/report/:projectId" element={<Report />} />
					<Route path="/inventory/:projectId" element={<Inventory />} />
				</Routes>
			</Router>
			<ToastContainer />
		</React.StrictMode>
	</Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
