import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Container, Spinner } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
	updateProjectAsync,
	getAProjectAsync,
	setProjectId,
} from "../../../slices/project/projectSlice";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { toast } from "react-toastify";
import errorIcon from "../../../assets/images/error-icon.svg";

interface EditProjectValues {
	id: string;
	company_id: string;
	project_name: string;
	project_location: string;
	project_start_date: string;
	project_end_date: string;
}
interface EditProjectProps {
	closeModal: () => void;
	show: boolean;
	projectId: string;
	onEditSuccess: () => void;
}

const EditProject: React.FC<EditProjectProps> = ({
	closeModal,
	show,
	projectId,
	onEditSuccess,
}) => {
	const dispatch = useAppDispatch();
	const companyId = useAppSelector(
		(state: RootState) => state.auth.user?.company_id || "",
	);
	const projectData = useAppSelector((state: any) => state.project.projectData);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (projectId) {
			dispatch(setProjectId(projectId));
			console.log("Fetching project with ID:", projectId);
			dispatch(getAProjectAsync({ companyId, id: projectId })).then(() =>
				setLoading(false),
			);
		} else {
			console.log("projectId is not set");
		}
	}, [dispatch, companyId, projectId]);

	if (loading) {
		return null;
	}

	return (
		<Container fluid className="">
			<Modal
				centered
				size="lg"
				show={show}
				onHide={closeModal}
				style={{ maxHeight: "100vh", overflowY: "auto" }}
			>
				<div className="px-5">
					<Formik
						enableReinitialize
						initialValues={{
							id: projectData?.id || "",
							company_id: projectData?.company_id || "",
							project_name: projectData?.project_name || "",
							project_location: projectData?.project_location || "",
							project_start_date: projectData?.project_start_date || "",
							project_end_date: projectData?.project_end_date || "",
						}}
						validationSchema={Yup.object().shape({
							id: Yup.string(),
							company_id: Yup.string(),
							project_name: Yup.string(),
							project_location: Yup.string(),
							project_start_date: Yup.string(),
							project_end_date: Yup.date().test(
								"is-after-start",
								"End date must be after start date",
								function (value) {
									const { project_start_date } = this.parent;
									return project_start_date && value
										? new Date(value) > new Date(project_start_date)
										: true;
								},
							),
							project_status_id: Yup.string(),
						})}
						onSubmit={(values: EditProjectValues, { setSubmitting }) => {
							console.log("Form values: ", values);
							dispatch(
								updateProjectAsync({
									updateProjectData: values,
									id: projectId || "",
								}),
							)
								.unwrap()
								.then((response) => {
									toast.success("Project updated successfully");
									onEditSuccess();
									closeModal();
								})
								.catch((error) => {
									const errorMessage = Object.values(error.message)
										.flat()
										.join(" ");
									toast.error(errorMessage);
								})
								.finally(() => {
									setSubmitting(false);
								});
						}}
					>
						{({
							errors,
							touched,
							handleSubmit,
							isSubmitting,
							values,
							handleChange,
						}) => (
							<Form onSubmit={handleSubmit}>
								<Modal.Header closeButton className="text-start mt-2">
									<h4 className="confirm-header">Edit project</h4>
								</Modal.Header>
								<Modal.Body>
									<Row>
										<Col md={6} className="mt-2">
											<label className="mt-2">
												<p>
													Project name <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="project_name"
												placeholder="Enter your project name"
												className={`fluent-input  ${touched.project_name && errors.project_name
													? "is-invalid"
													: ""
													}`}
												required
											/>
											{touched.project_name && errors.project_name ? (
												<p className="error-message mb-0 d-inline-flex align-items-center">
													<img src={errorIcon} alt="error-icon" />
													{`${errors.project_name}`}
												</p>
											) : (
												<p className="error-message-placeholder mb-0">
													no errors
												</p>
											)}
										</Col>
										<Col md={6} className="mt-2">
											<label className="mt-2">
												<p>
													Location <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="project_location"
												placeholder="Enter your location"
												className={`fluent-input  ${touched.project_location && errors.project_location
													? "is-invalid"
													: ""
													}`}
												required
											/>
										</Col>
										{touched.project_location && errors.project_location ? (
											<p className="error-message mb-0 d-inline-flex align-items-center">
												<img src={errorIcon} alt="error-icon" />
												{`${errors.project_location}`}
											</p>
										) : (
											<p className="error-message-placeholder mb-0">
												no errors
											</p>
										)}
									</Row>
									<Row>
										<Col md={6} className="mt-2">
											<label className="mt-2">
												<p>
													Start date <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="date"
												name="project_start_date"
												className={`fluent-input  ${touched.project_start_date &&
													errors.project_start_date
													? "is-invalid"
													: ""
													}`}
												required
											/>
											{touched.project_start_date &&
												errors.project_start_date ? (
												<p className="error-message mb-0 d-inline-flex align-items-center">
													<img src={errorIcon} alt="error-icon" />
													{`${errors.project_start_date}`}
												</p>
											) : (
												<p className="error-message-placeholder mb-0">
													no errors
												</p>
											)}
										</Col>
										<Col md={6} className="mt-2">
											<label className="mt-2">
												<p>
													End date <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="date"
												name="project_end_date"
												className={`fluent-input  ${touched.project_end_date && errors.project_end_date
													? "is-invalid"
													: ""
													}`}
												required
											/>
											{touched.project_end_date && errors.project_end_date ? (
												<p className="error-message mb-0 d-inline-flex align-items-center">
													<img src={errorIcon} alt="error-icon" />
													{`${errors.project_end_date}`}
												</p>
											) : (
												<p className="error-message-placeholder mb-0">
													no errors
												</p>
											)}
										</Col>
									</Row>
									{/* <Row>
                                        <Col md={12} className='mt-2'>
                                            <label className='mt-2'>
                                                <p>Description{" "}
                                                    <span className="text-danger">*</span>
                                                </p>
                                            </label>
                                            <textarea
                                                name='description'
                                                placeholder='Enter a company description'
                                                className={`form-control  ${touched.description && errors.description ? 'is-invalid' : ''
                                                    }`}
                                                required
                                                rows={5}
                                                cols={40} />
                                        </Col>
                                    </Row> */}

									<Modal.Footer className="mt-5">
										<Row>
											<Col md={6}>
												<div className=" mt-1">
													<Button onClick={closeModal} className="cancel-btn">
														<span className="text-center">Cancel </span>{" "}
													</Button>
												</div>
											</Col>
											<Col md={6}>
												<div className=" mt-1">
													<Button
														type="submit"
														className=" border-0 create-btn bg-color-1"
													>
														{isSubmitting ? (
															<Spinner animation="border" size="sm" />
														) : (
															<span className="text-center">Edit project</span>
														)}
													</Button>
												</div>
											</Col>
										</Row>
									</Modal.Footer>
								</Modal.Body>
							</Form>
						)}
					</Formik>
				</div>
			</Modal>
		</Container>
	);
};

export default EditProject;
