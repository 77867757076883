import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Container, Form, Spinner } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import {
	createVendorAsync,
	getAllBanksAsync,
	getAllVendorsAsync,
} from "../../../../slices/settings/settingsSlice";
import { RootState } from "../../../../store/store";
import { Select, FormControl, InputLabel, MenuItem } from "@mui/material";
import { toast } from "react-toastify";
import {
	handleAccountNumberChange,
	handlePhoneNumberChange,
} from "util/inputUtils";

interface CreateVendorValues {
	vendor_name: string;
	vendor_email: string;
	vendor_phone_number: string;
	vendor_bank_account: string;
	vendor_bank_id: string;
	company_id: string;
}
interface CreateVendorProps {
	closeModal: () => void;
	show: boolean;
	onCreateSuccess: () => void;
}

const CreateVendor: React.FC<CreateVendorProps> = ({
	closeModal,
	show,
	onCreateSuccess,
}) => {
	const dispatch = useAppDispatch();
	const [currentPage, setCurrentPage] = useState(1);

	const banks = useAppSelector((state) => state.settings.banks);
	const companyId = useAppSelector(
		(state: RootState) => state.auth.user?.company_id || "",
	);

	useEffect(() => {
		dispatch(getAllBanksAsync());
	}, [dispatch]);

	console.log("Banks", banks);

	return (
		<Container fluid className="">
			<Modal
				centered
				size="lg"
				show={show}
				onHide={closeModal}
				style={{ maxHeight: "100vh", overflowY: "auto" }}
			>
				<div className="px-5">
					<Formik
						initialValues={{
							vendor_name: "",
							vendor_email: "",
							vendor_phone_number: "",
							vendor_bank_account: "",
							vendor_bank_id: "",
							company_id: companyId || "",
						}}
						validationSchema={Yup.object().shape({
							vendor_name: Yup.string().required("Required"),
							vendor_email: Yup.string().required("Required"),
							vendor_phone_number: Yup.string()
								.matches(
									/^(070|080|081|090|091)[0-9]{8}$/,
									"Please enter a valid 11 digit phone number in Nigerian format e.g 08012345678",
								)
								.required("Please enter a valid 11 digit phone number"),
							vendor_bank_account: Yup.string().required("Required"),
							vendor_bank_id: Yup.string().required("Required"),
							company_id: Yup.string().required("Required"),
						})}
						onSubmit={(values: CreateVendorValues, { setSubmitting }) => {
							console.log("Submitting values:", values);
							dispatch(
								createVendorAsync({
									...values,
									company_id: companyId || "",
								}),
							)
								.unwrap()
								.then((response) => {
									toast.success("Vendor created successfully");
									dispatch(getAllVendorsAsync({ companyId, page: currentPage }));
									onCreateSuccess();
									closeModal();
								})
								.catch((error) => {
									const errorMessage = Object.values(error.message)
										.flat()
										.join(" ");
									toast.error(errorMessage);
								})
								.finally(() => {
									setSubmitting(false);
								});
						}}
					>
						{({
							errors,
							touched,
							handleSubmit,
							isSubmitting,
							values,
							handleChange,
						}) => (
							<Form onSubmit={handleSubmit}>
								<Modal.Header closeButton className="text-start mt-3">
									<h4 className="confirm-header">Add new vendor</h4>
								</Modal.Header>
								<Modal.Body>
									<Row>
										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Vendor <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="vendor_name"
												placeholder="Enter vendor name"
												className={`fluent-input  ${touched.vendor_name && errors.vendor_name
													? "is-invalid"
													: ""
													}`}
												required
											/>
										</Col>
									</Row>
									<Row>
										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Email <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="vendor_email"
												placeholder="Enter vendor email"
												className={`fluent-input  ${touched.vendor_email && errors.vendor_email
													? "is-invalid"
													: ""
													}`}
												required
											/>
										</Col>
									</Row>
									<Row>
										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Phone number <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="vendor_phone_number"
												placeholder="Enter vendor number"
												className={`fluent-input  ${touched.vendor_phone_number &&
													errors.vendor_phone_number
													? "is-invalid"
													: ""
													}`}
												required
												onInput={handlePhoneNumberChange}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Account number <span className="text-danger">*</span>
												</p>
											</label>
											<Field
												type="text"
												name="vendor_bank_account"
												placeholder="Enter account number"
												className={`fluent-input  ${touched.vendor_bank_account &&
													errors.vendor_bank_account
													? "is-invalid"
													: ""
													}`}
												required
												onInput={handleAccountNumberChange}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="mt-2">
											<label className="mt-2">
												<p>
													Bank <span className="text-danger">*</span>
												</p>
											</label>
											<FormControl fullWidth>
												<InputLabel>Select bank </InputLabel>
												<Select
													label="Select bank "
													name="vendor_bank_id"
													value={values.vendor_bank_id}
													onChange={handleChange}
												>
													{Array.isArray(banks) && banks.length > 0 ? (
														banks.map((bank) => (
															<MenuItem key={bank.id} value={bank.id}>
																{bank.bank_name}
															</MenuItem>
														))
													) : (
														<p> no banks </p>
													)}
												</Select>
											</FormControl>
											<div className="invalid-feedback d-block">
												{touched.vendor_bank_id && errors.vendor_bank_id}
											</div>
										</Col>
									</Row>
									<Modal.Footer className="mt-5">
										<Row>
											<Col md={6}>
												<div className=" mt-1">
													<Button onClick={closeModal} className="cancel-btn">
														<span className="text-center">Cancel </span>{" "}
													</Button>
												</div>
											</Col>
											<Col md={6}>
												<div className=" mt-1">
													<Button
														type="submit"
														className=" border-0 create-btn bg-color-1"
													>
														{isSubmitting ? (
															<Spinner animation="border" size="sm" />
														) : (
															<span className="text-center">Create vendor</span>
														)}
													</Button>
												</div>
											</Col>
										</Row>
									</Modal.Footer>
								</Modal.Body>
							</Form>
						)}
					</Formik>
				</div>
			</Modal>
		</Container>
	);
};

export default CreateVendor;
