import React from 'react';
import { Container, Button } from "react-bootstrap";
import LandingPageLayout from './components/landing-page-layout';
import { Link } from "react-router-dom";

function LandingPage(): JSX.Element {
    return (
        <LandingPageLayout>
            <Container>
                <div className='text-center mt-5 pt-5'>
                    <h1 className='display-2 fw-bold'>
                        Manage your <br /> construction projects <br /> with ease.
                    </h1>
                    <p className='my-5'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc <br /> vulputate libero et velit interdum, ac aliquet odio mattis.
                    </p>
                    <div>
                        <Link to="/sign-up" className='link-styles text-white'>
                            <Button type='submit' className='bg-color-1 px-5 py-3 border-0 btn-1'>
                                Get started. It's free
                            </Button>
                        </Link>
                    </div>
                </div>
            </Container>
        </LandingPageLayout>
    );
}

export default LandingPage;
