import React, { useState, useEffect } from 'react';
import { Image } from "react-bootstrap";
import DashVector from "../../assets/images/dash-vector.png"
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

function SettingsSideBar() {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
    const location = useLocation();
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setIsCollapsed(true);
                setIsSidebarOpen(false);
            } else {
                setIsCollapsed(false);
                setIsSidebarOpen(true);
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleSidebar = () => {
        setIsSidebarOpen((prevState) => !prevState);
    };
    return (
        <>
            {isCollapsed && (
                <button className="settings-hamburger" onClick={toggleSidebar}>
                    <MenuIcon />
                </button>
            )}
            <aside
                className={`sidebar bg-color-9 pt-5 px-3 ${isSidebarOpen ? 'open' : 'closed'}`}
            >                <h3 className='fw-bold'>Settings</h3>
                <div className=' sidebar-menu pt-5'>
                    <Link to="/settings-profile" className={`sidebar-link ${location.pathname === '/settings-profile' ? 'active' : ''}`}>
                        <span className='sidebar-text sidebar-link'>
                            <Image src={DashVector} className='sidebar-icon' />
                            Profile
                        </span>
                    </Link>
                    <Link to="/settings-team" className={`sidebar-link ${location.pathname === '/settings-team' ? 'active' : ''}`}>
                        <span className='sidebar-text sidebar-link'>
                            <Image src={DashVector} className='sidebar-icon' />
                            Team
                        </span>
                    </Link>
                    <Link to="/settings-approval-level" className={`sidebar-link ${location.pathname === '/settings-approval-level' ? 'active' : ''}`}>
                        <span className='sidebar-text sidebar-link'>
                            <Image src={DashVector} className='sidebar-icon' />
                            Approval level
                        </span>
                    </Link>
                    <Link to="/settings-materials" className={`sidebar-link ${location.pathname === '/settings-materials' ? 'active' : ''}`}>
                        <span className='sidebar-text sidebar-link'>
                            <Image src={DashVector} className='sidebar-icon' />
                            Materials
                        </span>
                    </Link>
                    <Link to="/settings-vendors" className={`sidebar-link ${location.pathname === '/settings-vendors' ? 'active' : ''}`}>
                        <span className='sidebar-text sidebar-link'>
                            <Image src={DashVector} className='sidebar-icon' />
                            Vendors
                        </span>
                    </Link>
                    <Link to="/settings-stages" className={`sidebar-link ${location.pathname === '/settings-stages' ? 'active' : ''}`}>
                        <span className='sidebar-text sidebar-link'>
                            <Image src={DashVector} className='sidebar-icon' />
                            Stages
                        </span>
                    </Link>
                    <Link to="/settings-billings" className={`sidebar-link ${location.pathname === '/settings-billings' ? 'active' : ''}`}>
                        <span className='sidebar-text sidebar-link'>
                            <Image src={DashVector} className='sidebar-icon' />
                            Billings
                        </span>
                    </Link>

                </div>
            </aside>
        </>
    )
}

export default SettingsSideBar