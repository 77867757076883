import React, { useEffect } from "react";
import ResetIcon from "../../../assets/images/reset-password-icon.png";
import { Container, Button, Image, Card } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { setNewPasswordAsync } from "../../../slices/auth/authSlice";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { toast } from "react-toastify";

interface SetNewPasswordFormValues {
	old_password: string;
	password: string;
	password_confirmation: string;
	email: string;
	token: string;
}

const SetNewPassword: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const email = searchParams.get("email");
	const token = searchParams.get("token");

	useEffect(() => {
		if (!email || !token) {
			console.error("Email or token is missing from URL");
		}
	}, [email, token]);
	console.log("email:", email);
	console.log("token:", token);
	const setNewPasswordResponse = useAppSelector(
		(state: any) => state.auth.setNewPasswordResponse,
	);
	useEffect(() => {
		if (setNewPasswordResponse && !setNewPasswordResponse.error) {
			navigate("/sign-in");
		}
	}, [setNewPasswordResponse, navigate]);

	return (
		<Container fluid>
			<div className="bg-color-2">
				<Card className="auth-card-3 p-5">
					<div className="text-center">
						<Image src={ResetIcon} className="mt-5" />
						<h1 className="my-3">Set new password</h1>
						<p>Enter your new password</p>
						<Formik
							initialValues={{
								password: "",
								old_password: "",
								password_confirmation: "",
								email: email || "",
								token: token || "",
							}}
							validationSchema={Yup.object().shape({
								password: Yup.string()
									.required("No password provided.")
									.min(8, "Password is too short - should be 8 chars minimum."),
								password_confirmation: Yup.string()
									.oneOf([Yup.ref("password"), ""], "Passwords do not match")
									.required("Please confirm password."),
							})}
							onSubmit={(
								values: SetNewPasswordFormValues,
								{ setSubmitting },
							) => {
								dispatch(
									setNewPasswordAsync({
										...values,
										email: email || "",
										token: token || "",
									}),
								)
									.unwrap()
									.then((response) => {
										toast.success("Password changed successfully");
										navigate("/sign-in");
									})
									.catch((error) => {
										const errorMessage = Object.values(error.message)
											.flat()
											.join(" ");
										toast.error(errorMessage);
									})
									.finally(() => {
										setSubmitting(false);
									});
								setSubmitting(false);
							}}
						>
							{({ errors, touched }) => (
								<Form>
									<div className="text-center mt-2">
										<div className="text-start">
											<label>
												<p className="">
													Old password <span className="text-danger">*</span>
												</p>
											</label>
										</div>
										<Field
											type="password"
											name="old_password"
											placeholder="Please enter your new password"
											className={`fluent-input`}
											required
										/>
									</div>
									<div className="text-center mt-2">
										<div className="text-start">
											<label>
												<p className="">
													New password <span className="text-danger">*</span>
												</p>
											</label>
										</div>
										<Field
											type="password"
											name="password"
											placeholder="Please enter your new password"
											className={`fluent-input ${
												touched.password && errors.password ? "is-invalid" : ""
											}`}
											required
										/>
									</div>
									<div className="text-center mt-4">
										<div className="text-start">
											<label>
												<p className="">
													Confirm new password{" "}
													<span className="text-danger">*</span>
												</p>
											</label>
										</div>
										<Field
											type="password"
											name="password_confirmation"
											placeholder="Please enter your new password"
											className={`fluent-input ${
												touched.password_confirmation &&
												errors.password_confirmation
													? "is-invalid"
													: ""
											}`}
											required
										/>
									</div>
									<div className="d-grid">
										<Button
											type="submit"
											className="my-4 py-3 border-0 bg-color-1"
										>
											Continue
										</Button>
									</div>
								</Form>
							)}
						</Formik>
					</div>
				</Card>
			</div>
		</Container>
	);
};

export default SetNewPassword;
